import { ChapterId, CourseId, LessonId } from "../types/common";

const baseApiUrl = window._env_?.API_URL || process.env.API_URL;

interface UrlProps {
  [key: string]: string;
}

interface GetURL {
  [key: string]: (...args: any[]) => string
}

export const URL: UrlProps = {
  login: `${baseApiUrl}/user/login`,
  authenticate: `${baseApiUrl}/user/authenticate`,
  requestOTP: `${baseApiUrl}/user/request-otp`,
  signUp : `${baseApiUrl}/user/sign-up`,
  updateAddress: `${baseApiUrl}/user/update-address`,
  refreshToken: `${baseApiUrl}/user/refresh-token`,
  getCourses: `${baseApiUrl}/courses`,
  getCourseBanners: `${baseApiUrl}/courses/banner`,
  getLessonVideoUrl: `${baseApiUrl}/courses/lessons/video`,
  publishCourseAnalytics: `${baseApiUrl}/courses/analytics`,
  createPaymentOrder: `${baseApiUrl}/courses/payments/order`,
  createCourseEnrollment: `${baseApiUrl}/courses/payments/enrollment`,
  recordPaymentFailure: `${baseApiUrl}/courses/payments/error`,
  getTimeSpent: `${baseApiUrl}/courses/analytics/time-spent`,
  getPerformance: `${baseApiUrl}/courses/analytics/performance`,
  getNextCourses: `${baseApiUrl}/courses/next-courses`,
  getLastViewed: `${baseApiUrl}/courses/analytics/last-viewed`,
  getEvent: `${baseApiUrl}/user/events`,
  uploadAssignment: `${baseApiUrl}/courses/assignments`,
  uploadAssignmentFiles: `${baseApiUrl}/courses/assignments/upload`,
  getProfile: `${baseApiUrl}/user/profile`,
  getAssignmentsAnalytics: `${baseApiUrl}/courses/assignments/total-completed`,
  getQuizAnalytics: `${baseApiUrl}/courses/chapters/get-attempted-quiz`,
  updateProfile: `${baseApiUrl}/user/update-profile`,
  updateImage: `${baseApiUrl}/user/update-image`,
  putTask: `${baseApiUrl}/courses/user/tasks`,
  getTasks: `${baseApiUrl}/courses/user/tasks`,
  uploadUsersAsset: `${baseApiUrl}/user/assets`,
  updateUserOnboard: `${baseApiUrl}/user/update-onboard`,
  createShipmentOrder: `${baseApiUrl}/courses/shipments/place-order`
};

export const getURL: GetURL = {
  submitQuiz: (chapterId: ChapterId, experimentId: LessonId) => 
    `${baseApiUrl}/courses/chapters/${chapterId}/quiz/submit${!!experimentId ? `?experimentId=${experimentId}` : ''}`,
  updateEnrollmentStatus: (courseId: CourseId) => `${baseApiUrl}/courses/${courseId}/enrollment/status`,
  downloadCertificate: (courseId: CourseId) => `${baseApiUrl}/courses/${courseId}/certificate` 
}
