import { createAction } from '@reduxjs/toolkit';
import { AppCustomError, AppError } from '../errors';
import { UserAccessToken, UserEmailId, UserFirstName, UserLastName,
  UserOTP, UserPhoneNumber, UserRefreshToken, UserRole, UserSession, UserTokenExpiry } from '../types/common';

export interface LoginPayload {
  phoneNumber: UserPhoneNumber;
  otp: UserOTP;
  session: UserSession;
}

export interface AuthenticatePayload {
  token: UserAccessToken;
}

export interface ReqOTPPayload {
    phoneNumber: UserPhoneNumber;
}

export interface SendOTPSuccessPayload {
    session: UserSession;
    phoneNumber: UserPhoneNumber;
    message: string | null;
}

export interface LoginSuccessPayload {
  AccessToken: UserAccessToken;
  ExpiresIn: UserTokenExpiry;
  RefreshToken: UserRefreshToken;
  User: User,
  PageMap: string
}

interface User {
  firstName: string;
  lastName: string;
}

export interface SignUpPayload {
  isSignUp: boolean;
}

export interface SignUpRequestPayload {
  firstName: UserFirstName;
  lastName: UserLastName;
  email: UserEmailId;
  phoneNumber: UserPhoneNumber;
  role: UserRole;
}

export interface SignUpRequestSuccessPayload {
  message: string;
  firstName: UserFirstName;
  lastName: UserLastName;
  email: UserEmailId;
  phoneNumber: UserPhoneNumber;
  role: UserRole;
  errorCode: string;
}

export interface UpdateAddressPayload {
  address: string;
}

export interface UpdateOnboardPayload {
  isOnboard: boolean;
}

export interface ProfileSuccessPayload {
  firstName: string;
  lastName: string;
  address: string;
  profilePicture: string;
  dateOfBirth: string;
  phoneNumber: string;
  email: string;
  instituteName: string;
  completionPercentage: number;
  id: string;
}

export interface RefreshTokenPayload {
  refreshToken: string;
}

export interface RefreshTokenSuccessPayload {
  AccessToken: string;
  ExpiresIn: number;
  TokenType: string;
  IdToken: string;
}

export const loginRequest = createAction<LoginPayload>('LOGIN_REQUEST');
export const authenticateRequest = createAction<AuthenticatePayload>('AUTHENTICATE_REQUEST');
export const loginSuccess = createAction<LoginSuccessPayload>('LOGIN_SUCCESS');
export const loginFailure = createAction<AppCustomError>('LOGIN_FAILURE');

export const sendOTPRequest = createAction<ReqOTPPayload>('SEND_OTP_REQUEST');
export const sendOTPSuccess = createAction<SendOTPSuccessPayload>('SEND_OTP_SUCCESS');
export const sendOTPFailure = createAction<AppCustomError>('SEND_OTP_FAILURE');

export const clearErrorMessages = createAction('CLEAR_ERROR_MESSAGES');
export const resetOTPSentFlag = createAction('RESET_OTP_SENT_FLAG');

export const fetchSignUp = createAction<SignUpPayload>('HANDLE_SIGN_UP');
export const signUpRequest = createAction<SignUpRequestPayload>('SIGN_UP_REQUEST');
export const signUpSuccess = createAction<SignUpRequestSuccessPayload>('SIGN_UP_SUCCESS');
export const signUpFailure = createAction<AppCustomError>('SIGN_UP_FAILURE');

export const updateAddressRequest = createAction<UpdateAddressPayload>('UPDATE_ADDRESS_REQUEST');
export const updateAddressRequestSuccess= createAction<UpdateAddressPayload>('UPDATE_ADDRESS_REQUEST_SUCCESS');
export const updateAddressRequestFailure= createAction<AppCustomError>('UPDATE_ADDRESS_REQUEST_FAILURE');

export const updateOnboardRequest = createAction<UpdateOnboardPayload>('UPDATE_ONBOARD_REQUEST');
export const updateOnboardRequestSuccess= createAction<UpdateOnboardPayload>('UPDATE_ONBOARD_REQUEST_SUCCESS');
export const updateOnboardRequestFailure= createAction<AppCustomError>('UPDATE_ONBOARD_REQUEST_FAILURE');

export const profileDataRequest = createAction('PROFILE_DATA_REQUEST');
export const profileDataRequestSuccess= createAction<ProfileSuccessPayload>('PROFILE_DATA_REQUEST_SUCCESS');

export const toggleLoading = createAction<boolean>('TOGGLE_LOADING');
export const logOut = createAction('LOG_OUT');

export const refreshTokenRequest = createAction('REFRESH_TOKEN_REQUEST');
export const refreshTokenSuccess = createAction<RefreshTokenSuccessPayload>('REFRESH_TOKEN_SUCCESS');