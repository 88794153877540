import { Formik } from 'formik';
import * as yup from 'yup';

import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import countryList from '../../data/countries.json';
import stateList from '../../data/states.json';
import { Profile } from './PersonalInfo';
import { ProfileSuccessPayload } from '../../actions/userActions';

interface EditProfileProps{
    show: boolean,
    handleClose: ()=>void,
    handleSubmit: (e: Profile)=>void
    user: ProfileSuccessPayload
    message?: string | null
}
const EditProfile: React.FC<EditProfileProps> = (props) => {
    const schema = yup.object().shape({
        address1: yup.string().required(),
        address2: yup.string(),
        city: yup.string().required(),
        state: yup.string().required(),
        pincode: yup.string().required(),
        country: yup.string().required(),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        dateOfBirth: yup.date(),
        phoneNumber: yup.string()
        .min(8)
        .max(15)
        .trim()
        .matches(new RegExp(/\d+/))
        .required(),
        email: yup.string().matches(new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)),
        instituteName: yup.string(),
	});

    return <Modal className="address-modal" show={props.show} onHide={props.handleClose} backdrop="static" keyboard={false}>
    <Modal.Header closeButton>
        <Modal.Title>{'User Information'}</Modal.Title>
    </Modal.Header>
    <Modal.Body className="pb-0">
        <Formik
            enableReinitialize
            validationSchema={schema}
            onSubmit={props.handleSubmit}
            initialValues={{
                ...JSON.parse(props.user.address),
                firstName: props.user.firstName,
                lastName: props.user.lastName,
                dateOfBirth: new Date(props.user.dateOfBirth).toLocaleDateString('en-GB').split('/').reverse().join('-'),
                phoneNumber: props.user.phoneNumber,
                email: props.user.email,
                instituteName: props.user.instituteName,
            } as Profile}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                resetForm
            }) => (
                <Form noValidate onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}>
                    <Row>
                    <Col xs={6} className="mb-3">
                            <Form.Group role="form">
                                <Form.Label>First name <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter First Name"
                                    id="firstName"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.firstName && !!errors.firstName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    First Name is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <Form.Group role="form">
                                <Form.Label>Last Name<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Last Name"
                                    id="lastName"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.lastName && !!errors.lastName}
                                />
                                <Form.Control.Feedback type="invalid">
                                 LastName is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <Form.Group role="form">
                                <Form.Label>Phone Number <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter phone number"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={values.phoneNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                                />
                                <Form.Control.Feedback type="invalid">
                                 Phone Number is invalid
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <Form.Group role="form">
                                <Form.Label>Email </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter email"
                                    id="email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.email && !!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                   Email is invalid
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <Form.Group role="form">
                                <Form.Label>Institue Name </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Institue Name"
                                    id="instituteName"
                                    name="instituteName"
                                    value={values.instituteName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.instituteName && !!errors.instituteName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Institute name is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <Form.Group role="form">
                                <Form.Label>Date of birth</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Enter Date of birth"
                                    id="dateOfBirth"
                                    name="dateOfBirth"
                                    value={values.dateOfBirth}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.dateOfBirth && !!errors.dateOfBirth}
                                />
                                <Form.Control.Feedback type="invalid">
                                   date Of Birth is invalid
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <Form.Group role="form">
                                <Form.Label>Address 1 <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter address 1"
                                    id="address1"
                                    name="address1"
                                    value={values.address1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.address1 && !!errors.address1}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Address 1 is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <Form.Label>Address 2 <span className="text-danger" style={{visibility: 'hidden'}}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter address 2"
                                id="address2"
                                name="address2"
                                value={values.address2}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Col>
                        <Col xs={6} className="mb-3">
                            <Form.Group>
                                <Form.Label>
                                    City <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter city"
                                    id="city"
                                    name="city"
                                    value={values.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.city && !!errors.city}
                                />
                                <Form.Control.Feedback type="invalid">
                                    City is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <Form.Group>
                                <Form.Label>
                                    State <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter state"
                                    id="state"
                                    name="state"
                                    value={values.state}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.state && !!errors.state}
                                    list="stateData"
                                />
                                <datalist id="stateData">
                                   {stateList.map((item, key) =>
                                     <option key={key} value={item.name} />
                                   )}
                                </datalist>
                                <Form.Control.Feedback type="invalid">
                                    State is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <Form.Group>
                                <Form.Label>
                                    Pincode <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter pincode"
                                    id="pincode"
                                    name="pincode"
                                    value={values.pincode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.pincode && !!errors.pincode}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Pincode is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <Form.Group>
                                <Form.Label>
                                    Country <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter country"
                                    id="country"
                                    name="country"
                                    value={values.country}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.country && !!errors.country}
                                    list="countryData"
                                />
                                <datalist id="countryData">
                                   {countryList.map((item, key) =>
                                     <option key={key} value={item.name} />
                                   )}
                                </datalist>
                                <Form.Control.Feedback type="invalid">
                                    Country is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row style={{float: 'right'}}>
                        <Button className="submit-btn bio-btn success-btn-2 bio-sm-btn" type="submit">Submit</Button>
                    </Row>
                    {props.message && <span className="text-danger">{props.message}</span>}
                </Form>
            )}
        </Formik>
    </Modal.Body>
</Modal>
}

export default EditProfile
