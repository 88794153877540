import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import {Banner} from "../actions/courseActions";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

interface Banners {
    [banner: string]: Banner[];
}

const bannerItem = (banner: Banner, index: number) => {
    return (
        <div className="item" style={{
            backgroundImage: `url("${banner.banner}")`,
            backgroundPosition: 'center',
            backgroundSize: 'center',
            backgroundRepeat: 'no-repeat'}}>
            <h4>{banner.title}</h4>
            <p>{banner.description}</p>
        </div>
    );
}

const banner = ({ banners }: Banners) => (
    <section className="web-section full-width mar-no">
        <div id="dashboardCarousel" className="mar-no">
            <OwlCarousel loop items={1} margin={8} nav autoplay={true} autoplaySpeed={3000}>
                {banners?.filter(x => x.status === 'active').map((banner, index) =>
                    <div key={index}>{bannerItem(banner, index)}</div>
                )}
            </OwlCarousel>
        </div>
    </section>
);

export default banner;