import { AuthenticatePayload, LoginPayload, RefreshTokenPayload, SignUpRequestPayload } from '../actions/userActions';
import { URL } from '../shared/url';
import { UserPhoneNumber } from '../types/common';

export async function login(req: LoginPayload): Promise<any> {
  const res = await fetch(URL.login, {
    method: 'POST',
    body: JSON.stringify(req),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
  })
  var response = await res.json().then(resBody => { return Promise.resolve(resBody)}).catch(err => Promise.reject(err));

  if (res.status >= 200 && res.status < 300) {
    return response;
  } else {
    if(response.message === 'User is not active') {
      return response;
    }
    throw new Error(res.status.toString())
  }
}

export async function authenticate(req: AuthenticatePayload): Promise<any> {
  const res = await fetch(URL.authenticate, {
    method: 'POST',
    body: JSON.stringify(req),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
  })
  var response = await res.json().then(resBody => { return Promise.resolve(resBody)}).catch(err => Promise.reject(err));

  if (res.status >= 200 && res.status < 300) {
    return response;
  } else {
    if(response.message === 'User is not active') {
      return response;
    }
    throw new Error(res.status.toString())
  }
}

export async function signUp(req: SignUpRequestPayload): Promise<any> {
  const res = await fetch(URL.signUp, {
    method: 'POST',
    body: JSON.stringify(req),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
  })

  return res
    .json()
    .then(resBody => {
      return Promise.resolve(resBody);
    })
    .catch(err => Promise.reject(err));
}

export async function requestOTP(phoneNumber: UserPhoneNumber): Promise<any> {
  const res = await fetch(URL.requestOTP, {
    method: 'POST',
    body: JSON.stringify({
      phoneNumber
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
  })

  return res
    .json()
    .then(resBody => {
      return Promise.resolve(resBody);
    })
    .catch(err => Promise.reject(err));
}

export async function updateUserAddress(payload: any, accessToken: string): Promise<any> {
  const res = await fetch(URL.updateAddress, {
      method: 'PUT',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload)
  })

  return res
      .json()
      .then(resBody => {
          return Promise.resolve(resBody);
      })
      .catch(err => Promise.reject(err));
}

export async function updateUserOnboard(payload: any, accessToken: string): Promise<any> {
  const res = await fetch(URL.updateUserOnboard, {
      method: 'PUT',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload)
  })

  return res
      .json()
      .then(resBody => {
          return Promise.resolve(resBody);
      })
      .catch(err => Promise.reject(err));
}

export async function getProfileData(accessToken: string): Promise<any> {
  const res = await fetch(URL.getProfile, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return res
      .json()
      .then(resBody => {
          return Promise.resolve(resBody);
      })
      .catch(err => Promise.reject(err));
}

export async function getRefreshedToken(payload: RefreshTokenPayload): Promise<any> {
  const res = await fetch(URL.refreshToken, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })

  return res
      .json()
      .then(resBody => {
          return Promise.resolve(resBody);
      })
      .catch(err => Promise.reject(err));
}
