import React from "react";
import { useNavigate } from "react-router-dom";

import "../../containers/Profile.scss";

// interface Author {
//   profilePicture: string;
// }

export interface ProfileInfoProps {
  profilePicture: string;
  percentage: number;
}

const ProfileProgress: React.FC<ProfileInfoProps> = (props) => {
  const { percentage, profilePicture } = props;
  const navigate = useNavigate();

  const redirectToProfile = () => {
    navigate(`/profile`);
  };

  const roundedPercentage = Math.round(percentage);

  return (
    <a className="pointer-button" onClick={redirectToProfile}>
      <div className="profile-progress-wrapper">
        <img className="image-container" src={profilePicture} />
        <div className="progress-bar-arrow-wrapper">
          <div className="progress-bar-wrapper">
            {roundedPercentage<100 && <h5>Your profile is {roundedPercentage}% completed</h5>}
            <div className="progress-bar-overlay">
              <div
                className="progress-indicator"
                style={{ width: `${roundedPercentage ?? 0}%` }}
              />
            </div>
          </div>
          <img height={24} src="/images/icons/arrow-point-to-right.png" />
        </div>
      </div>
    </a>
  );
};

export default ProfileProgress;
