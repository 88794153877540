import React, { useDebugValue, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BiLogOut } from 'react-icons/bi';

import Banner from '../components/Banner';

import { logOut } from "../actions/userActions";
import { AppState } from '../store';
import { getLastViewedRequest } from '../actions/analyticsAction';


interface Props {
    children: React.ReactNode,
    useBanner?: boolean | undefined,
    showAppHeader?: boolean | undefined,
}

const NavContainer = ({ children , useBanner = true, showAppHeader = true}: Props) => {
    const [toggleMobileMenu, setToggleMobileMenu] = useState<boolean>(false);
    const [showLogoutModel, setShowLogoutModel] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, analytics } = useSelector((state: AppState) => state);

    const {
        firstName,
        isLoggedIn
    } = useSelector((state: AppState) => state.user);
    
    const banners = useSelector((state: AppState) => {
        if(state.courses.banners && state.courses.banners.length > 0) {
            return state.courses.banners.filter(item => item.status !== 'disabled')
        } else {
            return []
        }
    });
    
    
    const handleLogOut = () => {
        dispatch(logOut());
    }


    useEffect(()=>{
        if(!isLoggedIn){
            navigate("/")
        }
    },[isLoggedIn])

    const handleLanding = () => {
        dispatch(getLastViewedRequest());
        if (analytics.lastViewed == null || Object.keys(analytics.lastViewed).length === 0) {
            navigate('/home');
          }else{
            navigate('/dashboard');
          }
    }
    
    return (
        <>
            <section className="web-section app-section mar-top-no">
                <div className="display-flex just-flex-start align-flex-start">
                    <div className={"app-nav-bar " + (toggleMobileMenu ? "toggleActive" : "")} id="app-nav-bar">
                        <div id="menu-bar" className={toggleMobileMenu ? "change" : ""} onClick={() => {
                            setToggleMobileMenu(!toggleMobileMenu)
                        }}>
                            <div id="bar1" className="bar"></div>
                            <div id="bar2" className="bar"></div>
                            <div id="bar3" className="bar"></div>
                        </div>
                        <nav>
                            {!toggleMobileMenu && (<a onClick={() => handleLanding()} title='Dashboard' className="b-logo"><span></span></a>)}
                            <a className="home-icon" title='Home' onClick={() => handleLanding()}><span>Home</span></a>
                            {/* <a href="" className="folder-icon"><span>Projects</span></a> */}
                            <a className="chart-icon" title='Courses' onClick={() => navigate("/allcourses")}><span>Courses</span></a>
                            <a className="group-icon" title='Community' onClick={() => navigate("/community")}><span>Community</span></a>
                            {/* <a href="" className="settings-icon"><span>Settings</span></a> */}
                            <a className="logout-icon" title='Logout' onClick={() => setShowLogoutModel(true)}><span>Logout</span></a>
                        </nav>
                    </div>
                    <div className="app-container router-content">
                        { showAppHeader && <div className="app-header display-flex just-flex-start align-items-cent">
                            <div className="flex-grow-1">
                                <h1>Welcome {firstName} !</h1>
                            </div>
                            <div className="avatar">
                                <a onClick={() => navigate("/profile")}>
                                    <img 
                                        src={user?.profilePicture ? user.profilePicture : "/images/profile-picture-fallback.png"}
                                    />
                                </a>
                            </div>
                            {/* <div className="avatar logout" >
                                <BiLogOut size={30} onClick={() => handleLogOut()} />
                            </div> */}
                        </div>}
        
                        {useBanner && banners.length > 0 && <Banner banners={banners} />}
                        {children}
                    </div>
                </div>
            </section>

            {showLogoutModel && <div className='logout-model-container'>
                <div className='logout-model'>
                    <div className='logout-header'>
                        <img src='/images/Bversity_PrimaryLogo_White.svg' alt="Bversity" />
                    </div>
                    <div className='logout-content'>
                        <p>Are you sure to Logout from your account ?</p>
                        <div className='logout-content-btns'>
                            <button className='logout-btn-cancel' onClick={() => setShowLogoutModel(false)}>Cancel</button>
                            <button className='logout-btn-prime' onClick={() => handleLogOut()}>Logout</button>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default NavContainer;