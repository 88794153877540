import {URL} from "../shared/url";

export async function uploadToS3(payload : {
    image: File
}, accessToken: String) : Promise<string>{
    const { image } = payload;
    const [fileName, ext] = image.name.split('.')

    const res = await fetch(URL.uploadUsersAsset, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            fileName: `${fileName}_${new Date().getTime()}.${ext}`,
            fileType: `image/${ext}`
        })
    }) 
    return res.json().then(async ({ signedUrl, objectUrl }) => {
        try {
            await fetch(signedUrl, {
                method: 'PUT',
                body: image,
                headers: {
                    "Content-Type": `${image.type}`
                }
            })

            return Promise.resolve(objectUrl);
        } catch(e : any) {
            throw new Error(e.message);
        }
    }).catch(err => Promise.reject(err));
}
