import { Form } from 'react-bootstrap';
import React from 'react';

interface InputFieldProps {
  className?: string;
  InputFieldClass?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  errors: string[];
  type: string;
  validation: string;
}

const InputField: React.FC<InputFieldProps> = (props) => {
  return (
    <Form.Group className={`${props.className || ''}`}>
      <Form.Control
        data-testid="Form-input"
        type={props.type}
        placeholder={props.placeholder || ''}
        value={props.value}
        isInvalid={props.errors.length > 0}
        className={props.InputFieldClass || ''}
        isValid={props.validation != '' && props.errors.length === 0 && props.value.trim().length !== 0}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        onBlur={props.onBlur}
      />

      <Form.Control.Feedback className='text-start' type='invalid'>
        {props.errors.map((err) => (
          <div>{err}</div>
        ))}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default InputField;
