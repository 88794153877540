import {createAction} from "@reduxjs/toolkit";
import {AppCustomError} from "../errors";
import {
    AuthorCreatedAt,
    AuthorDescription,
    AuthorId,
    AuthorInstitution,
    AuthorName,
    AuthorOrder,
    AuthorProfilePicture,
    AuthorUpdatedAt,
    BannerCreatedAt, BannerDescription, BannerId, BannerStatus, BannerTitle, BannerUpdatedAt, BannerUrl,
    ChapterCreatedAt,
    ChapterDescription,
    ChapterId,
    ChapterName,
    ChapterOrder,
    ChapterUpdatedAt,
    CourseCoverPhoto,
    CourseCreatedAt,
    CourseCurrency,
    CourseDescription,
    CourseExperiments,
    CourseGlimpse,
    LastWatchedVideo,
    CourseId,
    CourseLevel,
    CourseName,
    CoursePrice,
    CourseProjects,
    CourseStatus,
    CourseThumbnail,
    CourseTrailer,
    CourseUpdatedAt,
    ExperimentCreatedAt,
    ExperimentDescription,
    ExperimentId,
    ExperimentName,
    ExperimentOrder,
    ExperimentUpdatedAt,
    ExperimentVideo,
    FaqAnswer,
    FaqCreatedAt,
    FaqId,
    FaqOrder,
    FaqQuestion,
    FaqUpdatedAt,
    LessonCreatedAt,
    LessonDescription,
    LessonId,
    LessonName,
    LessonOrder,
    LessonThumbnail,
    LessonUpdatedAt,
    LessonVideo,
    LessonPreviewAble,
    LessonType,
    LessonMetaData,
    LessonVideoDuration,
    IsShipping,
    ShipmentData
} from "../types/common";

export interface CoursePayload {
    courseId: number;
}

export interface CoursesPayload {
    accessToken: string;
}

export interface Course {
    id: CourseId | null;
    name: CourseName | null;
    description: CourseDescription | null;
    trailer: CourseTrailer | null;
    glimpse: CourseGlimpse | null;
    thumbnail: CourseThumbnail | null;
    coverPhoto: CourseCoverPhoto | null;
    status: CourseStatus | null;
    level: CourseLevel | null;
    price: CoursePrice | null;
    currency: CourseCurrency | null;
    createdAt: CourseCreatedAt | null;
    updatedAt: CourseUpdatedAt | null;
    authors: Author[] | [];
    experiments: Experiment[] | [];
    chapters: Chapter[] | [];
    faqs: Faq[] | [];
    enrollment: number | null;
    assignments: Assignment[] | [];
    lastWatchedVideo: LastWatchedVideo | null;
    isShipping: IsShipping | false;
    shipmentData: ShipmentData | null;
}

export interface Courses {
    id: CourseId | null;
    name: CourseName | null;
    description: CourseDescription | null;
    trailer: CourseTrailer | null;
    glimpse: CourseGlimpse | null;
    thumbnail: CourseThumbnail | null;
    coverPhoto: CourseCoverPhoto | null;
    status: CourseStatus | null;
    level: CourseLevel| null;
    price: CoursePrice | null;
    currency: CourseCurrency | null;
    createdAt: CourseCreatedAt | null;
    updatedAt: CourseUpdatedAt | null;
    authors: Author[] | [];
    experiments: CourseExperiments | null;
    projects: CourseProjects | null;
    chapters: number,
    enrollment: number;
    enrollmentStatus: string;
}

export interface Author {
    id: AuthorId;
    name: AuthorName;
    description: AuthorDescription;
    order: AuthorOrder;
    profilePicture: AuthorProfilePicture;
    institution: AuthorInstitution;
    createdAt: AuthorCreatedAt;
    updatedAt: AuthorUpdatedAt;
}

export interface Experiment {
    id: ExperimentId;
    name: ExperimentName;
    description: ExperimentDescription;
    video: ExperimentVideo;
    order: ExperimentOrder;
    createdAt: ExperimentCreatedAt;
    updatedAt: ExperimentUpdatedAt;
}

export interface Assignment {
    id: number,
    assignmentUrl: string, 
    status: string,
    createdAt: Date,
    updatedAt: Date | null,
    lesson: LessonChapterId,
    chapter: LessonChapterId,
    assignmentScore: number | null
}

export interface LessonChapterId {
    id: number
}

export interface CourseAnalytics {
    lastWatchedTime: number
    isCompleted: boolean
}

export interface Lesson {
    id: LessonId;
    name: LessonName;
    description: LessonDescription;
    thumbnail: LessonThumbnail;
    video: LessonVideo;
    order: LessonOrder;
    videoDuration: LessonVideoDuration;
    isPreview: LessonPreviewAble;
    lessonType: LessonType;
    metaData: LessonMetaData;
    createdAt: LessonCreatedAt;
    updatedAt: LessonUpdatedAt;
    courseAnalytics: CourseAnalytics[];
    questions: Question[]
    quizMaxRetry: number;
    isQuizEnabled: boolean;
    noOfQuestions: number;
    assignmentScore: number;
}

export interface Choice {
    id: number,
    text: string
}

export interface Question {
    id: number,
    text: string,
    score: number,
    choices: Choice[],
    answers: SubmittedAnswer[]
}

export interface SubmittedAnswer {
    attemptCount: number,
    createdAt: Date,
    choice: {
        id: number,
        text: string,
        isCorrect: boolean
    }
}

export interface Answer {
    questionId: number,
    choiceId: number
}

export interface Quiz {
    chapterOrExp: Chapter | Lesson,
    questions: Question[],
    isExperimentQuiz?: boolean,
    chapterId?: number
}

export interface Chapter {
    id: ChapterId | null;
    name: ChapterName | null;
    description: ChapterDescription | null;
    order: ChapterOrder | null;
    createdAt: ChapterCreatedAt | null;
    updatedAt: ChapterUpdatedAt | null;
    lessons: Lesson[] | [];
    questions: Question[],
    quizMaxRetry: number,
    isQuizEnabled: boolean,
    noOfQuestions: number,
    totalDuration: number
}

export interface Faq {
    id: FaqId | null;
    question: FaqQuestion | null;
    answer: FaqAnswer | null;
    order: FaqOrder | null;
    createdAt: FaqCreatedAt | null;
    updatedAt: FaqUpdatedAt | null;
}

export interface Banner {
    id: BannerId | null,
    title: BannerTitle | null,
    description: BannerDescription | null,
    banner: BannerUrl | null,
    status: BannerStatus | null,
    createdAt: BannerCreatedAt | null;
    updatedAt: BannerUpdatedAt | null;
}

export interface CourseAnalyticsPayload {
    id: CourseId,
    name: CourseName,
    enrollmentId: number,
    timestamp: Date,
    chapter: {
        id: ChapterId,
        name: ChapterName,
        lesson: {
            id: LessonId,
            name: LessonName,
            watchedDuration: number,
            isCompleted: boolean
        }
    }
}

export interface OrderPayload {
    amount: number,
    currency: string,
    receipt: string,
    notes: note_key,
    courseId: number,
    userName: string
}

export interface ShipmentOrderPayload {
    shippingAddress: string,
    userEmail: string,
    userPhone: string,
    purchaseComment: string,
    price: number,
    userName: string,
    lastName: string,
    enrollmentId: number,
    courseId: number,
    orderId: string,
    length: number,
    breadth: number,
    height: number,
    weight: number
}

export interface PaymentFailurePayload {
    orderId: string,
    errorCode: string,
    errorDescription: string,
    errorSource: string,
    errorStep: string,
    errorReason: string,
    errorMetadataOrderId: string,
    errorMetadataPaymentId: string,
}

export interface EnrollmentPayload {
    paymentId: string,
    orderId: string,
    paymentSignature: string,
    courseId: number,
    userName: string
}

export interface ChartPayload {
   groupBy: string
}

interface note_key {
    notes_key_1: string
}

export interface SubmitQuizPayload {
    experimentId?: LessonId | null,
    chapterId: ChapterId,
    answers: Answer[]
}

export interface GetTasksPaylod {
    courseId: number
}

export interface Task {
    id: number,
    taskId: number,
    status: string,
    task : string
}

export interface PutTaskSuccess {
    status: string,
    cognitoSub: string,
    courseTask: {
        task:string
    },
    createdAt: string,
    id: number
}

export interface SubmitQuizSuccessResponse {
    experimentId?: LessonId | null,
    chapterId: ChapterId,
    quizResponses: Question[]
}

export interface SubmitQuizErrorResponse {
    errorCode: string,
    message: string
}

export interface AssignmentPayload {
    assignmentUrl: string,
    lessonId: number, 
    chapterId: number,
    enrollmentId: number,
}

export interface EnrollmentStatusUpdatePayload {
    courseId: CourseId,
    func: () => void
}

export interface EnrollmentStatusUpdateResponse {
    id: number,
    status: string,
    course: Course
}

export const getCourseRequest = createAction<CoursePayload>('GET_COURSE_REQUEST');
export const getCourseSuccess = createAction<Course>('GET_COURSE_SUCCESS');
export const getCourseFailure = createAction<AppCustomError>('GET_COURSE_FAILURE');

export const getCoursesRequest = createAction<CoursesPayload>('GET_COURSES_REQUEST');
export const getCoursesSuccess = createAction<Courses[]>('GET_COURSES_SUCCESS');
export const getCoursesFailure = createAction<AppCustomError>('GET_COURSES_FAILURE');

export const getTasksRequest = createAction<GetTasksPaylod>('GET_TASKS_REQUEST');
export const getTasksSuccess = createAction<Task[]>('GET_TASKS_SUCCESS');
export const getTasksFailure = createAction<AppCustomError>('GET_TASKS_FAILURE');

export const putTaskRequest = createAction<Task>('PUT_TASK_REQUEST');
export const putTaskSuccess = createAction<PutTaskSuccess>('PUT_TASK_SUCCESS');
export const putTaskFailure = createAction<AppCustomError>('PUT_TASK_FAILURE');

export const getCourseBannerSuccess = createAction<Banner[]>('GET_COURSE_BANNER_SUCCESS');
export const getCourseBannerFailure = createAction<AppCustomError>('GET_COURSE_BANNER_FAILURE');

export const publishCourseAnalyticsRequest = createAction<CourseAnalyticsPayload>('PUBLISH_COURSE_ANALYTICS_REQUEST');
export const publishCourseAnalyticsSuccess = createAction<CourseAnalyticsPayload>('PUBLISH_COURSE_ANALYTICS_SUCCESS');

export const uploadAssignmentRequest = createAction<AssignmentPayload>('UPLOAD_ASSIGNMENT_REQUEST');
export const uploadAssignmentSuccess = createAction<any>('UPLOAD_ASSIGNMENT_SUCCESS');
export const uploadAssignmentFailure = createAction<AppCustomError>('UPLOAD_ASSIGNMENT_FAILURE');
export const uploadAssignmentReset = createAction('UPLOAD_ASSIGNMENT_RESET');

export const createPaymentOrderRequest = createAction<OrderPayload>('CREATE_PAYMENT_ORDER_REQUEST');
export const createPaymentOrderSuccess = createAction<OrderPayload>('CREATE_PAYMENT_ORDER_SUCCESS');
export const createPaymentOrderFailure = createAction<AppCustomError>('CREATE_PAYMENT_ORDER_FAILURE');

export const createShipmentOrderRequest = createAction<ShipmentOrderPayload>('CREATE_SHIPMENT_ORDER_REQUEST');
export const createShipmentOrderSuccess = createAction<ShipmentOrderPayload>('CREATE_SHIPMENT_ORDER_SUCCESS');
export const createShipmentOrderFailure = createAction<AppCustomError>('CREATE_SHIPMENT_ORDER_FAILURE');

export const createCourseEnrollmentRequest = createAction<EnrollmentPayload>('CREATE_COURSE_ENROLLMENT_REQUEST');
export const createCourseEnrollmentSuccess = createAction<EnrollmentPayload>('CREATE_COURSE_ENROLLMENT_SUCCESS');
export const createCourseEnrollmentFailure = createAction<AppCustomError>('CREATE_COURSE_ENROLLMENT_FAILURE');

export const updateCourseEnrollmentStatusRequest = createAction<EnrollmentStatusUpdatePayload>('UPDATE_COURSE_ENROLLMENT_STATUS_REQUEST');
export const updateCourseEnrollmentStatusSuccess = createAction<EnrollmentStatusUpdateResponse>('UPDATE_COURSE_ENROLLMENT_STATUS_SUCCESS');
export const updateCourseEnrollmentStatusFailure = createAction<AppCustomError>('UPDATE_COURSE_ENROLLMENT_STATUS_FAILURE');

export const submitQuizRequest = createAction<SubmitQuizPayload>('SUBMIT_QUIZ_REQUEST');
export const submitQuizSuccess = createAction<SubmitQuizSuccessResponse>('SUBMIT_QUIZ_SUCCESS');
export const submitQuizFailure = createAction<SubmitQuizErrorResponse>('SUBMIT_QUIZ_FAILURE');
export const resetQuizResponse = createAction('RESET_QUIZ_RESPONSE');

export const recordEnrollmentFailureRequest = createAction<PaymentFailurePayload>('RECORD_ENROLLMENT_REQUEST');
export const recordEnrollmentFailureSuccess = createAction<PaymentFailurePayload>('RECORD_ENROLLMENT_SUCCESS');
export const recordEnrollmentFailure = createAction<AppCustomError>('RECORD_ENROLLMENT_FAILURE');

export const getNextCoursesRequest = createAction('GET_NEXT_COURSES_REQUEST');
export const getNextCoursesSuccess = createAction<Courses[]>('GET_NEXT_COURSES_SUCCESS');

export const resetPaymentOrder = createAction('RESET_PAYMENT_ORDER');

export const toggleModal = createAction<boolean>('TOGGLE_MODAL');
