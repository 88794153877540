import ReactGA from "react-ga";
export const gaEvents = {
  WATCH_GLIMPSE: "Watch glipmse",
  WATCH_TRAILER: "Watch trailer",
  SHOW_COURSE: 'Show course'
}
const useAnalyticsEventTracker = (category: string) => {
  const eventTracker = (action: string, courseId: number | null) => {
    ReactGA.event({category, action, label: String(courseId)});
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;