import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getNextCoursesRequest } from "../actions/courseActions";
import CourseInfo from "../components/Dashboard/CourseInfo";
import NextCourses from "../components/Dashboard/NextCourses";
import { AppState } from "../store";

import { useNavigate } from "react-router-dom";
import {
  getLastViewedRequest,
  getPerformanceDataRequest,
  getTimeSpentDataRequest,
} from "../actions/analyticsAction";
import CalendarWrapper from "../components/Dashboard/Calendar";
import StatusWrapper from "../components/Dashboard/StatusWrapper";
import NavContainer from "../components/NavContainer";
import TaskContainer from "../components/Dashboard/TaskContainer";
import ProfileProgress from "../components/Dashboard/ProfileProgress";
import "./Dashboard.scss";
import { profileDataRequest, refreshTokenRequest } from "../actions/userActions";
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";
import OrderStatusContainer from "../components/OrderStatusContainer";
import OnBoardModal from "./OnBoardModal";

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCourse, setSelectedCourse] = useState<number>();
  const [showOrderStatus, setShowOrderStatus] = useState<number>();

  const { isLoggedIn, completionPercentage, profilePicture, accessToken } = useSelector(
    (state: AppState) => state.user
  );

  const { timeSpentData, performanceData, lastViewed } = useSelector(
    (state: AppState) => state.analytics
  );

  const { nextCourses } = useSelector((state: AppState) => state.courses);

  const { course } = useSelector((state: AppState) => state.course);

  const timeSpentChartLables: string[] = Object.keys(timeSpentData);

  const timeSpentChartValue: number[] = Object.values(timeSpentData).map(
    (value: any) => parseInt(value)
  );

  const timeSpentChartOptionsChange = (type: string) =>
    dispatch(getTimeSpentDataRequest({ groupBy: type }));

  const timeSpentProps = {
    dropDownOptions: ["week", "month"],
    changeData: timeSpentChartOptionsChange,
    heading: "Time Spent",
  };

  const performanceChartLables: string[] = Object.keys(performanceData);
  const performanceChartValue: number[] = Object.values(performanceData).map(
    (value: any) => parseInt(value)
  );
  const performanceChartOptionsChange = (type: string) =>
    dispatch(getPerformanceDataRequest({ groupByForPerformance: type }));

  const lineChartProps = {
    dropDownOptions: ["week", "month"],
    changeData: performanceChartOptionsChange,
    heading: "Performance",
  };

  useEffect(() => {
    document.title = 'Dashboard - Bversity';
    dispatch(
      getPerformanceDataRequest({
        groupByForPerformance: lineChartProps.dropDownOptions[0],
      })
    );
    dispatch(getNextCoursesRequest());
    dispatch(getLastViewedRequest());
    dispatch(profileDataRequest());
  }, []);

  const requestCallback = (id: number) => {
    setSelectedCourse(id);
  };

  useEffect(() => {
    if (course && selectedCourse) {
      navigate(`/courses/${selectedCourse}`);
    }
    if (showOrderStatus) {
      navigate(`/orderStatus/${showOrderStatus}`);
    }
    if (!isLoggedIn) {
      navigate("/");
    }

  }, [selectedCourse, isLoggedIn, showOrderStatus]);

  useEffect(() => {
    if(accessToken) {
      const userData: any = jwtDecode(accessToken);
      const isExpired = dayjs.unix(userData.exp).diff(dayjs()) < 1;

      if(isExpired) {
        dispatch(refreshTokenRequest());
      }
    }
  });


  return (
    <NavContainer>
      <OnBoardModal />
      <div className="dashboard-grid">

        <div className="dashboard-grid-small">

          {completionPercentage && Math.round(completionPercentage) < 100 &&  (
            <div className="dashboard-grid-item-small">
              <ProfileProgress
                profilePicture={profilePicture ? profilePicture : "/images/profile-picture-fallback.png"}
                percentage={completionPercentage}
              />
            </div>
          )}

          <div className="dashboard-grid-item-small">
            {lastViewed && <CourseInfo {...lastViewed} />}
          </div>

          <div className="dashboard-grid-item-small">
            <div className="box">
              <h4>Tasks</h4>
              {lastViewed && lastViewed.chapter?.course
                ? <div className="box-content">
                    <TaskContainer courseId={lastViewed.chapter.course.id}></TaskContainer>
                  </div>
                : <div>No tasks available</div>}
            </div>
          </div>

        </div>

        <div className="dashboard-grid-large">

          <div className="dashboard-grid-item-large">
            {lastViewed && lastViewed.chapter?.course && lastViewed.chapter?.course.isShipping && 
             lastViewed.chapter.course.shipmentOrder.length > 0 && lastViewed.chapter?.course?.shipmentOrder[0]?.status !== 'Delivered' ?
              <div className="box" onClick={() => setShowOrderStatus(lastViewed.chapter.course.id)}>
                <h4>Order Status</h4>
                <OrderStatusContainer courseId={lastViewed.chapter.course.id} shipmentOrder={lastViewed.chapter ?
                  lastViewed.chapter.course.shipmentOrder[0] : lastViewed.courseEnrollment.course.shipmentOrder[0]}></OrderStatusContainer>
              </div>
              :
              <>
                {lastViewed && lastViewed.courseEnrollment?.course && lastViewed.courseEnrollment?.course.shipmentOrder[0]?.status !== 'Delivered' ?
                  <div className="box" onClick={() => setShowOrderStatus(lastViewed.courseEnrollment.course.id)}>
                    <h4>Order Status</h4>
                  <OrderStatusContainer courseId={lastViewed.courseEnrollment.course.id} shipmentOrder={lastViewed.courseEnrollment.course.shipmentOrder[0]}></OrderStatusContainer></div>
                  : <div></div>
                }
              </>
              }
          </div>

          <div className="dashboard-grid-item-large">
            {lastViewed && lastViewed.chapter?.course ? (
              <StatusWrapper
                lastViewedCourseId={lastViewed.chapter.course.id}
                lastViewedCourseName={lastViewed.chapter.course.name}
              />
            ) : (
              <div className="box">No status data available</div>
            )}
          </div>

          <div className="dashboard-grid-item-large">
            <div className="box dashboard-calendar-item">
              <h4>Calendar</h4>
              <div className="box-content">
                <CalendarWrapper />
              </div>
            </div>
          </div>

        </div>

      </div>


      {nextCourses && nextCourses.length > 0 && (
        <div style={{ marginTop: 50 }}>
          <h4>What's next?</h4>
          <NextCourses
            coursesList={nextCourses}
            requestCallback={requestCallback}
          ></NextCourses>
        </div>
      )}
    </NavContainer>
  );
};

export default Dashboard;
