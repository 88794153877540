import { Courses } from "../../actions/courseActions";
import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

interface NextCoursesProps{
    coursesList : Courses[]
    requestCallback: (id: number) => void
}

const NextCourses: React.FC<
    NextCoursesProps
> = (props) => {

    const courseItem = (course: Courses, index: number) => {
        return (
            <div className="dashboard-card" onClick={() => props.requestCallback(course.id ?? 0)} key={index}>
                <div className="d-card-title">
                    <img src={course.thumbnail ?? ''} alt={course.name ? course.name : ''} />
                    <span>{course.name}</span>
                </div>
                <div className="avatar-with-detail pad-top-20 pad-lft-20 pad-rgt-20">
                    <div className="avatar">
                        <img src={course.authors[0]?.profilePicture}/>
                    </div>
                    <div className="avatar-details">
                        <h5>{course.authors[0]?.name}</h5>
                        <span>{course.authors[0]?.institution}</span>
                    </div>
                </div>
                <div className="display-flex just-space-around">
                    <span className="label-with-icon projects-icon">{course.chapters} Chapters</span>
                    <span className="label-with-icon experiments-icon">{course.experiments} Experiments</span>
                </div>
                <a onClick={() => props.requestCallback(course.id ?? 0)} className="pointer-button card-btn-on-hover">Start now</a>
            </div>
        );
    }

    const courseResponsive = { 0:{ items:1 }, 600:{ items:2 }, 1000:{ items:3 }}

    return <>
    {props.coursesList.length > 0 ? (
                            <div className="whatsNextDashboard">
                                <OwlCarousel 
                                    loop={true}
                                    items={4} 
                                    margin={8} 
                                    nav={false}
                                    autoplay={true} 
                                    responsive={courseResponsive}
                                    autoplaySpeed={3000}>
                                    {props.coursesList?.map((course, index) =>
                                        <div key={index}>{courseItem(course, index)}</div>)}
                                </OwlCarousel>
                            </div>
                            )
                        : (<div className="no-course-text flex-grow-1">No courses available!</div>)}
    </>
}

export default NextCourses