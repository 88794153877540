import { combineReducers } from 'redux';
import userReducer from './userReducer';
import courseReducer from "./courseReducer";
import coursesReducer from "./coursesReducer";
import analyticsReducer from './analyticsReducer';

export default combineReducers({
  user: userReducer,
  course: courseReducer,
  courses: coursesReducer,
  analytics: analyticsReducer
});
