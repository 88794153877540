import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers/rootReducer';

import rootSaga from './sagas/rootSaga';


const persistConfig = {
    key: 'root',
    storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleWare = createSagaMiddleware()
//TODO: Use createStorage from @reduxjs/toolkit
const store = createStore(persistedReducer, process.env.ENV === 'Dev' 
    ? composeWithDevTools(applyMiddleware(sagaMiddleWare))
    : applyMiddleware(sagaMiddleWare));

sagaMiddleWare.run(rootSaga);

export default store;
export type AppState = ReturnType<typeof rootReducer>;
export const persistor = persistStore(store);