import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getLastViewedSuccess, getPerformanceDataSuccess, getTimeSpentDataSuccess } from '../actions/analyticsAction';
import { CourseInfoProps } from '../components/Dashboard/CourseInfo';

interface AnalyticsData{
    timeSpentData : Object,
    performanceData: Object,
    lastViewed: CourseInfoProps | null
}

const initialState: AnalyticsData = {
   timeSpentData : {},
   performanceData : {},
   lastViewed: null
};


const analyticsReducer = createReducer(initialState, (builder) => {
    builder
    .addCase(getTimeSpentDataSuccess,
        (state: AnalyticsData, action: PayloadAction<Object>) => ({
            
        ...state,
        timeSpentData: action.payload
    }))

    .addCase(getPerformanceDataSuccess,
        (state: AnalyticsData, action: PayloadAction<Object>) => ({
            
        ...state,
        performanceData: action.payload
    }))

    .addCase(getLastViewedSuccess,
         (state: AnalyticsData, action: PayloadAction<CourseInfoProps>) => ({
            
        ...state,
        lastViewed: action.payload
    }))
})

export default analyticsReducer;