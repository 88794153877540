import {createReducer, PayloadAction} from '@reduxjs/toolkit';

import {
    loginSuccess,
    loginFailure,
    clearErrorMessages,
    SendOTPSuccessPayload,
    sendOTPSuccess,
    sendOTPFailure,
    resetOTPSentFlag,
    fetchSignUp,
    SignUpPayload,
    signUpSuccess,
    signUpFailure,
    SignUpRequestSuccessPayload,
    toggleLoading, logOut,
    updateAddressRequestSuccess,
    updateAddressRequestFailure,
    updateAddressRequest,
    UpdateAddressPayload,
    profileDataRequestSuccess,
    ProfileSuccessPayload,
    refreshTokenSuccess,
    RefreshTokenSuccessPayload,
    updateOnboardRequest,
    updateOnboardRequestFailure,
    updateOnboardRequestSuccess,
    UpdateOnboardPayload,
} from '../actions/userActions';
import {AppCustomError} from '../errors';
import {
    UserPhoneNumber,
    UserEmailId,
    UserFirstName,
    UserLastName,
    UserRole,
    UserSession,
    UserAccessToken,
    UserTokenExpiry,
    UserRefreshToken,
    UserAddress
} from '../types/common';

interface UserState {
    phoneNumber: UserPhoneNumber | null;
    firstName: UserFirstName | null;
    lastName: UserLastName | null;
    email: UserEmailId | null;
    address: UserAddress | null;
    role: UserRole | null;
    isLoggedIn: boolean;
    errorMessage: string | null;
    OTPError: any;
    isOTPSent: boolean;
    session: UserSession | null;
    accessToken: UserAccessToken | null;
    refreshToken: UserRefreshToken | null;
    expiresIn: UserTokenExpiry | null;
    isLoginError: boolean;
    isSignUp: boolean;
    isSignUpError: boolean;
    isSignedUp: boolean;
    isLoading: boolean;
    isUpdateAddressError: boolean;
    isUpdateOnboardError: boolean;
    completionPercentage: number | null;
    profilePicture: string | null;
    id: string | null;
    dateOfBirth: string | null;
    instituteName: string | null;
    isOnboard: boolean;
    pageMap: string | null
}

const initialState: UserState = {
    phoneNumber: null,
    firstName: null,
    lastName: null,
    email: null,
    address: null,
    role: null,
    isLoggedIn: false,
    errorMessage: null,
    OTPError: null,
    isOTPSent: false,
    session: null,
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
    isLoginError: false,
    isSignUp: false,
    isSignUpError: false,
    isSignedUp: false,
    isLoading: false,
    isUpdateAddressError: false,
    isUpdateOnboardError: false,
    completionPercentage: null,
    profilePicture: null,
    id: null,
    dateOfBirth: null,
    instituteName: null,
    isOnboard: false,
    pageMap: null
};

const userReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(loginSuccess, (state: UserState, action: PayloadAction<any>) => ({
            ...state,
            accessToken: action.payload.AccessToken,
            refreshToken: action.payload.RefreshToken,
            expiresIn: action.payload.ExpiresIn,
            firstName: action.payload.User.firstName,
            lastName: action.payload.User.lastName,
            email: action.payload.User.email,
            address: action.payload.User.address,
            isOnboard: action.payload.User.isOnboard,
            isLoggedIn: true,
            isLoginError: false,
            pageMap: action.payload.PageMap
        }))

        .addCase(loginFailure, (state: UserState, action: PayloadAction<any>) => ({
            ...state,
            errorMessage: action.payload.message,
            invalidCredentials: action.payload.code === '403',
            isLoginError: true,
            isLoading: false
        }))

        .addCase(
            sendOTPSuccess,
            (state, action: PayloadAction<SendOTPSuccessPayload>) => ({
                ...state,
                session: action.payload.session,
                phoneNumber: action.payload.phoneNumber,
                OTPError: null,
                isOTPSent: true,
                isLoading: false
            })
        )

        .addCase(
            sendOTPFailure,
            (state, action: PayloadAction<AppCustomError>) => ({
                ...state,
                session: null,
                isOTPSent: false,
                OTPError: action.payload.message,
                errorMessage: action.payload.message,
                isLoading: false
            })
        )

        .addCase(
            resetOTPSentFlag,
            (state) => ({
                ...state,
                isOTPSent: false
            })
        )

        .addCase(
            fetchSignUp,
            (state, action: PayloadAction<SignUpPayload>) => ({
                ...state,
                isSignUp: action.payload.isSignUp,
                isOTPSent: false
            })
        )

        .addCase(
            signUpSuccess,
            (state, action: PayloadAction<SignUpRequestSuccessPayload>) => ({
                ...state,
                isSignedUp: true,
                isSignUp: false,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                role: action.payload.role,
                phoneNumber: action.payload.phoneNumber,
                isLoading: false
            })
        )

        .addCase(
            signUpFailure,
            (state, action: PayloadAction<AppCustomError>) => ({
                ...state,
                isSignUpError: true,
                errorMessage: action.payload.message,
                isLoading: false
            })
        )

        .addCase(
            clearErrorMessages,
            (state) => ({
                ...state,
                errorMessage: null,
                invalidCredentials: false,
                OTPError: null,
                isLoginError: false,
                isSignUpError: false,
                isLoading: false,
            })
        )

        .addCase(
            updateAddressRequest,
            (state) => ({
                ...state,
                isUpdateAddressError: false,
            })
        )

        .addCase(
            updateAddressRequestFailure,
            (state, action: PayloadAction<AppCustomError>) => ({
                ...state,
                errorMessage: action.payload.message,
                isUpdateAddressError: true,
            })
        )

        .addCase(
            updateAddressRequestSuccess,
            (state, action: PayloadAction<UpdateAddressPayload>) => ({
                ...state,
                isUpdateAddressError: false,
                address: action.payload.address
            })
        )

        .addCase(
            updateOnboardRequest,
            (state) => ({
                ...state,
                isUpdateOnboardError: false,
            })
        )

        .addCase(
            updateOnboardRequestFailure,
            (state, action: PayloadAction<AppCustomError>) => ({
                ...state,
                errorMessage: action.payload.message,
                isUpdateOnboardError: true,
            })
        )

        .addCase(
            updateOnboardRequestSuccess,
            (state, action: PayloadAction<UpdateOnboardPayload>) => ({
                ...state,
                isUpdateOnboardError: false,
                isOnboard: action.payload.isOnboard
            })
        )

        .addCase(
            toggleLoading,
            (state, action: PayloadAction<boolean>) => ({
                ...state,
                isLoading: action.payload,
            })
        )

        .addCase(
            profileDataRequestSuccess,
            (state, action: PayloadAction<ProfileSuccessPayload>) => ({
                ...state,
                ...action.payload,
            })
        )

        .addCase(
            refreshTokenSuccess,
            (state, action: PayloadAction<RefreshTokenSuccessPayload>) => ({
                ...state,
                accessToken: action.payload.AccessToken,
                expiresIn: action.payload.ExpiresIn
            })
        )

        .addCase(
            logOut,
            () => (initialState)
        );

});

export default userReducer;
