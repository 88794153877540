import React from 'react';
import { Spinner } from 'react-bootstrap';

const SpinnerElement = () => {
    return (
        <div className='spinner-container'>
            <Spinner className='spinner-element' animation="grow" variant="success" />
        </div>
    )
}

export default SpinnerElement;