import * as React from 'react';
import ReactPlayer from 'react-player';
import { useState, useRef, useCallback, useEffect } from 'react';



interface ReactPlayerWrapperProps {
    url?: string
    played: number
    onProgress?: Function
    onEnded?: Function
    width?: string
    height?: string
    playing?: boolean
}


const ReactPlayerWrapper: React.FC<
    ReactPlayerWrapperProps
> = (props) => {

    const [played, setPlayed] = useState(0)
    const [playing, setPlaying] = useState(!!props.playing)
    const [isReady, setIsReady] = useState(false)

    const handleProgress = (progress: {
        played: number;
        playedSeconds: number;
        loaded: number;
        loadedSeconds: number;
    }) => {
        if(props.onProgress) {
            props.onProgress(progress)
        } else {
            setPlayed(progress.playedSeconds) 
        }
    };

    const handleSeek = (playedSeconds: number) => {
        setPlayed(playedSeconds)
    };

    const handlePlay = () => {
        setPlaying(true)
    }


    const handlePause = () => {
        setPlaying(false)
    }

    const handleEnded = () => {
        props.onEnded && props.onEnded();
    }


    const playerRef = useRef<ReactPlayer>(null);

    const onReady = useCallback(() => {
        if (!isReady) {
         playerRef?.current?.seekTo(props.played, 'seconds');
         setPlayed(props.played)
         setIsReady(true);
        }
    }, [isReady]);

    useEffect(() => {
        setPlaying(!!props.playing);
    }, [props.playing]);

    const DEFAULT_STATE = {
        url: undefined,
        pip: false,
        playing: false,
        controls: true,
        light: false,
        volume: 0.8,
        muted: false,
        played: 1,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
        seeking: false,
        progressIntervalInMilliSeconds: 5000
    };


    return (
        <>
            <section>
                <div 
                    className='reactPlayerContainer'
                    onContextMenu={e => e.preventDefault()}>
                    <ReactPlayer
                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        ref={playerRef}
                        width={props.width || "100%"}
                        height={props.height || "100%"}
                        url={props.url}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        pip={DEFAULT_STATE.pip}
                        playing={playing}
                        controls={DEFAULT_STATE.controls}
                        playbackRate={DEFAULT_STATE.playbackRate}
                        volume={DEFAULT_STATE.volume}
                        muted={DEFAULT_STATE.muted}
                        onError={(e) => console.log('onError', e)}
                        onProgress={handleProgress}
                        onReady={onReady}
                        onSeek={handleSeek}
                        progressInterval={DEFAULT_STATE.progressIntervalInMilliSeconds}
                        onEnded={handleEnded}
                    />
                </div>
            </section>
        </>
    );
}


export default ReactPlayerWrapper;
