import { all, fork } from 'redux-saga/effects';

import analyticsSaga from './analyticsSaga';
import courseSaga from "./courseSaga";
import userSaga from './userSaga';

export default function* root() {
    yield all([
        ...userSaga.map(saga => fork(saga)),
        ...courseSaga.map(saga => fork(saga)),
        ...analyticsSaga.map(saga => fork(saga))
    ]);
}
