import { PerformanceChartPayload } from "../actions/analyticsAction";
import { ChartPayload } from "../actions/courseActions";
import { URL } from '../shared/url';


export async function getTimeSpentData(req: ChartPayload, accessToken: string): Promise<any> {
    const response = await fetch(`${URL.getTimeSpent}?dataBy=${req.groupBy}`,
        { method: 'GET', headers: { Authorization: `Bearer ${accessToken}` } });

    return response.json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function getPerformanceData(req: PerformanceChartPayload, accessToken: string): Promise<any> {
    const response = await fetch(`${URL.getPerformance}?dataBy=${req.groupByForPerformance}`,
        { method: 'GET', headers: { Authorization: `Bearer ${accessToken}` } });
    return response.json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function getLastViewed(accessToken: string): Promise<any> {
    const response = await fetch(`${URL.getLastViewed}`,
        { method: 'GET', headers: { Authorization: `Bearer ${accessToken}` } });
    return response.json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}