import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './VideoModal.scss';

import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../store";
import { Button } from 'react-bootstrap';
import { updateOnboardRequest } from '../actions/userActions';

const OnBoardModal: React.FC = () => {
    const dispatch = useDispatch();
    const { isOnboard } = useSelector((state: AppState) => state.user);

    const [isModalOpen, setIsModalOpen] = useState(!isOnboard);

    const handleClose = () => {
        setIsModalOpen(false);
        dispatch(updateOnboardRequest({
            isOnboard: true
        }));
    }

    return (
        <>
            <Modal
                className="video-modal"
                size="lg"
                show={isModalOpen}
                onHide={handleClose}
                backdrop={'static'}
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Body className="border-0">
                    <h2 className="app-section-page-header-onboard">Congratulations! The <span>Bversity Family</span> is on cloud nine to have you.<br />We're glad to onboard our passionate <span>Bvers</span>ite !<br />
                    Learning & Growth is all yours!</h2>
                    <video src={`${process.env.COURSES_ONBOARDING_ASSET_URL}`} width="100%" height="80%" autoPlay controls controlsList='nodownload' onContextMenu={e => e.preventDefault()}/>
                    <Button variant="outline-light" style={{marginTop: '1rem', float: 'right'}} 
                            onClick={() => handleClose()}>Continue &rarr;</Button>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default OnBoardModal;
