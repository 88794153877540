import { useEffect } from "react";
import * as React from "react";
import { useDispatch } from "react-redux";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { ShipmentOrder } from "./Dashboard/CourseInfo";

interface OrderStatusPros {
  courseId: number;
  shipmentOrder: ShipmentOrder
}

const OrderStatusContainer: React.FC<OrderStatusPros> = (props) => {
  const steps = ["NEW","Shipped","In Transit","Out For Delivery","Delivered"];

  const getStepPosition = () => {
    const statusIndex = steps.findIndex(status => status === props.shipmentOrder?.status);
    const position = 100 * ((statusIndex + 1) / steps.length);
    return position;
  };

  return (
    <div className="orderStatusContainer">
      <ProgressBar
        width={500}
        percent={getStepPosition()}
        filledBackground="linear-gradient(to right, #41ad49, #41ad49)"
      >
        {steps.map((step, index, arr) => {
          return (
            <Step
              key={index}
              transition="scale"
              children={({ accomplished }) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    width: 20,
                    height: 20,
                    color: "black",
                    backgroundColor: accomplished ? "green" : "gray"
                  }}
                >
                  <br />
                  <br />
                  <br />
                  <br />
                  {step}
                </div>
              )}
            />
          );
        })}
      </ProgressBar>
    </div>
  );
};

export default OrderStatusContainer;
