import React, { SyntheticEvent, useState } from "react";
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import "../../containers/Profile.scss";

interface EditImageProps {
    show: boolean,
    handleClose: () => void,
    onSubmit : (profile : File | null) => void
    message: string| null
}

const EditImage: React.FC<EditImageProps> = (props) => {

    const [profile, setProfile] = useState<null | File>(null)

    const change = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        setProfile(file)
    }

    
    return <>
        <Modal className="address-modal" show={props.show} onHide={props.handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{'User Information'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={e=> { e.preventDefault(); props.onSubmit(profile); }} encType='multipart/form-data'>
                    <Col xs={12} className="mb-3">
                        <Form.Group controlId="formFileUpload">
                            <Form.Label>
                                Choose Profile image to upload <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                name="profile"
                                type="file"
                                accept="image/*"
                                onChange={(event: SyntheticEvent) => change(event)}
                                required
                            />
                        </Form.Group>
                        {/*{*/}
                        {/*    formData?.banner && (*/}
                        {/*        <div className='mb-3'>*/}
                        {/*            <Button variant="outline-white" className="me-1 mb-1" style={{ marginTop: '10px', float: 'right'}} onClick={() => setPreviewImage(formData.banner)}>*/}
                        {/*                <ImageIcon size="15px" className="dropdown-item-icon me-1" />*/}
                        {/*                Preview*/}
                        {/*            </Button>*/}
                        {/*        </div>*/}
                        {/*    )*/}
                        {/*}*/}
                    </Col>
                    <Row>
                        <Form.Text className="text-muted">
                            Upload image must meet our profile image
                            quality standards. Important guidelines: 750x440
                            pixels; .jpg, .jpeg,. gif, or .png. no text on the image.
                        </Form.Text>
                    </Row>
                    <Col xs={12} className="profile-button-group">
                        <Button variant="primary" type="submit">
                            Upload
                        </Button>
                    </Col>
                    {props.message && <span className="text-danger">{props.message}</span>}
                </Form>
            </Modal.Body>
        </Modal>
    </>
}
export default EditImage