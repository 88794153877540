import React from "react";
import { useNavigate } from 'react-router-dom';

import './CourseInfo.scss';

interface Author {
    profilePicture: string
}

export interface CourseInfoProps {
    lesson: {
        video: string,
        videoDuration: number,
        name: string,
        thumbnail: string
    }
    lastWatchedTime: number,
    chapter: {
        name: string,
        course: {
            id: number,
            thumbnail: string,
            authors: Author[],
            name: string,
            isShipping: boolean,
            shipmentOrder: ShipmentOrder[]
        }
    },
    courseEnrollment: {
        id: number,
        status: string,
        cognitoSub: string,
        createdAt: string,
        updatedAt: string,
        userName: string,
        shipmentOrder: any,
        course: Course
    }
}

interface Course {
    id: number,
    shipmentOrder: ShipmentOrder[]
}

export interface ShipmentOrder {
    awbCode: string,
    courierCompanyId: string,
    courierName: string,
    createdAt: string,
    orderId: string,
    shipmentId: string,
    status: string,
    statusCode: number
}

const CourseInfo: React.FC<
    CourseInfoProps
> = (props) => {
    const navigate = useNavigate();

    const redirectToCourse = () => {
        navigate(`/courses/${props.chapter.course.id}`);
    }
    return (
        <div className="CourseInfo">
            {props.lesson ? (<>
                <h3 className="heading" title={props.chapter.name}>{props.chapter.name}</h3>
                <div className="thumbnail-container" style={{backgroundImage: `url(${props.lesson.thumbnail})`}}>
                    <button className="thumb-play-btn" onClick={redirectToCourse} title="Play"></button>
                </div>
                <div className="footer">
                    <div className="content">
                        <h6>{props.lesson.name}</h6>
                        {props.chapter.course.authors.map(({ profilePicture }, index)=> 
                            (<img src={profilePicture} className="avatar" key={index} />)
                        )}
                    </div>
                    <div className="footer-flex">
                        <div className="footer-progress-cont">
                            <div className="progress-bar-overlay">
                                <div
                                    className="progress-indicator"
                                    style={{ width: `${Math.round((props.lastWatchedTime / props.lesson.videoDuration) * 100)}%` }}
                                />
                            </div>
                            <div className="progress-info">{Math.round((props.lastWatchedTime / props.lesson.videoDuration) * 100)}%</div>
                        </div>
                        <button className="resume-btn" onClick={redirectToCourse}>Resume</button>
                    </div>
                </div>
            </>) : <div className="no-data">No recently watched lesson</div>}
        </div>
    )
}

export default CourseInfo
