import { call, put, putResolve, select, takeEvery } from 'redux-saga/effects';
import {
    CourseAnalyticsPayload, CoursePayload, createCourseEnrollmentFailure, createCourseEnrollmentRequest, createCourseEnrollmentSuccess, createPaymentOrderFailure, createPaymentOrderRequest, createPaymentOrderSuccess, EnrollmentPayload, getCourseBannerFailure,
    getCourseBannerSuccess,
    getCourseFailure,
    getCourseRequest,
    getCoursesFailure,
    getCoursesRequest,
    getCoursesSuccess,
    getCourseSuccess, getNextCoursesRequest, getNextCoursesSuccess, OrderPayload, PaymentFailurePayload, publishCourseAnalyticsRequest, publishCourseAnalyticsSuccess,
    recordEnrollmentFailureRequest, submitQuizRequest, submitQuizSuccess, submitQuizFailure, SubmitQuizPayload, uploadAssignmentRequest, AssignmentPayload,
    uploadAssignmentFailure, uploadAssignmentSuccess, updateCourseEnrollmentStatusRequest, updateCourseEnrollmentStatusSuccess, updateCourseEnrollmentStatusFailure, EnrollmentStatusUpdatePayload,
    putTaskRequest, getTasksRequest, GetTasksPaylod, Task, getTasksSuccess, getTasksFailure, putTaskSuccess, putTaskFailure, createShipmentOrderRequest, ShipmentOrderPayload, createShipmentOrderSuccess, createShipmentOrderFailure
} from "../actions/courseActions";
import { toggleLoading, logOut, refreshTokenRequest } from "../actions/userActions";
import { createCustomError } from '../errors';
import {
    createCourseEnrollment, createPaymentOrder, getCourse,
    getCourseBanners,
    getCourses, getNextCourses, publishAnalytics, recordEnrollmentFailure, submitQuiz, uploadAssignments, updateCourseEnrollmentStatus,
    getTasks, putTask, createShipmentOrder
} from "../services/courses.service";

function* getCourseHandler(params: {
    type: string;
    payload: CoursePayload;
}): any {
    try {
        yield put(toggleLoading(true));
        const { accessToken } = yield select((state) => state.user);

        const courseResponse: any = yield call(getCourse, params.payload, accessToken);
        if (courseResponse.errorCode) {
            if (courseResponse.errorCode === 401 || courseResponse.errorCode == 'InvalidToken') {
                yield put(refreshTokenRequest());
            }
            yield put(getCourseFailure(createCustomError(courseResponse.message)));
        }
        else {
            yield put(getCourseSuccess(courseResponse));
        }
    } catch (error: any) {
        throw new Error(error)
    }
}

function* getCoursesHandler(params: {
    type: string;
}): any {
    try {
        yield put(toggleLoading(true));
        const { accessToken } = yield select((state) => state.user);

        const coursesResponse: any = yield call(getCourses, accessToken);
        if (coursesResponse.errorCode) {
            if (coursesResponse.errorCode === 401 || coursesResponse.errorCode == 'InvalidToken') {
                yield put(refreshTokenRequest());
            }
            yield put(getCoursesFailure(createCustomError(coursesResponse.message)));
        }
        else {
            yield put(getCoursesSuccess(coursesResponse));

            const courseBannerResponse: any = yield call(getCourseBanners, accessToken);
            if (courseBannerResponse.errorCode) {
                yield put(getCourseBannerFailure(createCustomError(courseBannerResponse.message)));
            }
            else {
                yield put(getCourseBannerSuccess(courseBannerResponse));
            }
        }
    } catch (error: any) {
        throw new Error(error)
    }
}

function* publishCourseAnalyticsHandler(params: {
    type: string;
    payload: CourseAnalyticsPayload
}): any {
    try {
        yield put(toggleLoading(true));
        const { accessToken } = yield select((state) => state.user);

        const analyticsResponse = yield call(publishAnalytics, params.payload, accessToken);

        if (params.payload.chapter.lesson.isCompleted) {
            yield put(publishCourseAnalyticsSuccess(params.payload));
        }

    } catch (error: any) {
        throw new Error(error)
    }
}

function* createPaymentOrderHandler(params: {
    type: string;
    payload: OrderPayload
}): any {
    try {
        yield put(toggleLoading(true));
        const { accessToken } = yield select((state) => state.user);

        const createPaymentOrderResponse = yield call(createPaymentOrder, params.payload, accessToken);
        if (createPaymentOrderResponse.errorCode) {
            yield put(createPaymentOrderFailure(createCustomError(createPaymentOrderResponse.message)));
        }
        else {
            yield put(createPaymentOrderSuccess(createPaymentOrderResponse));
        }
    } catch (error: any) {
        throw new Error(error)
    }
}

function* createShipmentOrderHandler(params: {
    type: string;
    payload: ShipmentOrderPayload
}): any {
    try {
        yield put(toggleLoading(true));
        const { accessToken } = yield select((state) => state.user);

        const createShipmentOrderResponse = yield call(createShipmentOrder, params.payload, accessToken);
        if (createShipmentOrderResponse.errorCode) {
            yield put(createShipmentOrderFailure(createCustomError(createShipmentOrderResponse.message)));
        }
        else {
            yield put(createShipmentOrderSuccess(createShipmentOrderResponse));
        }
    } catch (error: any) {
        throw new Error(error);
    }
}

function* createCourseEnrollmentHandler(params: {
    type: string;
    payload: EnrollmentPayload
}): any {
    try {
        yield put(toggleLoading(true));
        const { accessToken } = yield select((state) => state.user);

        const createCourseEnrollmentResponse = yield call(createCourseEnrollment, params.payload, accessToken);
        if (createCourseEnrollmentResponse.errorCode) {
            yield put(createCourseEnrollmentFailure(createCustomError(createCourseEnrollmentResponse.message)));
        }
        else {
            yield put(createCourseEnrollmentSuccess(createCourseEnrollmentResponse));
            yield call(getCourseHandler, { type: getCourseRequest.type, payload: { courseId: params.payload.courseId } });
        }
    } catch (error: any) {
        throw new Error(error)
    }
}

function* recordEnrollmentFailureRequestHandler(params: {
    type: string;
    payload: PaymentFailurePayload
}): any {
    try {
        yield put(toggleLoading(true));
        const { accessToken } = yield select((state) => state.user);

        yield call(recordEnrollmentFailure, params.payload, accessToken);
    } catch (error: any) {
        throw new Error(error)
    }
}

function* getNextCoursesRequestHandler(params: {
    type: string;
}): any {
    try {
        const { accessToken } = yield select((state) => state.user);

        const nextCourseResponse = yield call(getNextCourses, accessToken);
        yield put(getNextCoursesSuccess(nextCourseResponse))
    } catch (error: any) {
        throw new Error(error)
    }
}

function* submitQuizHandler(params: {
    type: string;
    payload: SubmitQuizPayload
}): any {
    try {
        const { accessToken } = yield select((state) => state.user);

        const submitQuizResponse = yield call(submitQuiz, params.payload, accessToken);
        if (submitQuizResponse.errorCode) {
            yield put(submitQuizFailure(submitQuizResponse));
        } else {
            yield put(submitQuizSuccess(submitQuizResponse));
        }
    } catch (error: any) {
        throw new Error(error)
    }
}

function* getTasksHandler(params: {
    type: string;
    payload: GetTasksPaylod
}): any {
    try {
        const { accessToken } = yield select((state) => state.user);
        const tasksResponse = yield call(getTasks, params.payload, accessToken);
        if (tasksResponse.errorCode) {
            yield put(getTasksFailure(tasksResponse));
        } else {
            yield put(getTasksSuccess(tasksResponse));
        }
    } catch (error: any) {
        throw new Error(error)
    }
}

function* putTaskHandler(params: {
    type: string;
    payload: Task
}): any {
    try {
        const { accessToken } = yield select((state) => state.user);

        const putTaskResponse = yield call(putTask, params.payload, accessToken);
        if (putTaskResponse.errorCode) {
            yield put(putTaskFailure(putTaskResponse));
        } else {
            yield put(putTaskSuccess(putTaskResponse));
        }
    } catch (error: any) {
        throw new Error(error)
    }
}

function* uploadAssignmentRequestHandler(params: {
    type: string;
    payload: AssignmentPayload
}): any {
    try {
        const { accessToken } = yield select((state) => state.user);

        const uploadAssignmentResponse = yield call(uploadAssignments, params.payload, accessToken);
        if (uploadAssignmentResponse.errorCode) {
            yield put(uploadAssignmentFailure(uploadAssignmentResponse));
        } else {
            yield put(uploadAssignmentSuccess(uploadAssignmentResponse));
        }
    } catch (error: any) {
        throw new Error(error)
    }
}

function* updateCourseEnrollmentStatusHandler(params: {
    type: string;
    payload: EnrollmentStatusUpdatePayload
}): any {
    try {
        const { accessToken } = yield select((state) => state.user);

        const updateEnrollmentStatusRes = yield call(updateCourseEnrollmentStatus, params.payload, accessToken);
        if (updateEnrollmentStatusRes.errorCode) {
            yield put(updateCourseEnrollmentStatusFailure(updateEnrollmentStatusRes));
        } else {
            yield put(updateCourseEnrollmentStatusSuccess(updateEnrollmentStatusRes));
            params.payload.func();
        }
    } catch (error: any) {
        throw new Error(error)
    }
}


function* courseSaga() {
    yield takeEvery(getCourseRequest.type, getCourseHandler);
    yield takeEvery(getCoursesRequest.type, getCoursesHandler);
    yield takeEvery(publishCourseAnalyticsRequest.type, publishCourseAnalyticsHandler);
    yield takeEvery(createPaymentOrderRequest.type, createPaymentOrderHandler);
    yield takeEvery(createShipmentOrderRequest.type, createShipmentOrderHandler);
    yield takeEvery(createCourseEnrollmentRequest.type, createCourseEnrollmentHandler);
    yield takeEvery(recordEnrollmentFailureRequest.type, recordEnrollmentFailureRequestHandler);
    yield takeEvery(getNextCoursesRequest.type, getNextCoursesRequestHandler)
    yield takeEvery(submitQuizRequest.type, submitQuizHandler)
    yield takeEvery(getTasksRequest.type, getTasksHandler);
    yield takeEvery(putTaskRequest.type, putTaskHandler);
    yield takeEvery(uploadAssignmentRequest.type, uploadAssignmentRequestHandler)
    yield takeEvery(updateCourseEnrollmentStatusRequest.type, updateCourseEnrollmentStatusHandler)
}

export default [courseSaga];
