import { useEffect } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { getTasksRequest, putTaskRequest, Task } from "../../actions/courseActions";

interface TasksProps {
  courseId: number;
}

const TaskContainer: React.FC<TasksProps> = (props) => {

  const dispatch = useDispatch();
  
  const {tasks} = useSelector((state: AppState) => state.course);
  const taskStatus = {COMPLETED: "completed",PENDING: "pending"}
  
  useEffect(() => {
    dispatch(getTasksRequest({ courseId: props.courseId }));
  }, []);

  const updateTask = (task: Task) =>{
    const status = task.status == taskStatus.COMPLETED ? taskStatus.PENDING : taskStatus.COMPLETED;
    task = {...task, status: status};
    dispatch(putTaskRequest(task));
  }


  const taskItem = (task: Task, index: string) => {
    return (
      <div key={index}>
        <label className="task-label" htmlFor={`checkbox-${task.id}`}>
          <input
            className="task-check-box"
            type="checkbox"
            id={`checkbox-${task.id}`}
            checked={task.status == taskStatus.COMPLETED}
            onChange= {(e) => updateTask(task)}
          />
          <h6 className="label-text">{task.task}</h6>
        </label>
      </div>
    );
  };

  return (
    <div className="task-container">
      {tasks?.map((task, index) => (
        <div className="task-item" key={index}>
          {taskItem(task, index.toString())}
        </div>
      ))}
    </div>
  );
};

export default TaskContainer;
