import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";

import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import * as yup from 'yup';
import { Formik } from 'formik';

import ReactPlayerWrapper from "../components/ReactPlayerWrapper";
import { AppState } from "../store";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Badge from 'react-bootstrap/Badge';
import { BiCheck, BiX } from 'react-icons/bi';
import { GiOpenBook } from 'react-icons/gi';
import { HiClock } from 'react-icons/hi';
import { MdLock, MdOutlineOndemandVideo } from 'react-icons/md'
import { VscDebugRestart } from 'react-icons/vsc';
import Lottie from "lottie-react";
import greenTickAnimation from '../../public/scripts/greentick.json';
import './Common.scss';
import countryList from '../data/countries.json';
import stateList from '../data/states.json';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import useDeepCompareEffect from '../hooks/useDeepCompareEffect';
import { logOut } from '../actions/userActions';
import { getLastViewedRequest } from '../actions/analyticsAction';
import SpinnerElement from '../components/SpinnerElement';

import {
    Author,
    Chapter,
    Faq,
    getCourseRequest,
    Lesson,
    toggleModal,
    CourseAnalyticsPayload,
    publishCourseAnalyticsRequest,
    OrderPayload,
    createPaymentOrderRequest,
    resetPaymentOrder,
    createCourseEnrollmentRequest, recordEnrollmentFailureRequest, PaymentFailurePayload,
    Quiz,
    submitQuizRequest,
    resetQuizResponse,
    AssignmentPayload,
    uploadAssignmentRequest,
    uploadAssignmentReset,
    updateCourseEnrollmentStatusRequest,
    ShipmentOrderPayload,
    createShipmentOrderRequest,
    Question
} from "../actions/courseActions";
import "./Common.scss";
import { Accordion, Card, Button, Form, Modal, FormGroup, Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowRight, BsPlayCircleFill } from "react-icons/bs";
import VideoModal from "./VideoModal";
import { URL, getURL } from '../shared/url';
import './VideoModal.scss';
import { LessonId, ChapterId, ChapterName, LessonName } from '../types/common';
import { refreshTokenRequest, updateAddressRequest } from '../actions/userActions';
import useDeepCompareMemoize from '../hooks/useDeepCompareMemoize';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import useAnalyticsEventTracker, { gaEvents } from "../shared/analytics";

const defaultOrder: OrderPayload = {
    amount: 0,
    currency: '',
    receipt: '',
    notes: {
        notes_key_1: ''
    },
    courseId: 0,
    userName: ''
}

interface quizAnswer {
    [key: string]: string
}

interface ShipmentsData {
    length: number,
    breadth: number,
    height: number,
    weight: number
}

export interface Address {
    address1: string,
    address2: string,
    city: string,
    state: string,
    country: string,
    pincode: string
}

export const defaultAddress: Address = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    pincode: ''
}

interface CourseAnalytics extends CourseAnalyticsPayload {
    chapter: {
        id: ChapterId,
        name: ChapterName,
        lesson: {
            id: LessonId,
            name: LessonName,
            watchedDuration: number,
            isCompleted: boolean,
            totalDuration: number
        }
    }
}

const Courses: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [videoURL, setVideoURL] = useState('');
    const [videoPlayTime, setVideoPlayTime] = useState(0);
    const [playVideo, setPlayVideo] = useState(false);
    const [lessonVideo, setLessonVideo] = useState<string | null>(null);
    const [courseAnalytics, setCourseAnalytics] = useState<CourseAnalytics | null>(null);
    const [quiz, setQuiz] = useState<Quiz | null>(null);
    const [quizAnswers, setQuizAnswers] = useState<quizAnswer>({});
    const [signedAssignmentUrl, setSignedAssignmentUrl] = useState(null);
    const [mostRecent, setMostRecent] = useState<any>(null);
    const [expandedChapter, setExpandedChapter] = useState<AccordionEventKey>(null);
    const prevExpandedChapterRef = useRef<AccordionEventKey>(null);
    const [showLoader, setShowLoader] = useState(true);
    DOMPurify.setConfig({ ADD_ATTR: ['target'] }); 

    useEffect(() => {
        prevExpandedChapterRef.current = expandedChapter;
    }, [expandedChapter]);

    const [userAddress, setUserAddress] = useState<Address>(defaultAddress);
    const [reUpload, setReUpload] = useState<boolean>(false);

    const gaEventTracker = useAnalyticsEventTracker('click');
    const params = useParams();
    const courseId = Number(params.courseId)

    const {
        course,
        courseFailedToLoad,
        orderId,
        errorMessage,
        isOrderCreated,
        isOrderError,
        transactionId,
        submitQuizResponse,
        submitQuizError,
        isAssignmentUploaded,
        isAssignmentUploadError,
        courseProgress
    } = useSelector((state: AppState) => state.course);
    const {
        courses
    } = useSelector((state: AppState) => state.courses);
    const {
        isLoggedIn,
        accessToken,
        firstName,
        lastName,
        phoneNumber,
        email,
        address,
        isUpdateAddressError
    } = useSelector((state: AppState) => state.user);

    const courseDuration = useMemo(() => {
        if (course) {
            let durationInSecs = 0;
            course.chapters.forEach((chapter: Chapter) => {
                chapter.lessons.forEach((lesson: Lesson) => {
                    durationInSecs += lesson.videoDuration;
                });
            });
            const hours = Math.floor(durationInSecs / (60 * 60));
            const mins = Math.floor(durationInSecs % 3600 / 60);
            const secs = Math.floor(durationInSecs % 3600 % 60);

            return `${hours < 10 ? `0${hours}` : hours}h ${mins < 10 ? `0${mins}` : mins}m ${secs < 10 ? `0${secs}` : secs}s`;
        }
    }, [course.id]);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/');
        }

        window.onbeforeunload = function () {
            if (isLoggedIn) {
                dispatch(getCourseRequest({ courseId: courseId ?? 0 }));
            }
            dispatch(toggleModal(false));
        };

        return () => {
            window.onbeforeunload = null;
        };
    }, [isLoggedIn]);

    useEffect(() => {
        if(address != null) {
            var obj = JSON.parse(address);
            setUserAddress(obj);
        }
    }, [address]);

    useEffect(() => {
        if (isAssignmentUploaded) {
            dispatch(getCourseRequest({ courseId: courseId ?? '' }));
            handleTabClick('Experiments');
            dispatch(uploadAssignmentReset());
        }

    }, [isAssignmentUploaded])

    useEffect(() => {
        if (course.name != null) {
            document.title = course.name + ' - Bversity';
        }
        
        if (!!course.enrollment && course.id === Number(courseId)) {
            let chapter = course.chapters[0];
            let lesson = chapter.lessons[0];
            let lastWatchedTime = 0;
            const lastWatchedVideo = course.lastWatchedVideo;

            if (!!lastWatchedVideo?.lastWatchedTime && !!lastWatchedVideo?.chapterId
                && !!lastWatchedVideo?.lessonId) {
                chapter = course.chapters.find(c => c.id === lastWatchedVideo?.chapterId) as Chapter;
                lesson = chapter.lessons.find(l => l.id === lastWatchedVideo?.lessonId) as Lesson;
                lastWatchedTime = lastWatchedVideo?.lastWatchedTime as number;
            }
            handlePlayVideo(chapter, lesson, lastWatchedTime, false);
        } else {
            setLessonVideo(null);
        }
    }, [course.id, course.enrollment]);

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(getCourseRequest({ courseId: courseId ?? '' }));
        }

        if(accessToken) {
            const userData: any = jwtDecode(accessToken);
            const isExpired = dayjs.unix(userData.exp).diff(dayjs()) < 1;
    
            if(isExpired) {
              dispatch(refreshTokenRequest());
            }
        }

        return () => {
            dispatch(resetPaymentOrder());
        };
    }, []);

    useEffect(() => {
        if (courseFailedToLoad)
            navigate('/home');

    }, [courseFailedToLoad]);

    useEffect(() => {
        if (submitQuizResponse !== null) {
            const updatedQuiz = submitQuizResponse !== null && quiz !== null
                ? {
                    chapterOrExp: quiz.isExperimentQuiz 
                        ? course.chapters.find(chapter => chapter.id === quiz.chapterId)?.lessons.find(lesson => lesson.id === quiz.chapterOrExp.id) as Lesson
                        : course.chapters.find(chapter => chapter.id === quiz.chapterOrExp.id) as Chapter,
                    questions: submitQuizResponse.quizResponses,
                    isExperimentQuiz: quiz.isExperimentQuiz,
                    chapterId: quiz.chapterId
                } : null
            setQuiz(updatedQuiz);
        }
    }, [submitQuizResponse]);

    useDeepCompareEffect(() => {
        if (course.id) {
            const courseEnrollment = courses.find(c => c.id === course.id);
            const progress = Object.values(courseProgress);
            const isCourseCompleted = progress.length > 0 && progress.every(p => p === 100);
            if (courseEnrollment?.enrollmentStatus !== 'completed' && isCourseCompleted) {
                dispatch(updateCourseEnrollmentStatusRequest({ courseId: course.id as number, func: () => {
                    const downloadCertElem = document.getElementById('download-certificate');

                    if (downloadCertElem)
                        downloadCertElem.scrollIntoView();
                    } 
                }));
            }
        }
            
    }, [courseProgress, course]);

    const [toggleMobileMenu, setToggleMobileMenu] = useState<boolean>(false);

    const [showDescription, setShowDescription] = useState<boolean>(true);
    const [showAboutAuthor, setShowAboutAuthor] = useState<boolean>(false);
    const [showDiscussion, setShowDiscussion] = useState<boolean>(false);
    const [showExperiments, setShowExperiments] = useState<boolean>(false);
    const [isExperiment, setIsExperiment] = useState<boolean>(false);
    const [showQuiz, setShowQuiz] = useState<boolean>(false);

    const handleTabClick = function handleTabClick(category: string) {
        if (category === 'Description') {
            setShowDescription(true);
            setShowAboutAuthor(false);
            setShowExperiments(false);
            setShowDiscussion(false);
            setShowQuiz(false);
            setQuiz(null);
        } else if (category === 'Author') {
            setShowDescription(false);
            setShowAboutAuthor(true);
            setShowExperiments(false);
            setShowDiscussion(false);
            setShowQuiz(false);
            setQuiz(null);
        } else if (category === 'Discussion') {
            setShowDescription(false);
            setShowAboutAuthor(false);
            setShowExperiments(false);
            setShowDiscussion(true);
            setShowQuiz(false);
            setQuiz(null);
        } else if (category === 'Quiz') {
            setShowDescription(false);
            setShowAboutAuthor(false);
            setShowExperiments(false);
            setShowDiscussion(false);
            setShowQuiz(true);
        }
        else if (category === 'Experiments') {
            setShowDescription(false);
            setShowAboutAuthor(false);
            setShowExperiments(true);
            setShowDiscussion(false);
            setShowQuiz(false);
            setQuiz(null);
        }
    }

    const handleTakeQuiz = (chapterOrExp: Chapter | Lesson, isTryAgain: boolean, isExperimentQuiz?: boolean, chapterId?: number) => {
        if (quiz?.chapterOrExp.id !== chapterOrExp.id || isTryAgain) {
            dispatch(resetQuizResponse());
            setQuizAnswers({});
            const questionsPerAttempt = chapterOrExp.noOfQuestions;
            const quizMaxRetry = chapterOrExp.quizMaxRetry;

            const unAttemptedQuestions = chapterOrExp.questions.filter(q => q.answers.length === 0);
            const attemptedQuestions = chapterOrExp.questions
                .filter(q => q.answers.length !== 0)
                .sort((a, b) => new Date(a.answers[0]?.createdAt).getTime() - new Date(b.answers[0]?.createdAt).getTime());
        
            const quizAttemptCount = attemptedQuestions.length / questionsPerAttempt;

            let questions: Question[] = [];
            if (attemptedQuestions.length === 0 || isTryAgain) {
                questions = unAttemptedQuestions.slice(0, questionsPerAttempt);
            }
            else {
                questions = attemptedQuestions.slice(attemptedQuestions.length - questionsPerAttempt, attemptedQuestions.length);
                const answers: quizAnswer = {}
                questions.forEach(q => answers[String(q.id)] = String(q.answers[0].choice.id));
                setQuizAnswers(answers);
            }

            setQuiz({
                chapterOrExp,
                questions,
                isExperimentQuiz,
                chapterId
            });
            handleTabClick('Quiz');
        }
        const courseTabsElem = document.querySelector('.course-tabs');

        if (courseTabsElem)
            courseTabsElem.scrollIntoView();
    }

    const authorItem = (author: Author, index: number) => {
        return (
            <div className="author-contentainer">
                <div className="author-content-dp">
                    <img className='profilePicture' src={author.profilePicture} alt={`${author.name}`} />
                </div>
                <div className="author-content-text">
                    <h5>{author.name}</h5>
                    <h6 className='text-secondary'>{author.institution}</h6>
                    <div className="mar-top-1rem">{parse(DOMPurify.sanitize(author.description))}</div>
                </div>
            </div>
        );
    }

    const checkExperiment = (chapter: Chapter) => {
        if (chapter.lessons.filter(x => x.lessonType == 'experiment').length > 0)
            return true;

        return false;
    }

    const faqItem = (faq: Faq, index: string) => {
        return (
            <Accordion.Item eventKey={index} className="faq-item" >
                <Accordion.Header>{parse(DOMPurify.sanitize(faq.question ?? ''))}</Accordion.Header>
                <Accordion.Body>{parse(DOMPurify.sanitize(faq.answer ?? ''))}</Accordion.Body>
            </Accordion.Item>
        );
    }

    const chapterItem = (chapter: Chapter, index: number) => {
        const chapterId = chapter.id as number;
        const chapterProgress = courseProgress[chapterId] || 0;
        /* return (
            <div>
                <div className="chapter-widget">
                    <div className="chapter-widget-count">{index + 1}.</div>
                    <div className="chapter-widget-content">
                        <h1>{chapter.name} {checkExperiment(chapter) && <span className="experiments-chapter-tag">&nbsp;with experiments&nbsp;</span>}</h1>
                    </div>
                </div>
                <div className="units-list-container mar-top-1rem mar-btm-2rem">
                    {chapter.lessons?.map((lesson, index) =>
                        <div key={index}>{lessonItem(chapter, lesson, index)}</div>)}
                </div>
                {
                    chapter.isQuizEnabled && !!course.enrollment && (
                        <div className='col-md-12 text-center mb-2'>
                            <Button variant="outline-dark" onClick={handleTakeQuiz.bind(this, chapter, false)}>Take Quiz &rarr;</Button>
                        </div>
                    )
                }
            </div>
        ); */
        let isChapterCollapse = expandedChapter !== `${chapterId}`;
        
        if (expandedChapter === null && prevExpandedChapterRef.current === null) {
            isChapterCollapse = courseAnalytics?.chapter.id.toString() !== `${chapterId}`;
            if(!isChapterCollapse) {
                setExpandedChapter(`${chapterId}`);
            }
        }
        const mins = Math.floor(chapter.totalDuration / 60);
        const secs = Math.floor(chapter.totalDuration % 3600 % 60);
        const lastLesson = chapter.lessons[chapter.lessons.length - 1];
        
        return (
            <Accordion.Item eventKey={`${chapterId}`} key={chapterId}>
                <Accordion.Header className={isChapterCollapse ? 'chapter-collapse' : ''}>
                   <div>{index + 1}.</div>
                    <div className='mx-1'>{chapter.name}</div>
                    {isChapterCollapse && (
                        <div className='chapter-info'>
                            <MdOutlineOndemandVideo size={16} color='#03C1ED' /> 
                            <span>&nbsp;{chapter.lessons.length} videos</span>
                            <HiClock className='ms-3' size={16} color='#03C1ED' /> 
                            <span>&nbsp;{mins} min {secs} sec</span>
                        </div>
                    )}
                </Accordion.Header>
                <Accordion.Body>
                    {
                        course.enrollment && (
                            <React.Fragment>
                                <div className='chapter-progress'>
                                    <div className='completed-percent' style={{ width: `${chapterProgress}%` }}></div>
                                </div>
                                <div className='chapter-progress-text'>{chapterProgress}% completed</div>
                            </React.Fragment>
                        )
                    }
                    <div className="units-list-container mar-top-1rem mar-btm-2rem">
                        {chapter.lessons?.map((lesson, index) =>
                            <div key={index}>{lessonItem(chapter, lesson, index)}</div>)}
                    </div>
                    {
                        chapter.isQuizEnabled && !!course.enrollment && (
                            <div className='col-md-12 text-center mb-2'>
                                <Button 
                                    variant="outline-dark" 
                                    onClick={handleTakeQuiz.bind(this, chapter, false, false, undefined)}
                                    disabled={lastLesson.courseAnalytics.length === 0 || !lastLesson.courseAnalytics[0].isCompleted 
                                        || (lastLesson.lessonType === 'experiment' && course.assignments.filter(x => x.lesson.id === lastLesson.id).length === 0)}
                                >
                                    Take Quiz &rarr;
                                </Button>
                            </div>
                        )
                    }
                </Accordion.Body>
            </Accordion.Item>
        )
    }

    const setCourseAnalyticsPayload = (chapter: Chapter, lesson: Lesson) => {
        setCourseAnalytics({
            id: course.id as number,
            enrollmentId: course.enrollment as number,
            name: course.name as string,
            timestamp: new Date(),
            chapter: {
                id: chapter.id as number,
                name: chapter.name as string,
                lesson: {
                    id: lesson.id,
                    name: lesson.name,
                    watchedDuration: 0,
                    isCompleted: lesson.courseAnalytics.length > 0 && lesson.courseAnalytics[0].isCompleted,
                    totalDuration: lesson.videoDuration
                }
            }
        })
    }

    const getSignedUrl = async (lessonId: LessonId, playInModal: boolean = false) => {
        try {
            const response = await fetch(`${URL.getLessonVideoUrl}/${lessonId}?type=mpd`,
                { method: 'GET', headers: { Authorization: `Bearer ${accessToken}` } });

            response.json().then(signedUrlResponse => {
                if (playInModal) {
                    dispatch(toggleModal(true));
                    setVideoURL(signedUrlResponse?.url);
                } else {
                    setLessonVideo(signedUrlResponse?.url);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }
            });
        } catch (e: any) {
            console.error(e.message);
        }
    }

    const handlePlayVideo = (chapter: Chapter, lesson: Lesson, lastWatchedTime: number, playVideo: boolean = true, playInModal: boolean = false) => {
        setMostRecent({ chapter: chapter, lesson: lesson });

        if (lesson.lessonType === 'experiment') {
            setIsExperiment(true)
            handleTabClick('Experiments');
        }
        else {
            handleTabClick('Description');
            setIsExperiment(false)
        }
        setCourseAnalyticsPayload(chapter, lesson);
        getSignedUrl(lesson.id, playInModal);

        if (!playInModal) {
            setVideoPlayTime(lastWatchedTime);
            setPlayVideo(playVideo);
        }
    }

    const lessonItem = (chapter: Chapter, lesson: Lesson, index: number) => {
        let isLessonLocked = false;
        const currentLessonIndex = chapter.lessons.findIndex(l => l.id === lesson.id);
        let prevLesson: Lesson | null = null;
        let prevChapter: Chapter | null = null;

        if (currentLessonIndex === 0) {
            const currentChapterIndex = course.chapters.findIndex(c => c.id === chapter.id);

            if (currentChapterIndex > 0) {
                prevChapter = course.chapters[currentChapterIndex - 1];
                prevLesson = prevChapter.lessons[prevChapter.lessons.length - 1];
            }
        } else {
            prevLesson = chapter.lessons[currentLessonIndex - 1];
        }

        if (prevChapter) {
            isLessonLocked = prevChapter.isQuizEnabled && prevChapter.questions.every(q => q.answers.length === 0);
        } 
        
        if (!isLessonLocked && prevLesson) {
            isLessonLocked = prevLesson.courseAnalytics.length <= 0 || !prevLesson.courseAnalytics[0].isCompleted 
                || (prevLesson.isQuizEnabled && prevLesson.questions.every(q => q.answers.length === 0)) 
                || (prevLesson.lessonType === 'experiment' && course.assignments.filter(x => x.lesson.id === prevLesson?.id).length === 0);
        }

        const isLessonPlayable = (lesson.isPreview || !!course.enrollment) && !isLessonLocked;

        return (
            <div
                className={`units-list-item ${isLessonPlayable ? 'pointer-cursor' : 'not-allowed-cursor'} ${(chapter.id === courseAnalytics?.chapter.id && lesson.id === courseAnalytics.chapter.lesson.id) ? 'is-active' : ''}`}
                onClick={() => isLessonPlayable && handlePlayVideo(chapter, lesson, 0, true, !course.enrollment && lesson.isPreview)}
            >
                <div className={`units-list-item-thumb ${lesson.lessonType === 'experiment' ? 'experiment' : ''}`}>
                    <img src={lesson.thumbnail} />
                    {!isLessonPlayable && <div className="units-item-lock-no-enroll">
                        <img src="/images/icons/lockFFF@24.png" />
                        <div>{!course.enrollment ? 'Subscribe now' : 'Watch previous videos to unlock'}</div>
                    </div>}
                    {lesson.courseAnalytics.length > 0 && lesson.courseAnalytics[0].isCompleted && (
                        <VscDebugRestart className='lesson-restart-icon' color='#FFFFFF' size={16}/>
                    )}
                </div>
                {lesson.lessonType === 'experiment' && <div className="units-list-item-tag">Experiment</div>}
                <div className="units-list-item-text">{lesson.name}</div>
            </div>
        );
    }

    useEffect(() => {
        if (isOrderCreated && !course.enrollment) {
            let options = {
                key: `${process.env.RAZORPAY_KEY_ID}`,
                amount: (course.price ?? 0) * 100,
                currency: course.currency,
                name: `Course Purchase`,
                description: `Purchasing ${course.name}`,
                order_id: `${orderId}`,
                handler: function (response: any) {
                    let paymentResponse = {
                        paymentId: response.razorpay_payment_id,
                        orderId: response.razorpay_order_id,
                        paymentSignature: response.razorpay_signature,
                        transactionId: `${transactionId}`,
                        courseId: course.id ?? 0,
                        userName: firstName + ' ' + lastName
                    }
                    notifySuccess("Course purchased");
                    if(course.isShipping) {
                        createShipmentOrder(paymentResponse.orderId, course.shipmentData ?? '');
                    }
                    dispatch(createCourseEnrollmentRequest(paymentResponse))
                },
                modal: {
                    ondismiss: () => {
                        dispatch(resetPaymentOrder());
                    }
                },
                prefill: {
                    name: `${firstName} ${lastName}`,
                    email: email || '',
                    contact: `${phoneNumber}`,
                },
                notes: {
                    notes_key_1: `Purchasing ${course.id} - ${course.name} for ${(course.price ?? 0)} INR at ${new Date()}.`
                },
                theme: {
                    color: "#16C1AD"
                }
            };
            // @ts-ignore
            const payPortal = new window.Razorpay(options);

            payPortal.on('payment.failed', function (response: any) {
                let error: PaymentFailurePayload = {
                    errorCode: response.error.code,
                    errorDescription: response.error.description,
                    errorSource: response.error.source,
                    errorStep: response.error.step,
                    errorReason: response.error.reason,
                    errorMetadataOrderId: response.error.metadata.order_id,
                    errorMetadataPaymentId: response.error.metadata.payment_id,
                    orderId: `${orderId}`
                }
                // notifyFailure(response.error.code);
                notifyFailure(response.error.description);
                // notifyFailure(response.error.source);
                // notifyFailure(response.error.step);
                // notifyFailure(response.error.reason);
                // notifyFailure(response.error.metadata.order_id);
                // notifyFailure(response.error.metadata.payment_id);
                dispatch(recordEnrollmentFailureRequest(error));
                dispatch(resetPaymentOrder());
            });

            payPortal.open();
        }
        if (isOrderError) {
            notifyFailure(errorMessage ?? 'Purchase failed');
            dispatch(resetPaymentOrder());
        }
    }, [isOrderCreated, isOrderError]);

    const notifySuccess = (content: string) => toast.success(content, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        theme: "light",
        pauseOnHover: true,
        closeOnClick: true,
    });

    const notifyFailure = (content: string) => toast.error(content, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        theme: "light",
        pauseOnHover: true,
        closeOnClick: true,
    });

    const handlePayment = () => {
        console.log("RazorPay Called");
        let orderPayload: OrderPayload = {
            amount: (course.price ?? 0) * 100,
            currency: course.currency as string,
            receipt: `${course.name} by ${firstName}` as string,
            notes: {
                notes_key_1: `Purchasing ${course.id} - ${course.name} for ${(course.price ?? 0)} INR at ${new Date()}.` as string,
            },
            courseId: course.id as number,
            userName: firstName + ' ' + lastName
        }

        dispatch(createPaymentOrderRequest(orderPayload));
    }

    const createShipmentOrder = (orderId: string, shipmentData: string) => {
        console.log("Shipment Order called");
        var shipmentsData: ShipmentsData = JSON.parse(shipmentData);
        let shipmentPayload: ShipmentOrderPayload = {
            price: (course.price ?? 0) * 100,
            userName: firstName ?? '',
            lastName: lastName ?? '',
            userEmail: email ?? '',
            userPhone: phoneNumber ?? '',
            shippingAddress: address ?? '',
            enrollmentId: course.enrollment ?? 0,
            courseId: courseId,
            orderId: orderId,
            purchaseComment: `${course.name} by ${firstName}` as string,
            length: shipmentsData.length,
            breadth: shipmentsData.breadth,
            height: shipmentsData.height,
            weight: shipmentsData.weight
        }

        dispatch(createShipmentOrderRequest(shipmentPayload));
    }

    const handleClose = () => {
        setShow(false);
    };

    const handleAddress = () => {
        if (courses.some(c => c.enrollmentStatus === 'ongoing')) {
            toast.error('Can\'t purchase new course without completing previous course');
            return;
        }
        else {
            if (course.isShipping) {
                setShow(true);
            }
            else {
                handlePayment();
            }
        }
    }

    const handleAddressSubmit = (e: any) => {
        dispatch(updateAddressRequest({ address: JSON.stringify(e)}));

        if(!isUpdateAddressError) {
            setShow(false);
            handlePayment();
        }
    }

    const bannerImageStyle = {
        backgroundImage: "url(" + course.coverPhoto + ")"
    };

    function isEmpty(obj: any) {
        return Object.keys(obj).length === 0;
    }

    const experimentItem = () => {
        if (courseAnalytics != null) {
            try {
                const description = course.chapters.filter(x => x.id == courseAnalytics?.chapter.id ?? 0)[0].lessons.filter(y => y.id == courseAnalytics?.chapter.lesson.id ?? 0)[0].description

                return <div className='experiment-description'>{parse(DOMPurify.sanitize(description))}</div>
            }
            catch {
                return (<div></div>)
            }
        }
        return (<div></div>)
    }

    const renderPlayNext = () => {
        const nextChapterAndLesson = getNextChapterAndLesson();
        return nextChapterAndLesson && (
            <Button variant="outline-dark" onClick={() => {
                if (nextChapterAndLesson.currentChapter.id !== nextChapterAndLesson.chapter.id 
                    && nextChapterAndLesson.currentChapter.isQuizEnabled && nextChapterAndLesson.currentChapter.questions.every(q => q.answers.length === 0)) {
                    handleTakeQuiz(nextChapterAndLesson.currentChapter, false, false, undefined);
                } else {
                    setExpandedChapter(`${nextChapterAndLesson.chapter.id}`);
                    handlePlayVideo(nextChapterAndLesson.chapter, nextChapterAndLesson.lesson, 0, true, false);
                }
            }}>Play Next &rarr;</Button>
        )
    }

    const assignmentItem = () => {
        let assignment = course.assignments.filter(x => x.lesson.id === courseAnalytics?.chapter.lesson.id &&
            x.chapter.id === courseAnalytics.chapter.id);

        let totalScore = course.chapters.filter(x => x.id === courseAnalytics?.chapter.id)[0]
                               .lessons.filter(x => x.id === courseAnalytics?.chapter.lesson.id)[0].assignmentScore;

        if (assignment.length > 0 && !reUpload) {
            let filteredAssignment = assignment.filter(x => x.status === 'active')?.sort((a,b) => {
                return new Date(a.createdAt).getTime() - 
                    new Date(b.createdAt).getTime()
            }).reverse();

            let assignmentScore = filteredAssignment && filteredAssignment.length > 0 ? filteredAssignment[0].assignmentScore: null;

            const chapterId = courseAnalytics?.chapter.id ?? 0;
            const lessonId = courseAnalytics?.chapter.lesson.id ?? 0;

            const chapter = course.chapters.find(chapter => chapter.id === chapterId);
            const lesson = chapter?.lessons.find(lesson => lesson.id === lessonId);
            let finalScore: string | number = '-';

            if(assignmentScore != null)
                finalScore = Math.round((assignmentScore / totalScore) * 100);

            return (<Card className="assignment-card">
                <div style={{ textAlign: 'center' }}>
                    <h5 className="assignment-header">Your assignment has been uploaded successfully</h5>
                    <Row>
                        <Col xs={6} className="mb-3">
                            <Lottie animationData={greenTickAnimation} loop={true} style={{ width: '20%', marginLeft: 'auto', marginRight: 'auto' }} />
                        </Col>
                        <Col xs={6} className="mb-3" style={{alignSelf: 'center'}}>
                            <div className="label-value"><h5 className="label">Score</h5><h6>{finalScore}/100</h6></div>
                        </Col>
                    </Row>
                    <div className='col-md-12 text-center mb-2'>
                    {
                        (courses.find(c => c.id === course.id)?.enrollmentStatus !== 'completed') &&
                        <Button variant="outline-dark" className="course-assignment-button" onClick={() => {setReUpload(true)}}>Re-upload &rarr;</Button>
                    }
                    {
                        lesson?.isQuizEnabled ? (
                            <Button variant="outline-dark" onClick={handleTakeQuiz.bind(this, lesson, false, true, chapter?.id as number)}>Take Quiz &rarr;</Button>
                        ) : renderPlayNext()
                    }
                    </div>
                </div></Card>)
        }
        else {
            return (<Card className="experiment-card" style={{ textAlign: 'center' }}><div>
                <h5 className="assignment-header">Submit your assignments here</h5>
                <Form.Control
                    type="file"
                    className="form-control-assignment"
                    name="assignmentUpload"
                    accept="*"
                    onChange={handleChange}
                />
                <Button className='assignment-upload-button' onClick={handleSubmit}>Upload</Button>
            </div></Card>)
        }
    }
    const handleAnswerChange = (e: any) => {
        setQuizAnswers({
            ...quizAnswers,
            [e.target.name]: e.target.id
        })
    }

    const renderQuiz = () => {
        if (!course.enrollment) {
            return (
                <div className='text-center'>
                    <img height={300} width={350} src="/images/subscription-bg.png" />
                    <h6>Subscribe to view and use quiz!</h6>
                </div>
            )
        }
        
        if (quiz !== null) {

            if (quiz.questions.length === 0)
                return (
                    <div className='col-md-12 text-center h5'>
                        No questions available
                    </div>
                )

            let finalScore: string | number = '-';

            if (quiz.questions[0].answers.length > 0) {
                let score = 0;
                let totalScore = 0;

                quiz.questions.forEach(q => {
                    score += q.answers[0].choice.isCorrect ? q.score : 0;
                    totalScore += q.score;
                })

                finalScore = Math.round((score / totalScore) * 100);
            }

            const quizMaxRetry = quiz.chapterOrExp.quizMaxRetry;
            const answeredQuestions = quiz.chapterOrExp.questions.filter(q => q.answers.length > 0).length;
            const questionsPerAttempt = quiz.chapterOrExp.noOfQuestions;
            const quizAttemptCount = answeredQuestions / questionsPerAttempt;

            return (
                <Form className='quiz-form' 
                    onSubmit={e => {
                    e.preventDefault();
                    dispatch(submitQuizRequest({
                        chapterId: (quiz.isExperimentQuiz ? quiz.chapterId : quiz.chapterOrExp.id) as number,
                        answers: Object.keys(quizAnswers).map(questionId => ({
                            questionId: Number(questionId),
                            choiceId: Number(quizAnswers[questionId])
                        })),
                        experimentId: quiz.isExperimentQuiz ? quiz.chapterOrExp.id as number : null
                    }));
                }}>
                    {
                        quizAttemptCount === quizMaxRetry && (
                            <div className='warning-box pos-abs h5 auto-hide'>
                                Reached max retry attempt
                            </div>
                        )
                    }
                    <div className='col-md-12 d-flex'>
                        <div className="label-value"><h5 className="label">Score</h5><h6>{finalScore}/100</h6></div>
                        <div className="label-value"><h5 className="label">Attempt</h5><h6>{quizAttemptCount}/{quizMaxRetry}</h6></div>
                    </div>
                    <ol>
                        {quiz.questions.map(q => (
                            <>
                                <li className='mt-4' key={q.id}>
                                    {q.text}
                                    {q.answers.length > 0 && (q.answers[0].choice.isCorrect
                                        ? (<BiCheck size={24} color='green' />)
                                        : (<BiX size={24} color='red' />))}
                                </li>
                                {q.choices.map(c => (
                                    <Form.Check
                                        checked={quizAnswers === null ? false : quizAnswers[q.id] === `${c.id}`}
                                        type={'radio'}
                                        id={`${c.id}`}
                                        label={c.text}
                                        name={`${q.id}`}
                                        onChange={handleAnswerChange}
                                    />
                                ))}
                            </>
                        ))}
                    </ol>
                    <Row className='text-center mx-2'>
                        <Col>
                            <Button type='submit' variant="info" className='text-white'
                                disabled={Object.keys(quizAnswers).length !== quiz.questions.length || quizAttemptCount === quizMaxRetry || submitQuizResponse !== null || quiz.questions.filter(q => q.answers.length).length > 0}>
                                Submit
                            </Button>
                        </Col>
                        {
                            quizAttemptCount > 0 && quizAttemptCount !== quizMaxRetry && (
                                <Col>
                                    <Button variant="info" className='text-white mx-1' disabled={quiz.questions.filter(q => q.answers.length).length == 0} onClick={handleTakeQuiz.bind(this, quiz.chapterOrExp, true, quiz.isExperimentQuiz, quiz.chapterId)}>
                                        Try Again
                                    </Button>
                                </Col>
                            )
                        }
                        {
                            quizAttemptCount > 0 && (<Col>
                                <div className='col-md-12 text-center mb-2'>
                                    {renderPlayNext()}
                                </div>
                            </Col>)
                        }
                    </Row>
                </Form>
            )
        } else if (submitQuizError?.errorCode) {
            return (
                <div className='col-md-12 text-center text-danger h5'>
                    {submitQuizError.message}
                </div>
            )
        } else {
            return (
                <div className='col-md-12 text-center h5'>
                    Click take quiz after watching all lessons in chapter
                </div>
            )
        }
    }

    const renderExperiment = () => {
        if (!course.enrollment) {
            return (
                <div className='text-center'>
                    <img height={300} width={350} src="/images/subscription-bg.png" />
                    <h6>Subscribe to view and use experiments!</h6>
                </div>
            )
        }

        return (
            <React.Fragment>
                <h3>Instructions</h3>
                <Card className="experiment-card">
                    {experimentItem()}
                </Card>
                {assignmentItem()}
            </React.Fragment>
        )
    }

    const handleChange = async (data: any) => {
        const files = data.target.files;
        if (files.length > 0) {
            const file = files[0];
            const [fileName, ext] = file.name.split('.');
            const res = await fetch(URL.uploadAssignmentFiles, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    fileName: `${fileName}_${new Date().getTime()}.${ext}`,
                    fileType: `application/octet-stream`,
                })
            })

            res.json().then(async ({ signedUrl, objectUrl }) => {
                try {
                    setSignedAssignmentUrl(objectUrl);
                    await fetch(signedUrl, {
                        method: 'PUT',
                        body: file,
                        headers: {
                            "Content-Type": `${file.type}`
                        }
                    });
                }
                catch (e) {
                    setSignedAssignmentUrl(null);
                    toast.error((e as Error).message);
                }
            })
        }
    }

    const handleSubmit = () => {
        if (signedAssignmentUrl) {
            let payload: AssignmentPayload = {
                assignmentUrl: signedAssignmentUrl,
                chapterId: courseAnalytics?.chapter.id ?? 0,
                lessonId: courseAnalytics?.chapter.lesson.id ?? 0,
                enrollmentId: courseAnalytics?.enrollmentId ?? 0
            }
            dispatch(uploadAssignmentRequest(payload));
            setReUpload(false);
        }
    }

    const schema = yup.object().shape({
        address1: yup.string().required(),
        address2: yup.string(),
        city: yup.string().required(),
        state: yup.string().required(),
        pincode: yup.string().required(),
        country: yup.string().required()
	});

    const handleAnalytics = ({ playedSeconds, isCompleted, isVideoEnd } : { playedSeconds?: number, isCompleted: boolean, isVideoEnd: boolean }) => {
        let analytics = {};
        if (courseAnalytics?.chapter?.lesson) {
            analytics = {
                ...courseAnalytics,
                timestamp: new Date(),
                chapter: {
                    ...courseAnalytics.chapter,
                    lesson: {
                        id: courseAnalytics.chapter.lesson.id,
                        name: courseAnalytics.chapter.lesson.name,
                        watchedDuration: isCompleted ? courseAnalytics.chapter.lesson.totalDuration : playedSeconds,
                        isCompleted
                    }
                }
            }

            if (isVideoEnd) {
                const currentChapterIndex = course.chapters.findIndex(chapter => chapter.id === courseAnalytics.chapter.id)
                const currentChapter = course.chapters[currentChapterIndex];
                const currentLessonIndex = currentChapter?.lessons.findIndex(lesson => lesson.id === courseAnalytics.chapter.lesson.id);
                const currentLesson = currentChapter.lessons[currentLessonIndex];

                if (currentLesson?.lessonType === 'experiment') {
                    const courseTabsElem = document.querySelector('.course-tabs');
                    courseTabsElem && courseTabsElem.scrollIntoView();
                } else if (currentChapter.lessons.length - 1 === currentLessonIndex && currentChapter.isQuizEnabled) {
                    handleTakeQuiz(currentChapter, false, false, undefined);
                } else {
                    const nextChapterAndLesson = getNextChapterAndLesson();
                    if (nextChapterAndLesson) {
                        setExpandedChapter(`${nextChapterAndLesson.chapter.id}`);
                        handlePlayVideo(nextChapterAndLesson.chapter, nextChapterAndLesson.lesson, 0, true, false);
                    }
                }
            }
        }

        dispatch(publishCourseAnalyticsRequest(analytics as CourseAnalyticsPayload));
    }

    const getNextChapterAndLesson = () : { currentChapter: Chapter, currentLesson: Lesson, chapter: Chapter, lesson: Lesson } | null => {
        if (courseAnalytics?.chapter?.lesson) { 
            const currentChapterIndex = course.chapters.findIndex(chapter => chapter.id === courseAnalytics.chapter.id);
            const currentChapter = course.chapters[currentChapterIndex];
            const currentLessonIndex = currentChapter.lessons.findIndex(lesson => lesson.id === courseAnalytics.chapter.lesson.id);
            const currentLesson = currentChapter.lessons[currentLessonIndex];

            if (currentChapter.lessons.length !== currentLessonIndex + 1) {
                return { currentChapter, currentLesson, chapter: currentChapter, lesson: currentChapter.lessons[currentLessonIndex + 1]};
            } else if (course.chapters.length !== currentChapterIndex + 1) {
                const nextChapter = course.chapters[currentChapterIndex + 1];
                return { currentChapter, currentLesson, chapter: nextChapter, lesson: nextChapter.lessons[0] }
            }
        }

        return null
    }

    const downloadCertificate = async () => {
        try {
            toast.info('Preparing certificate');
            const response = await fetch(getURL.downloadCertificate(course.id),
                { method: 'GET', headers: { Authorization: `Bearer ${accessToken}` } });

            response.blob().then(file => {
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(file);
                a.target = '_blank';
                a.click();
                a.remove();
            });
        } catch (e: any) {
            console.error(e.message);
        }
    }

    const [showLogoutModel, setShowLogoutModel] = useState<boolean>(false);

    const handleLogOut = () => {
        dispatch(logOut());
        navigate("/");
    }

    const { user, analytics } = useSelector((state: AppState) => state);

    const handleLanding = () => {
        dispatch(getLastViewedRequest());
        if (analytics.lastViewed == null || Object.keys(analytics.lastViewed).length === 0) {
            navigate('/home');
          }else{
            navigate('/dashboard');
          }
    }

    useEffect(() => {
        setTimeout(() => {
            setShowLoader(false)
        }, 2000)
    }, []);

    return (
        <>
        <section className="web-section app-section mar-top-no">
            <VideoModal isOpen={isOpen} videoURL={videoURL} />
            <ToastContainer />
            <div className="display-flex sm-display-flex just-flex-start align-flex-start">
                <div className={"app-nav-bar " + (toggleMobileMenu ? "toggleActive" : "")} id="app-nav-bar">
                    <div id="menu-bar" className={toggleMobileMenu ? "change" : ""} onClick={() => {
                        setToggleMobileMenu(!toggleMobileMenu)
                    }}>
                        <div id="bar1" className="bar"></div>
                        <div id="bar2" className="bar"></div>
                        <div id="bar3" className="bar"></div>
                    </div>
                    <nav>
                        {!toggleMobileMenu && (<a onClick={() => handleLanding()} title='Dashboard' className="b-logo"><span></span></a>)}
                        <a className="home-icon" title='Home' onClick={() => handleLanding()}><span>Home</span></a>
                        {/* <a href="javascript:void(0)" className="folder-icon"><span>Projects</span></a> */}
                        <a className="chart-icon" title='Courses' onClick={() => navigate("/allcourses")}><span>Courses</span></a>
                        <a className="group-icon" title='Community' onClick={() => navigate("/community")}><span>Community</span></a>
                        <a className="logout-icon" title='Logout' onClick={() => setShowLogoutModel(true)}><span>Logout</span></a>
                    </nav>
                </div>

                <div className="app-container router-content">
                    <div id="view-course">
                        <div className="course-view-container">

                            {lessonVideo === null ? (<div className="course-view-banner" style={bannerImageStyle}>
                                <div className="course-view-banner-content">
                                    <h1>{course.name}</h1>
                                    <div className='d-flex text-light text-sm'>
                                        <div><GiOpenBook size={16} color='#106D5B' /> {course.chapters.length} Chapters</div>
                                        <div className='mx-3'><HiClock size={16} color='#106D5B' /> {courseDuration}</div>
                                    </div>
                                    {/* <img src="/images/whatsHappening/john-schnobrich-2FPjlAyMQTA-unsplash.jpg" alt="Trailer" width="85%" /> */}
                                    <div className="course-view-banner-btn-content mar-top-2rem">
                                        <button className="bio-btn dark-btn login-btn bio-sm-btn" onClick={() => {
                                            gaEventTracker(gaEvents.WATCH_GLIMPSE, course.id);
                                            dispatch(toggleModal(true));
                                            setVideoURL(course.glimpse ?? '')
                                        }}><BsPlayCircleFill size="20" />&nbsp;&nbsp;Watch glimpse</button>
                                        <button className="bio-btn dark-btn bio-sm-btn active" onClick={() => {
                                            gaEventTracker(gaEvents.WATCH_TRAILER, course.id);
                                            dispatch(toggleModal(true));
                                            setVideoURL(course.trailer ?? '')
                                        }}><BsPlayCircleFill size="20" />&nbsp;&nbsp;Watch trailer</button>
                                    </div>
                                    <div className="course-view-banner-btn-content just-cent-imp mar-top-2rem">
                                        <a href="javascript:void(0)" onClick={() => handleAddress()} className="bio-btn success-btn-2 bio-sm-btn">Get started&nbsp;&nbsp;<BsArrowRight size="20" /></a>
                                    </div>
                                </div>
                            </div>) : (
                                <ReactPlayerWrapper
                                    playing={playVideo}
                                    height="550px"
                                    url={lessonVideo}
                                    played={videoPlayTime}
                                    onProgress={({ playedSeconds }: { playedSeconds: number }) => {
                                        handleAnalytics({ 
                                            playedSeconds, 
                                            isCompleted: Math.ceil(playedSeconds) >= (courseAnalytics?.chapter?.lesson?.totalDuration || 0) 
                                                || !!courseAnalytics?.chapter.lesson.isCompleted,
                                            isVideoEnd: false
                                        });
                                    }}
                                    onEnded={() => {
                                        handleAnalytics({ isCompleted: true, isVideoEnd: true });
                                    }}
                                />
                            )}

                            <div className="display-flex md-display-flex-reverse just-flex-start align-flex-start">
                                <div className="course-tab-container flex-grow-1">
                                    <Modal className="address-modal" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{'Shipment Details'}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="pb-0">
                                            <Formik
                                                enableReinitialize
                                                validationSchema={schema}
                                                onSubmit={handleAddressSubmit}
                                                initialValues={userAddress}
                                            >
                                                {({
                                                    handleSubmit,
                                                    handleChange,
                                                    handleBlur,
                                                    values,
                                                    touched,
                                                    isValid,
                                                    errors,
                                                    resetForm
                                                }) => (
                                                    <Form noValidate onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}>
                                                        <Row>
                                                            <Col xs={6} className="mb-3">
                                                                <Form.Group role="form">
                                                                    <Form.Label>Address 1 <span className="text-danger">*</span></Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter address 1"
                                                                        id="address1"
                                                                        name="address1"
                                                                        value={values.address1}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        isInvalid={touched.address1 && !!errors.address1}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Address 1 is required
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} className="mb-3">
                                                                <Form.Label>Address 2 <span className="text-danger" style={{visibility: 'hidden'}}>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter address 2"
                                                                    id="address2"
                                                                    name="address2"
                                                                    value={values.address2}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </Col>
                                                            <Col xs={6} className="mb-3">
                                                                <Form.Group>
                                                                    <Form.Label>
                                                                        City <span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter city"
                                                                        id="city"
                                                                        name="city"
                                                                        value={values.city}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        isInvalid={touched.city && !!errors.city}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        City is required
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} className="mb-3">
                                                                <Form.Group>
                                                                    <Form.Label>
                                                                        State <span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter state"
                                                                        id="state"
                                                                        name="state"
                                                                        value={values.state}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        isInvalid={touched.state && !!errors.state}
                                                                        list="stateData"
                                                                    />
                                                                    <datalist id="stateData">
                                                                       {stateList.map((item, key) =>
                                                                         <option key={key} value={item.name} />
                                                                       )}
                                                                    </datalist>
                                                                    <Form.Control.Feedback type="invalid">
                                                                        State is required
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} className="mb-3">
                                                                <Form.Group>
                                                                    <Form.Label>
                                                                        Pincode <span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        placeholder="Enter pincode"
                                                                        id="pincode"
                                                                        name="pincode"
                                                                        value={values.pincode}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        isInvalid={touched.pincode && !!errors.pincode}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Pincode is required
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6} className="mb-3">
                                                                <Form.Group>
                                                                    <Form.Label>
                                                                        Country <span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter country"
                                                                        id="country"
                                                                        name="country"
                                                                        value={values.country}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        isInvalid={touched.country && !!errors.country}
                                                                        list="countryData"
                                                                    />
                                                                    <datalist id="countryData">
                                                                       {countryList.map((item, key) =>
                                                                         <option key={key} value={item.name} />
                                                                       )}
                                                                    </datalist>
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Country is required
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{float: 'right'}}>
                                                            <Button className="submit-btn bio-btn success-btn-2 bio-sm-btn" type="submit">Submit</Button>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </Modal.Body>
                                    </Modal>
                                    <div className="mar-top-2rem mar-btm-2rem course-tab-top-header">
                                        <h1>{course.name}{!!course.enrollment && <Badge className="badgePill" pill bg="success">Enrolled</Badge>}</h1>
                                        <span>{course.level}</span>
                                    </div>

                                    <div className="course-tabs">
                                        <a onClick={() => handleTabClick('Description')}
                                            className={("pointer-button") + (showDescription ? " current" : "")}
                                            data-id="description-content">About</a>
                                        <a onClick={() => (isExperiment || !course.enrollment) && handleTabClick('Experiments')}
                                            className={(isExperiment || !course.enrollment ? "pointer-button " : "tab-masked") + (showExperiments ? "current" : "")}
                                            data-id="experiments-content">
                                                Experiments {!course.enrollment && (<MdLock size={16} color='grey' className='mb-1' />)}
                                        </a>
                                        <a onClick={() => handleTabClick('Quiz')}
                                            data-id="quiz-content"
                                            className={"pointer-button " + (showQuiz ? "current" : "")}>
                                                Quiz {!course.enrollment && (<MdLock size={16} color='grey' className='mb-1' />)}
                                        </a>
                                        <a onClick={() => handleTabClick('Author')}
                                            data-id="author-content"
                                            className={"pointer-button " + (showAboutAuthor ? "current" : "")}>About tutor</a>
                                        {/* <a
                                            data-id="whats-in-the-box"
                                            className={"pointer-button"}>What's in the box?</a> */}
                                        {/* <a onClick={() => handleTabClick('Discussion')}
                                            data-id="discussion-content"
                                            className={"pointer-button " + (showDiscussion ? "current" : "")}>Discussion</a> */}
                                    </div>

                                    {showDescription && <div className={"course-tabs-contents " + (showDescription ? "current" : "")}
                                        id="experiments-content">
                                        <div>{parse(DOMPurify.sanitize(course.description as string))}</div>
                                    </div>}

                                    {showExperiments && <div className={"course-tabs-contents " + (showExperiments ? "current" : "")}
                                        id="experiments-content">
                                        {renderExperiment()}
                                    </div>}

                                    <div className={"course-tabs-contents " + (showQuiz ? "current" : "")}
                                        id="quiz-content">
                                        {renderQuiz()}
                                    </div>

                                    {showAboutAuthor && <div className={"course-tabs-contents " + (showAboutAuthor ? "current" : "")}
                                        id="author-content">
                                        {course.authors?.map((author, index) =>
                                            <div key={index}>{authorItem(author, index)}</div>)}
                                    </div>}

                                    {showDiscussion && <div className={"course-tabs-contents " + (showDiscussion ? "current" : "")}
                                        id="discussion-content">
                                        <div
                                            className="avatar-with-detail align-items-flex-start pad-top-20 pad-lft-0 pad-rgt-0">
                                            <div className="avatar">
                                                <img src="/images/icons/woman-2.png" />
                                            </div>
                                            <div className="avatar-details">
                                                <h5>Nancy Williams <span>Tue 14:03</span></h5>
                                                <p>Hi there, I have a doubt on how enzyme bind with the sector ?</p>
                                                <div className="discussion-iterations">
                                                    <div className="discussion-iterations-actions">
                                                        <a href="javascript:void(0)" className="reply-icon">Reply</a>
                                                        <a href="javascript:void(0)"><span>Show</span>
                                                            <span>6</span> replies</a>
                                                    </div>
                                                    <div className="discussion-iterations-content"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="avatar-with-detail align-items-flex-start pad-top-20 pad-lft-0 pad-rgt-0">
                                            <div className="avatar">
                                                <img src="/images/icons/man.png" />
                                            </div>
                                            <div className="avatar-details">
                                                <h5>Gamora Tedd <span>Tue 14:03</span></h5>
                                                <p>Hello all, can you give an specific example for that part in 8:02 ?</p>
                                                <div className="discussion-iterations">
                                                    <div className="discussion-iterations-actions">
                                                        <a href="javascript:void(0)" className="reply-icon">Reply</a>
                                                        <a href="javascript:void(0)"><span>Hide</span> replies</a>
                                                    </div>
                                                    <div className="discussion-iterations-content">

                                                        <div
                                                            className="avatar-with-detail align-items-flex-start pad-top-20 pad-lft-0 pad-rgt-0">
                                                            <div className="avatar avatar-mini">
                                                                <img src="/images/icons/woman-2.png" />
                                                            </div>
                                                            <div className="avatar-details">
                                                                <h5>Gamora Tedd <span>Tue 14:03</span></h5>
                                                                <p>Yeah sure, ping me</p>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="avatar-with-detail align-items-flex-start pad-top-20 pad-lft-0 pad-rgt-0">
                                                            <div className="avatar avatar-mini">
                                                                <img src="/images/icons/man.png" />
                                                            </div>
                                                            <div className="avatar-details">
                                                                <h5>Gamora Tedd <span>Tue 14:03</span></h5>
                                                                <p>Yeah sure, ping me</p>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="avatar-with-detail align-items-flex-start pad-top-20 pad-lft-0 pad-rgt-0">
                                                            <div className="avatar avatar-mini">
                                                                <img src="/images/icons/woman-2.png" />
                                                            </div>
                                                            <div className="avatar-details">
                                                                <h5>Gamora Tedd <span>Tue 14:03</span></h5>
                                                                <p>Yeah sure, ping me</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>}

                                    <section className="" id="faq">
                                        <h1>FAQ</h1>
                                        <div className="faq-container">
                                            <Accordion>
                                                {course.faqs?.map((faq, index) =>
                                                    <div key={index}>{faqItem(faq, index.toString())}</div>)}
                                            </Accordion>
                                        </div>
                                    </section>

                                </div>

                                <div className="course-view-sidebar">
                                    <Accordion onSelect={(eventKey, event) => { setExpandedChapter(eventKey); }} activeKey={expandedChapter} >
                                        {course.chapters?.map((chapter: Chapter, index: number) => chapterItem(chapter, index))}
                                    </Accordion>
                                    {course.enrollment && (<div className='col-md-12 text-center mb-2 mt-2'>
                                        <Button id="download-certificate" disabled={courses.find(c => c.id === course.id)?.enrollmentStatus !== 'completed'} variant="info" className='text-white' onClick={downloadCertificate}>
                                            Get Certificate
                                        </Button>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<section className="web-section" id="HearFromOurUsers">*/}
                    {/*    <h1>Hear from our users</h1>*/}
                    {/*    <div className="webAlumini-contianer">*/}
                    {/*        <div className="webAluminiImages full-width">*/}
                    {/*            <a href="javascript:void(0)" data-name=""*/}
                    {/*               className="alumini-span-3 alumini1 alumini-contains-rating">*/}
                    {/*                <div className="rating-panel">*/}
                    {/*                    <div className="avatar-with-detail">*/}
                    {/*                        <div className="avatar">*/}
                    {/*                            <img src="/images/icons/man.png"/>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="avatar-details">*/}
                    {/*                            <h5>Elena Tylerwood</h5>*/}
                    {/*                            <div className="rating">*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <p>The main motive to join the course to gain some knowledge about how to draft*/}
                    {/*                        cover letter, motivation letter and statement of purpose. however, the*/}
                    {/*                        course helped me to know the other aspects of the life sciences*/}
                    {/*                        background.</p>*/}
                    {/*                </div>*/}
                    {/*            </a>*/}

                    {/*            <a href="javascript:void(0)" data-name="" className="alumini2 alumini-contains-rating">*/}
                    {/*                <div className="rating-panel full-width">*/}
                    {/*                    <div className="avatar-with-detail">*/}
                    {/*                        <div className="avatar">*/}
                    {/*                            <img src="/images/icons/man.png"/>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="avatar-details">*/}
                    {/*                            <h5>Elena Tylerwood</h5>*/}
                    {/*                            <div className="rating">*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <p>The main motive to join the course to gain some knowledge about how to draft*/}
                    {/*                        cover letter, motivation letter and statement of purpose. however, the*/}
                    {/*                        course helped me to know the other aspects of the life sciences*/}
                    {/*                        background.</p>*/}
                    {/*                </div>*/}
                    {/*            </a>*/}

                    {/*            <a href="javascript:void(0)" data-name=""*/}
                    {/*               className="alumini-span-2 alumini3 alumini-contains-rating">*/}
                    {/*                <div className="rating-panel width-50">*/}
                    {/*                    <div className="avatar-with-detail">*/}
                    {/*                        <div className="avatar">*/}
                    {/*                            <img src="/images/icons/man.png"/>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="avatar-details">*/}
                    {/*                            <h5>Elena Tylerwood</h5>*/}
                    {/*                            <div className="rating">*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <p>The main motive to join the course to gain some knowledge about how to draft*/}
                    {/*                        cover letter, motivation letter and statement of purpose. however, the*/}
                    {/*                        course helped me to know the other aspects of the life sciences*/}
                    {/*                        background.</p>*/}
                    {/*                </div>*/}
                    {/*            </a>*/}

                    {/*            <a href="javascript:void(0)" data-name=""*/}
                    {/*               className="alumini-span-1 alumini3 alumini-contains-rating">*/}
                    {/*                <div className="rating-panel width-50">*/}
                    {/*                    <div className="avatar-with-detail">*/}
                    {/*                        <div className="avatar">*/}
                    {/*                            <img src="/images/icons/man.png"/>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="avatar-details">*/}
                    {/*                            <h5>Elena Tylerwood</h5>*/}
                    {/*                            <div className="rating">*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <p>The main motive to join the course to gain some knowledge about how to draft*/}
                    {/*                        cover letter, motivation letter and statement of purpose. however, the*/}
                    {/*                        course helped me to know the other aspects of the life sciences*/}
                    {/*                        background.</p>*/}
                    {/*                </div>*/}
                    {/*            </a>*/}

                    {/*            <a href="javascript:void(0)" data-name="" className="alumini4 alumini-contains-rating">*/}
                    {/*                <div className="rating-panel full-width">*/}
                    {/*                    <div className="avatar-with-detail">*/}
                    {/*                        <div className="avatar">*/}
                    {/*                            <img src="/images/icons/man.png"/>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="avatar-details">*/}
                    {/*                            <h5>Elena Tylerwood</h5>*/}
                    {/*                            <div className="rating">*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <p>The main motive to join the course to gain some knowledge about how to draft*/}
                    {/*                        cover letter, motivation letter and statement of purpose. however, the*/}
                    {/*                        course helped me to know the other aspects of the life sciences*/}
                    {/*                        background.</p>*/}
                    {/*                </div>*/}
                    {/*            </a>*/}

                    {/*            <a href="javascript:void(0)" data-name="" className="alumini4 alumini-contains-rating">*/}
                    {/*                <div className="rating-panel full-width">*/}
                    {/*                    <div className="avatar-with-detail">*/}
                    {/*                        <div className="avatar">*/}
                    {/*                            <img src="/images/icons/man.png"/>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="avatar-details">*/}
                    {/*                            <h5>Elena Tylerwood</h5>*/}
                    {/*                            <div className="rating">*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <p>The main motive to join the course to gain some knowledge about how to draft*/}
                    {/*                        cover letter, motivation letter and statement of purpose. however, the*/}
                    {/*                        course helped me to know the other aspects of the life sciences*/}
                    {/*                        background.</p>*/}
                    {/*                </div>*/}
                    {/*            </a>*/}

                    {/*            <a href="javascript:void(0)" data-name="" className="alumini5 alumini-contains-rating">*/}
                    {/*                <div className="rating-panel full-width">*/}
                    {/*                    <div className="avatar-with-detail">*/}
                    {/*                        <div className="avatar">*/}
                    {/*                            <img src="/images/icons/man.png"/>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="avatar-details">*/}
                    {/*                            <h5>Elena Tylerwood</h5>*/}
                    {/*                            <div className="rating">*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <p>The main motive to join the course to gain some knowledge about how to draft*/}
                    {/*                        cover letter, motivation letter and statement of purpose. however, the*/}
                    {/*                        course helped me to know the other aspects of the life sciences*/}
                    {/*                        background.</p>*/}
                    {/*                </div>*/}
                    {/*            </a>*/}

                    {/*            <a href="javascript:void(0)" data-name="" className="alumini6 alumini-contains-rating">*/}
                    {/*                <div className="rating-panel full-width">*/}
                    {/*                    <div className="avatar-with-detail">*/}
                    {/*                        <div className="avatar">*/}
                    {/*                            <img src="/images/icons/man.png"/>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="avatar-details">*/}
                    {/*                            <h5>Elena Tylerwood</h5>*/}
                    {/*                            <div className="rating">*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                                <span className="active">&#9733;</span>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <p>The main motive to join the course to gain some knowledge about how to draft*/}
                    {/*                        cover letter, motivation letter and statement of purpose. however, the*/}
                    {/*                        course helped me to know the other aspects of the life sciences*/}
                    {/*                        background.</p>*/}
                    {/*                </div>*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</section>*/}

                </div>
            </div>
        </section>

        <div className={showLoader ? '' : 'hide-element'}>
            <SpinnerElement></SpinnerElement>
        </div>

        {showLogoutModel && <div className='logout-model-container'>
        <div className='logout-model'>
            <div className='logout-header'>
                <img src='/images/Bversity_PrimaryLogo_White.svg' alt="Bversity" />
            </div>
            <div className='logout-content'>
                <p>Are you sure to Logout from your account ?</p>
                <div className='logout-content-btns'>
                    <button className='logout-btn-cancel' onClick={() => setShowLogoutModel(false)}>Cancel</button>
                    <button className='logout-btn-prime' onClick={() => handleLogOut()}>Logout</button>
                </div>
            </div>
        </div>
        </div>}

        </>
    );
};

export default Courses;
