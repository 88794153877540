import type { EventObject } from "@toast-ui/calendar";
import Calendar from "@toast-ui/calendar"; // must go before plugins
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import React, { useEffect, useState } from "react";
import { Col, Modal } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { ICalendarInfo } from "tui-calendar";
import "tui-calendar/dist/tui-calendar.css";
import { URL } from "../../shared/url";
import { AppState } from "../../store";

interface EventInterface {
  name: string;
  description: string;
  createdByUserId: string;
  startsAt: string;
  endsAt: string;
}

const CalendarWrapper: React.FC = ({ }) => {
  const [initialEvents, setInitialEvents] = useState<
    Partial<EventObject>[] | null
  >([]);

  const [showEventInfo, setShowEventInfo] = useState(false)
  const [eventInfo, setEventInfo] = useState<Partial<EventInterface> | null>(null)

  const calendars: ICalendarInfo[] = [
    {
      id: "1",
      name: "My Calendar",
      color: "#ffffff",
      bgColor: "#ffffff",
      dragBgColor: "#ffffff",
      borderColor: "#ffffff",
    },
    // {
    //   id: "2",
    //   name: "Company",
    //   color: "#ffffff",
    //   bgColor: "#00a9ff",
    //   dragBgColor: "#00a9ff",
    //   borderColor: "#00a9ff",
    // },
  ];

  const { accessToken } = useSelector((state: AppState) => state.user);

  const template = {
    milestone(event: EventObject) {
      return `<span style="color:#fff;background-color: #9e5fff;">${event.title}</span>`;
    },
    task(event: EventObject) {
      return `<span style="color:#fff;background-color: #ffffff;">${event.title}</span>`;
    },
    milestoneTitle() {
      return "Milestone";
    },
    taskTitle() {
      return "task";
    },
    allday(event: EventObject) {
      return `${event.title}<i class="fa fa-refresh"></i>`;
    },
    alldayTitle() {
      return "All Day";
    },
    timegridDisplayPrimaryTime(displayTime: any) {
      const time = displayTime.time;
      var meridiem = "am";
      var hour = time.getHours();

      if (time.getHours() > 12) {
        meridiem = "pm";
        hour = time.getHours() - 12;
      }

      return `<span class="timegridDisplayPrimaryTime">${hour}<em>${meridiem}</em></span>`;
    },
    weekDayName(model:any) {
      return `<span class="toastui-calendar-day-name__date">${model.date}</span><span class="toastui-calendar-day-name__name ">-${model.dayName}</span>`;
    },
  };

  const isoToUtc = (item: string) => {
    return item.slice(0, -1);
  };
  const getMilliseconds = (date: string) : number =>new Date(date).getUTCMilliseconds()

  useEffect(() => {
    const fetchdata = async (accessToken: string) => {
      const response = await fetch(`${URL.getEvent}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const oneDayInMilliSeconds = 60 * 60 * 24 * 1000;
      response.json().then((response) =>
        setInitialEvents(
          response.map((item: EventInterface, index: number) => ({
            id: index,
            start: new Date(isoToUtc(item.startsAt)),
            end: new Date(isoToUtc(item.endsAt)),
            title: item.name,
            calendarId: "1",
            category: "time",
            isVisible: true,
            body: item.description,
            isAllDay : ( getMilliseconds(item.startsAt)  - getMilliseconds(item.endsAt)  ) > oneDayInMilliSeconds,
          }))
        )
      );
    };

    fetchdata(accessToken ?? "");
  }, []);

  if (initialEvents != null && !showEventInfo) {
    const calendar = new Calendar("#tui-calendar", {
      defaultView: "week",
      template: template,
      calendars: calendars,
      isReadOnly: true,

    })
    calendar.createEvents(initialEvents)
    calendar.render()
    calendar.on('clickEvent', function ({ event }) {
      setEventInfo({
        name: event.title,
        description: event.body,
        startsAt: event.start.d.toString(),
        endsAt: event.end.d.toString()
      })
      setShowEventInfo(true)
    })

  }
  
  return (
    <div className="calendar-wrapper">
      {initialEvents && (
        <div id="tui-calendar"></div>
      )}
      {
        showEventInfo && eventInfo && <div>
          <Modal className="address-modal" show={showEventInfo} onHide={() => setShowEventInfo(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{'Event Information'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0">
            <Col xs={12} className="mb-3">
            <h5>Title: </h5>{eventInfo.name}
            </Col>
            <Col xs={12} className="mb-3">
            <h5>Description:</h5> {eventInfo.description}
            </Col>
            <Col xs={12} className="mb-3">
            <h5>Starts At: </h5>{eventInfo.startsAt}
            </Col>
            <Col xs={12} className="mb-3">
            <h5>Ends At: </h5>{eventInfo.endsAt}
            </Col>
            </Modal.Body>
          </Modal>

        </div>
      }
    </div>
  );
};
export default CalendarWrapper;
