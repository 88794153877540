import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getLastViewedRequest } from '../actions/analyticsAction';
import {
  clearErrorMessages, fetchSignUp, loginRequest, resetOTPSentFlag, sendOTPRequest, authenticateRequest
} from '../actions/userActions';
import OTPInput from '../components/OTPInput';
import { AppState } from '../store';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const {
    user: {
      isLoggedIn,
      errorMessage,
      isOTPSent,
      session
    },
    analytics
  } = useSelector((state: AppState) => state);

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [OTP, setOTP] = useState<string>('');
  const [timer, setTimer] = useState(5);
  const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  window.onload = () => {
    resetTimer();
  }
  const resendOTP = function () {
    resetTimer();
    handleOTPRequest();
  }

  const resetTimer = function () {
    setTimer(60);
  };

  const handleSignUp = function () {
    dispatch(clearErrorMessages());
    dispatch(fetchSignUp({ isSignUp: true }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get('token');
    if (!!token) 
      dispatch(authenticateRequest({ token }));
  }, [searchParams]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getLastViewedRequest());
      const courseId = searchParams.get('courseId');
      if (!!courseId) {
        navigate(`/courses/${courseId}`);
      } else if (analytics.lastViewed == null || Object.keys(analytics.lastViewed).length === 0) {
        navigate('/home');
      } else {
        navigate('/dashboard');
      }
    }
  }, [isLoggedIn])

  useEffect(() => {
    document.title = 'Login - Bversity';
    window.onbeforeunload = function () {
      if (!isLoggedIn) {
        dispatch(resetOTPSentFlag());
        dispatch(clearErrorMessages());
      }
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [isLoggedIn]);

  const handleOTPRequest = () => {
    resetTimer();
    dispatch(clearErrorMessages());
    dispatch(sendOTPRequest({ phoneNumber }));
  };

  const handleLoginRequest = () => {
    if (OTP.length < 4) {
      return false
    }
    dispatch(loginRequest({
      phoneNumber,
      otp: OTP,
      session: session ?? ''
    }));
  };

  const getPhoneNumberErrors = () => {
    let phoneNumberLength = phoneNumber.trim().length;
    if (phoneNumberLength === 0) return 'Please enter phone number';
    if (phoneNumberLength < 8)
      return 'Phone number must be at least 8 characters long';
    return '';
  };

  const handlePhoneNumberChange = (phoneNumber: string) => {
    dispatch(clearErrorMessages());
    setPhoneNumber(phoneNumber);
  }

  const handleOTPChange = (otp: string) => {
    dispatch(clearErrorMessages());
    setOTP(otp);
    if (otp.length === 4) {
      handleLoginRequest()
    }
  }

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key;
      switch (pressedKey) {
        case 'Enter': {
          if (phoneNumber.trim().length === 13) {
            handleOTPRequest()
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    [phoneNumber],
  );

  return (
    <div className="panel user-auth-panel user-auth-login-panel">
      <h2 className="panel-title">Login</h2>
      {!isOTPSent ? (
        <div>
          <h2 className="panel-title-sub">Enter your phone number</h2>
          <div className="display-grid grid-temp-1 grid-gap-24">
            <div className="b-form-control grid-col-span-3 display-gri-item">
              <PhoneInput
                className='b-form-input b-form-input-phone'
                placeholder="Enter phone number"
                value={phoneNumber}
                defaultCountry="IN"
                onChange={handlePhoneNumberChange}
                onKeyDown={handleOnKeyDown} />
            </div>
          </div>
          <p className="text-center cont-btn">
            <Button
              className="bio-btn dark-btn bio-regular-btn active"
              disabled={phoneNumber?.trim().length < 10 || phoneNumber === undefined}
              onClick={handleOTPRequest}>Get OTP</Button>
          </p>
          {errorMessage !== null && (
            <div className='text-danger text-sm error-message text-center'>{errorMessage}</div>)
          }
          <p className="text-center">Don't have an account ? <a onClick={handleSignUp}>Sign Up</a></p>
        </div>) :
        (
          <>
            <div className="display-grid grid-temp-1 grid-gap-24">
              <div className="b-form-control grid-col-span-3 display-gri-item otp-container">
                <label className="input-label-top" htmlFor="phone">Enter OTP</label>

                <OTPInput
                  autoFocus
                  length={4}
                  className="display-grid grid-temp-4 otp-input-container"
                  inputClassName="b-form-input b-form-input-icon"
                  onChangeOTP={handleOTPChange}
                />


                {timer <= 0 ? (<label onClick={resendOTP} className="input-label-btm" htmlFor="phone">Resend</label>) :
                  (<label className="input-label-btm" htmlFor="phone">Resend OTP in <span hidden={timer == 0}>00:{timer}</span></label>)}
              </div>
            </div>

            <p className="cont-btn text-center">
              <Button
                className="bio-btn dark-btn bio-regular-btn active"
                disabled={OTP.trim().length < 4}
                onClick={handleLoginRequest} >Verify OTP</Button>
            </p>
            {errorMessage !== null && (
              <div className='text-danger text-sm error-message text-center'>{errorMessage}</div>)
            }
          </>
        )}
    </div>
  );
};

export default Login;
