import { useEffect } from 'react';
import useDeepCompareMemoize from './useDeepCompareMemoize';

function useDeepCompareEffect(callback: () => void, dependencies: any[]) {
  useEffect(
    callback,
    dependencies.map(useDeepCompareMemoize)
  )
}

export default useDeepCompareEffect;
