import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { Courses, getCoursesRequest } from "../actions/courseActions";
import { AppState } from "../store";
import "../containers/Profile.scss";
import useAnalyticsEventTracker, { gaEvents } from "../shared/analytics";
import { useNavigate } from "react-router-dom";

const DEFAULT_ACTIVE_TAB = "completed";

const CourseList: React.FC = () => {
  const { courses } = useSelector((state: AppState) => state.courses);
  const dispatch = useDispatch();
  const gaEventTracker = useAnalyticsEventTracker('click');
  const navigate = useNavigate();

  const [filteredCourse, setFilteredCourse] = useState<Courses[] | null>(null);
  const [activeTab, setActiveTab] = useState<string>(DEFAULT_ACTIVE_TAB);
  const [selectedCourse, setSelectedCourse] = useState<number|null>(null);
  

  const requestCallback = (id: number | null) => {
    gaEventTracker(gaEvents.SHOW_COURSE, id);
    setSelectedCourse(id);
  }

  const {
    accessToken
} = useSelector((state: AppState) => state.user);

useEffect(() => {
  if (courses && selectedCourse !== null) {
      navigate(`/courses/${selectedCourse}`);
  }
}, [selectedCourse]);


  const courseItem = (course: Courses) => (
    <>
      <div className="dashboard-card dashboard-card-bord" onClick={() => requestCallback(course.id ?? null)}>
          <div className="d-card-title">
              {course.thumbnail && (
                <img src={course.thumbnail ? course.thumbnail : ''} alt={course.name ? course.name : ''} />
              )}
              <span>{course.name}</span>
          </div>
          <div className="display-flex just-space-around mar-top-1rem">
              <span className="label-with-icon projects-icon">{course.chapters} Chapters</span>
              <span className="label-with-icon experiments-icon">{course.experiments} Experiments</span>
          </div>
      </div>
    </>
  );

  useEffect(()=>{
    dispatch(getCoursesRequest({ accessToken: accessToken ?? '' }))
  },[])

  const filter = (status: string) => {
    setActiveTab(status);
    setFilteredCourse(
      courses.filter((value: Courses) => value.enrollmentStatus == status)
    );
  };

  useEffect(() => {
    filter("completed");
  }, []);

  const courseResponsive = { 0:{ items:1 }, 600:{ items:2 }, 1000:{ items:3 }}

  return (
    <section className="course-list-section box">
      <div
        style={{
          margin: 20,
        }}
      >
        <div className="course-list-section-title">
          <a
            className={`tab-bar ${
              activeTab === "completed" ? "active-tab-border" : ""
            }`}
            onClick={() => filter("completed")}
          >
            <h5>Completed</h5>
          </a>
          <a
            className={`tab-bar ${
              activeTab === "ongoing" ? "active-tab-border" : ""
            }`}
            onClick={() => filter("ongoing")}
          >
            <h5>Ongoing</h5>
          </a>
        </div>
        <hr className="no-margin" />
        <div className="course-list-content whatsNextDashboard mar-top-1rem">
          {filteredCourse && filteredCourse.length > 0 ? (
            <OwlCarousel
              loop={false}
              items={3}
              margin={8}
              responsive={courseResponsive}
              nav
              autoplay
              autoplaySpeed={3000}
              key={activeTab}
            >
              {filteredCourse?.map((course, index) => (
                <div key={index}>{courseItem(course)}</div>
              ))}
            </OwlCarousel>
          ) : (
            <div className="fallback-text">No course data available</div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CourseList;
