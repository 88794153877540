import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import store, { persistor } from './store';
import ErrorBoundary from './components/ErrorBoundary';
import LoginContainer from './containers/LoginContainer';
import React from "react";
import './App.scss';
import Dashboard from './containers/Dashboard';
import Community from './containers/Community';
import Home from './containers/Home';
import AuthContainer from './containers/AuthContainer';
import Courses from "./containers/Courses";
import Profile from './containers/Profile';
import ReactGA from 'react-ga';
import OrderStatus from './containers/OrderStatus';
import AllCourses from './containers/AllCourses';

const gaTrackingKey = window._env_?.GA_TRACKING_KEY || process.env.GA_TRACKING_KEY;
ReactGA.initialize(gaTrackingKey);

const App: React.FC = () => (
    <ErrorBoundary>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <Routes>
                        <Route path="/" element={<AuthContainer />} />
                        <Route path="/login" element={<LoginContainer />} />
                        <Route path="/home" element={<Home />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/community" element={<Community />} />
                        <Route path="/courses/:courseId" element={<Courses />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/orderStatus/:courseId" element={<OrderStatus />} />
                        <Route path="/allcourses" element={<AllCourses />} />
                        <Route path="*" element={<Home />} />
                    </Routes>
                </Router>
            </PersistGate>
        </Provider>
    </ErrorBoundary>
);

export default App;
