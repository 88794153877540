import React, { useEffect, useState } from "react";
import { AppState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import "../../containers/Profile.scss";
import { URL } from "../../shared/url";
import EditProfile from "./EditProfile";
import {
  profileDataRequest,
  ProfileSuccessPayload,
} from "../../actions/userActions";
import EditImage from "./EditImage";
import { uploadToS3 } from "../../services/s3.service";

export interface Profile {
  firstName: string;
  lastName: string;
  address: string;
  profilePicture: string;
  dateOfBirth: string;
  phoneNumber: string;
  email: string;
  instituteName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  id: string;
}
const PersonalInfo: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppState) => state);
  const { accessToken } = user;

  const [showPersonalInfoEdit, setShowPersonalInfoEdit] = useState(false);
  const [showPersonalImage, setShowPersonalImageEdit] = useState(false);

  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    dispatch(profileDataRequest());
  }, []);

  const updateProfile = (newUser: Profile) => {
    fetch(`${URL.updateProfile}/${user?.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        address: JSON.stringify({
          address1: newUser.address1,
          address2: newUser.address2,
          city: newUser.city,
          state: newUser.state,
          country: newUser.country,
          pincode: newUser.pincode,
        }),
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        dateOfBirth: newUser.dateOfBirth,
        phoneNumber: newUser.phoneNumber,
        email: newUser.email,
        instituteName: newUser.instituteName,
      }),
    }).then((response) => {
      if (response.status == 200) {
        setShowPersonalInfoEdit(false);
        dispatch(profileDataRequest());
      } else {
        setMessage("Could not update profile. Try again");
      }
    });
  };

  const updateImage = async (image: File | null) => {
    if(image == null || accessToken == null) return 

    const url = await uploadToS3({image}, accessToken)
  
    await fetch(`${URL.updateImage}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "fileName": url
      }),
    })
    .then((response) => {
      if (response.status == 200) {
        setShowPersonalImageEdit(false);
        dispatch(profileDataRequest());
      } else {
        setMessage("Could not update profile. Try again");
      }
    });
  };

  if (user == null) {
    return null;
  }

  return (
    <>
      <EditProfile
        show={showPersonalInfoEdit}
        handleClose={() => setShowPersonalInfoEdit(false)}
        handleSubmit={(e) => updateProfile(e)}
        user={user as ProfileSuccessPayload}
        message={message}
      />
      <EditImage  show={showPersonalImage}
        handleClose={() => setShowPersonalImageEdit(false)} onSubmit={updateImage}
        message={message}
        />
      <div className="personal-info-header">
        <div className="personal-info-header-pane">
          <section className="box">
            {/* <div className="left-pane-item title-header">
              <h4>Basic info</h4>
              <a className="pointer-button edit" onClick={() => setShowPersonalInfoEdit(true)}>
                <img
                  className="edit-icon"
                  width="20px"
                  height="20px"
                  src={"/images/icons/edit.svg"}
                />
                <h6 className="no-margin no-padding">Edit</h6>
              </a>
            </div> */}
            <div className="left-pane-item personal-info-header-pane-body">

              <a className="pointer-button edit personal-info-edit" onClick={() => setShowPersonalInfoEdit(true)}>
                <img
                  className="edit-icon"
                  width="20px"
                  height="20px"
                  src={"/images/icons/edit.svg"}
                />
                <h6 className="no-margin no-padding">Edit</h6>
              </a>

              <div className="profile-image-container">
                <img
                  className="profile-image"
                  height="100%"
                  width="100%"
                  src={user?.profilePicture ? user.profilePicture : "/images/profile-picture-fallback.png"}
                />
                <a
                  className="biota-user-picutre upload-icon"
                  onClick={() => setShowPersonalImageEdit(true)}
                />
              </div>
              <div className="basic-detail-wrapper">
                <div className="center-div">
                  <h2>
                    {user.firstName} {user.lastName}
                  </h2>
                </div>
                <div className="details-row">
                  <div className="label-value">
                    <h5 className="label">Phone</h5>
                    <h6>{user.phoneNumber ? user.phoneNumber : '-'}</h6>
                  </div>
                  <div className="label-value">
                    <h5 className="label">E-mail</h5>
                    <h6>{user.email ? user.email : '-'}</h6>
                  </div>
                </div>
                <div className="details-row">
                  <div className="label-value">
                    <h5 className="label">D.O.B</h5>
                    <h6>
                      {user?.dateOfBirth
                        ? new Date(user.dateOfBirth)
                            .toDateString()
                            .substring(4)
                            .replaceAll(" ", ",")
                            .replace(",", " ")
                        : "-"}
                    </h6>
                  </div>
                  <div className="label-value">
                    <h5 className="label">College</h5>
                    <h6>{user.instituteName ? user.instituteName : '-'}</h6>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <div className="right-pane">
          <div className="right-pane-content-wrapper">
            <section
              className="box right-pane-item"
              style={{ marginBottom: 10 }}
            >
              <div className="center-div">
                <h4>Current plan</h4>
              </div>
            </section>
            <section className="box right-pane-item" style={{ marginTop: 10 }}>
              <div className="center-div">
                <h4>Saved items</h4>
              </div>
            </section>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default PersonalInfo;
