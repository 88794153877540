import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavContainer from "../components/NavContainer";
import './Community.scss';

const Community: React.FC = () => {
    useEffect(() => {
        document.title = 'Community - Bversity';
    })
    return (
        <NavContainer showAppHeader={false} useBanner={false}>
            <div className="communityBanner"></div>
            <div className="communityContainer">
                <h1 className="communityPageTitle">Welcome to our own community</h1>
                <h1 className="communityPageTitle2">B <span>for</span> Biology</h1>
                <a href="https://bit.ly/b4b-chennai" target="_blank" className="community-btn">Join now</a>
                <div className="communityFlex">
                    <div className="communityImages">
                        <img src="/images/pexels-mikhail-nilov-9543814.jpg" className="communityImage-1" />
                        <img src="/images/pexels-cottonbro-4881619.jpg" className="communityImage-2" />
                        <img src="/images/pexels-pavel-danilyuk-6405769.jpg" className="communityImage-3" />
                    </div>
                    <div className="communityContent">
                        <div className="communityContent-1">
                            <h1>Network & Nurture</h1>
                            <p>A place where biotechnologists from diverse backgrounds & professional aspirations get to connect & engage.</p>
                        </div>
                        <div className="communityContent-2">
                            <h1>Events & Experience</h1>
                            <p>Join up on innovative activities like talk shows, workshops, and other events to give learners a whole new, enriching perspective.</p>
                        </div>
                        <div className="communityContent-3">
                            <h1>Fun & Inclusiveness</h1>
                            <p>Join a space that place us inclusivity as its cornerstone and strives to give learning a new perspective fused with fun & innovation</p>
                        </div>
                    </div>
                </div>
            </div>
        </NavContainer>
    )
}

export default Community;