import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {clearErrorMessages, fetchSignUp, resetOTPSentFlag, signUpRequest} from '../actions/userActions';
import InputField from '../components/InputField';
import {UserPhoneNumber} from '../types/common';
import {AppState} from '../store';
import {Row, Col} from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';

export interface LoginProps {
    userPhoneNumber: UserPhoneNumber;
}

const SignUp: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.onbeforeunload = function () {
            dispatch(clearErrorMessages());
        };

        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    const {errorMessage} = useSelector((state: AppState) => state.user);

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');

    const handleSignUp = function () {
        dispatch(clearErrorMessages());
        dispatch(fetchSignUp({isSignUp: false}));
    };

    const handleSignUpRequest = () => {
        dispatch(signUpRequest({
            phoneNumber,
            firstName,
            lastName,
            email,
            role: "Student"
        }));
    };

    const handlePhoneNumberChange = (phoneNumber: string) => {
        dispatch(clearErrorMessages());
        setPhoneNumber(phoneNumber);
    }

    const handleFirstNameChange = (firstName: string) => {
        dispatch(clearErrorMessages());
        setFirstName(firstName);
    }

    const handleLastNameChange = (lastName: string) => {
        dispatch(clearErrorMessages());
        setLastName(lastName);
    }

    const handleEmailChange = (email: string) => {
        dispatch(clearErrorMessages());
        setEmail(email);
    }

    return (
        <div className="panel user-auth-panel">
            <h2 className="panel-title">Sign Up</h2>
            <h2 className="panel-title-sub">Enter your details</h2>

            <div className="display-grid grid-temp-2 grid-gap-24">
                <div className="b-form-control display-gri-item">
                    <label htmlFor="firstName">First name</label>
                    <InputField
                        className=''
                        InputFieldClass='b-form-input'
                        value={firstName}
                        type='text'
                        onChange={handleFirstNameChange}
                        validation={''}
                        placeholder='Enter first name'
                        errors={[]}
                    />
                </div>
                <div className="b-form-control display-gri-item">
                    <label htmlFor="lastName">Last name</label>
                    <InputField
                        className=''
                        InputFieldClass='b-form-input'
                        value={lastName}
                        type='text'
                        onChange={handleLastNameChange}
                        placeholder='Enter last name'
                        validation={''}
                        errors={[]}
                    />
                </div>
                <div className="b-form-control grid-col-span-3 display-gri-item">
                    <label htmlFor="email">E-mail</label>
                    <InputField
                        className=''
                        InputFieldClass='b-form-input b-form-input-icon b-form-input-msg'
                        value={email}
                        type='text'
                        onChange={handleEmailChange}
                        placeholder='Enter e-mail'
                        validation={''}
                        errors={[]}
                    />
                    {/* <span className="error-text">*Please enter a valid e-mail id</span> */}
                </div>
                <div className="b-form-control grid-col-span-3 display-gri-item">
                    <label htmlFor="phone">Phone</label>
                    <PhoneInput
                        className='b-form-input b-form-input-icon b-form-input-phone'
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        defaultCountry="IN"
                        onChange={handlePhoneNumberChange}
                    />
                </div>
            </div>

            <p className="cont-btn text-center">
                <Button
                    className="bio-btn dark-btn bio-regular-btn active"
                    disabled={((phoneNumber?.trim().length < 10 || phoneNumber === undefined) || firstName.trim().length === 0 ||
                        lastName.trim().length === 0 || email.trim().length === 0)}
                    onClick={handleSignUpRequest}>Sign Up</Button>
            </p>
            {errorMessage !== null && (
                <div className='text-danger text-sm error-message text-center'>{errorMessage}</div>)
            }
            <p className="text-center">Already have an account ? <a className="signUp-link"
                                                                    onClick={handleSignUp}>Login</a></p>
        </div>
    );
};

export default SignUp;
