import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AppCustomError } from '../errors';

import {
    Banner,
    Courses, EnrollmentStatusUpdateResponse, getCourseBannerFailure, getCourseBannerSuccess,
    getCoursesFailure,
    getCoursesRequest,
    getCoursesSuccess,
    getNextCoursesSuccess,
    updateCourseEnrollmentStatusSuccess
} from "../actions/courseActions";
import { logOut } from "../actions/userActions";

interface CoursesState {
    courses: Courses[],
    banners: Banner[],
    errorMessage: string | null,
    hasError: boolean,
    nextCourses: Courses[]
}

const initialState: CoursesState = {
    courses: [],
    banners: [],
    errorMessage: null,
    hasError: false,
    nextCourses: []
};

const coursesReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getCoursesRequest, (state: CoursesState) => ({
            ...state
        }))

        .addCase(
            getCoursesFailure,
            (state: CoursesState, action: PayloadAction<AppCustomError>) => ({
                ...state,
                errorMessage: action.payload.message,
                hasError: true
            })
        )

        .addCase(getCoursesSuccess,
            (state: CoursesState, action: PayloadAction<Courses[]>) => ({
                ...state,
                courses: action.payload,
                errorMessage: null,
                hasError: false
            }))

        .addCase(getCourseBannerSuccess,
            (state: CoursesState, action: PayloadAction<Banner[]>) => ({
                ...state,
                banners: action.payload
            }))

        .addCase(getCourseBannerFailure,
            (state: CoursesState, action: PayloadAction<AppCustomError>) => ({
                ...state,
                errorMessage: action.payload.message,
                hasError: true,
            }))
        .addCase(getNextCoursesSuccess,
            (state: CoursesState, action: PayloadAction<Courses[]>) => ({
                ...state,
                nextCourses: action.payload
            }))
        .addCase(updateCourseEnrollmentStatusSuccess, 
            (state: CoursesState, action: PayloadAction<EnrollmentStatusUpdateResponse>) => ({
                ...state,
                courses: state.courses.map(course => {
                    if (course.id === action.payload.course.id) {
                        return {
                            ...course,
                            enrollmentStatus: action.payload.status
                        };
                    }

                    return course;
                })
            }))

        .addCase(
            logOut,
            () => (initialState)
        );
});

export default coursesReducer;
