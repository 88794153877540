import DoughnutWrapper from "./DoughnutWrapper"
import React, { useEffect, useState } from "react";
import { URL } from '../../shared/url'
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import SemiCircleProgress from '../SemiCircleProgress';

interface QuizAnalytics {
    totalQuiz: number;
    completedQuiz: number,
    completedQuizScore: number
}

interface AssignmentsAnalytics {
    completedAssignment: number;
    completedAssignmentsScore: number;
    totalAssignments: number;
}

interface StatusWrapperProps {
    lastViewedCourseId: number,
    lastViewedCourseName: string
}

const StatusWrapper: React.FC<StatusWrapperProps> = (props) => {

    const [assignmentsAnalytics, setAssignmentsAnalytics] = useState<AssignmentsAnalytics | null>(null);
    const [quizAnalytics, setQuizAnalytics] = useState<QuizAnalytics | null>(null)
    const { accessToken } = useSelector((state: AppState) => state.user);

    const fetchAssignmentsAnalytics = async () => {
        fetch(`${URL.getAssignmentsAnalytics}/${props.lastViewedCourseId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        }).then((response) => {
            response.json().then((analytics) => {
                setAssignmentsAnalytics(analytics)
            })
        })
    }

    const fetchQuizAnalytics = async () => {
        fetch(`${URL.getQuizAnalytics}/${props.lastViewedCourseId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        }).then((response) => {
            response.json().then((analytics) => {
                setQuizAnalytics(analytics)
            })
        })
    }

    useEffect(() => {
        fetchAssignmentsAnalytics()
        fetchQuizAnalytics()
    }, [])


    if (assignmentsAnalytics == null || quizAnalytics == null) {
        return null
    }


    return <div className="statusWrapper">
        <h3>{props.lastViewedCourseName}</h3>
        {/* <div className='doughnutWrapperContainer'>
            <DoughnutWrapper 
                title="Completed assignments" 
                completed={assignmentsAnalytics.completedAssignment} 
                completedScore={assignmentsAnalytics.completedAssignmentsScore} 
                missedScore={100 - assignmentsAnalytics.completedAssignmentsScore} 
                message={
                    (assignmentsAnalytics.completedAssignmentsScore >= 0 && assignmentsAnalytics.completedAssignmentsScore <= 50) ? "No worries! It's never too late to level up again!"
                    : (assignmentsAnalytics.completedAssignmentsScore > 50 && assignmentsAnalytics.completedAssignmentsScore <= 80) ? 'Yass! There you go! Keep progressing!'
                    : (assignmentsAnalytics.completedAssignmentsScore > 80 && assignmentsAnalytics.completedAssignmentsScore <= 100) ? 'Congratulations! You’ve reached a milestone' : ''} 
            />

            <DoughnutWrapper 
                title="Completed quizzes" 
                completed={quizAnalytics.completedQuiz} 
                completedScore={quizAnalytics.completedQuizScore} 
                missedScore={100 - quizAnalytics.completedQuizScore} 
                message={
                    (quizAnalytics.completedQuizScore >= 0 && quizAnalytics.completedQuizScore <= 50) ? "No worries! It's never too late to level up again!"
                    : (quizAnalytics.completedQuizScore > 50 && quizAnalytics.completedQuizScore <= 80) ? 'Yass! There you go! Keep progressing!'
                    : (quizAnalytics.completedQuizScore > 80 && quizAnalytics.completedQuizScore <= 100) ? 'Congratulations! You’ve reached a milestone' : ''}
            />
        </div> */}

        <div className='doughnutWrapperContainer'>
            <SemiCircleProgress 
                startColor="#56c4fb"
                stopColor="#0baeff"
                title="Completed assignments" 
                completed={assignmentsAnalytics.completedAssignment} 
                percent={assignmentsAnalytics.completedAssignmentsScore}
                message={
                    (assignmentsAnalytics.completedAssignmentsScore >= 0 && assignmentsAnalytics.completedAssignmentsScore <= 50) ? "No worries! It's never too late to level up again!"
                    : (assignmentsAnalytics.completedAssignmentsScore > 50 && assignmentsAnalytics.completedAssignmentsScore <= 80) ? 'Yass! There you go! Keep progressing!'
                    : (assignmentsAnalytics.completedAssignmentsScore > 80 && assignmentsAnalytics.completedAssignmentsScore <= 100) ? 'Congratulations! You’ve reached a milestone' : ''} 
            ></SemiCircleProgress>
            <SemiCircleProgress 
                startColor="#1ceac3"
                stopColor="#1ceac3"
                title="Completed quizzes" 
                completed={quizAnalytics.completedQuiz} 
                percent={quizAnalytics.completedQuizScore}
                message={
                    (quizAnalytics.completedQuizScore >= 0 && quizAnalytics.completedQuizScore <= 50) ? "No worries! It's never too late to level up again!"
                    : (quizAnalytics.completedQuizScore > 50 && quizAnalytics.completedQuizScore <= 80) ? 'Yass! There you go! Keep progressing!'
                    : (quizAnalytics.completedQuizScore > 80 && quizAnalytics.completedQuizScore <= 100) ? 'Congratulations! You’ve reached a milestone' : ''}
            ></SemiCircleProgress>
        </div>
    </div>
}

export default StatusWrapper
