import React, { useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getLastViewedRequest } from '../actions/analyticsAction';
import { AppState } from '../store';
import './AuthController.scss';
import Login from './Login';
import SignUp from './SignUp';

const AuthContainer: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();

    const {
        user: {
            isSignUp,
            isLoading,
            isLoggedIn 
        },
        analytics
    } = useSelector((state: AppState) => state);

  useEffect( () => {
    if (isLoggedIn) {
        if (location.key !== 'default') {
            navigate(-2);
        } else {
            const courseId = searchParams.get('courseId');
            dispatch(getLastViewedRequest());
            if (!!courseId) {
                navigate(`/courses/${courseId}`);
            } else if (analytics.lastViewed == null || Object.keys(analytics.lastViewed).length === 0) {
                navigate('/home');
            } else{
                navigate('/dashboard');
            }
        }
    }
  },[isLoggedIn])


    return (
        <>
        <div className={isLoading? "loader-div" : ""}>
            <TailSpin
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass="loading-spin"
                visible={isLoading}
            />
        </div>

        <section data-testid="login" className="web-section mar-top-no">

            {isSignUp && (
                <h1 className="app-section-page-header text-center">Hey you!<br/>Here's a secret!<br/>you've arrived <span>at the right place</span></h1>
            )}

            {!isSignUp && (
                <h1 className="app-section-page-header text-center">Welcome back <span>Bvers</span>ite !</h1>
            )}

            <div className="display-flex sm-display-flex just-cent align-items-cent">
                <div className="width-50 display-flex flex-dir-col just-cent md-hide">
                    <Image className='img-center signup-photo' src='/images/signup-photo.png' alt="BIOTA new user sign up" fluid />
                    
                    {isSignUp && (
                    <h2 className="app-section-header size-2 text-center mar-top-2rem">
                        <span>Sign up</span> & <span>level up</span> with a career transforming learning experience
                    </h2>)}

                    {!isSignUp && (
                        <h2 className="app-section-header size-2 text-center mar-top-2rem">A massive career transition is approaching !</h2>
                    )}
                </div>
                <div className="width-50 sm-width-100">
                    {isSignUp ? (<SignUp />) : (<Login />)}
                </div>
            </div>
        </section>
        </>
    );
};

export default AuthContainer;
