import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CourseList from "../components/CourseList";
import NavContainer from "../components/NavContainer";
import PersonalInfo from "../components/Profile/PersonalInfo";
import ProjectList from "../components/ProjectList";
import { AppState } from "../store";

const Profile: React.FC = () => {
  const { isLoggedIn} = useSelector(
    (state: AppState) => state.user
  );

  const navigate = useNavigate();

  useEffect(()=>{
    document.title = 'My Profile - Bversity';
    
    if (!isLoggedIn) {
      navigate("/");
    }
  },[isLoggedIn])
  return (
    <NavContainer useBanner={false} showAppHeader={false}>
      <PersonalInfo />
      {/* <ProjectList/> */}
      <CourseList />
    </NavContainer>
  );
};

export default Profile;
