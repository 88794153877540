import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { AppState } from '../store';
import { Courses, getCoursesRequest } from "../actions/courseActions";
import "./Common.scss";
import Badge from "react-bootstrap/Badge";

import NavContainer from '../components/NavContainer';
import { refreshTokenRequest } from '../actions/userActions';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import useAnalyticsEventTracker, { gaEvents } from "../shared/analytics";
import OnBoardModal from './OnBoardModal';

interface CoursesSet {
    [Key: string]: Courses;
}

const Home: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const gaEventTracker = useAnalyticsEventTracker('click');

    const {
        isLoggedIn,
        accessToken
    } = useSelector((state: AppState) => state.user);

    const {
        courses,
    } = useSelector((state: AppState) => state.courses);

    const {
        course
    } = useSelector((state: AppState) => state.course);

    const [selectedCourse, setSelectedCourse] = useState<number|null>(null);

    const requestCallback = (id: number | null) => {
        gaEventTracker(gaEvents.SHOW_COURSE, id);
        setSelectedCourse(id);
    }

    useEffect(() => {
        document.title = 'Courses - Bversity';
        if (accessToken) {
            const userData: any = jwtDecode(accessToken);
            const isExpired = dayjs.unix(userData.exp).diff(dayjs()) < 1;
    
            if(isExpired) {
              dispatch(refreshTokenRequest());
            }
        }
    })


    useEffect(() => {
        if (course && selectedCourse !== null) {
            navigate(`/courses/${selectedCourse}`);
        }

        if (!isLoggedIn) {
            navigate('/');
        }
        else {
            dispatch(getCoursesRequest({ accessToken: accessToken ?? '' }));
        }
    }, [course, selectedCourse, isLoggedIn]);

    const coursesSet: CoursesSet = {};
    courses?.forEach(e =>
        coursesSet[e.id ?? ''] = e);
    const coursesList = Object.values(coursesSet);

    const courseItem = (course: Courses, index: number) => {
        return (
            <div className="dashboard-card" onClick={() => requestCallback(course.id ?? null)}>
                <div className="d-card-title">
                    <img src={course.thumbnail ?? ''} alt="Panel Title" />
                    <span>{course.name}</span>
                </div>
                <div className="avatar-with-detail pad-top-20 pad-lft-20 pad-rgt-20">
                    <div className="avatar">
                       <img src={course.authors[0]?.profilePicture} />
                    </div>
                    <div className="avatar-details">
                        <h5>{course.authors[0]?.name}{course.enrollment !== 0 && <Badge className="badgePill" pill bg="success">Enrolled</Badge>}</h5>
                        <span>{course.authors[0]?.institution}</span>
                    </div>
                </div>
                <div className="display-flex just-space-around">
                    <span className="label-with-icon projects-icon">{course.chapters} Chapter</span>
                    <span className="label-with-icon experiments-icon">{course.experiments} Experiments</span>
                </div>
                <a onClick={() => requestCallback(course.id ?? null)} className="pointer-button card-btn-on-hover">Start now</a>
            </div>
        );
    }

    return (
        <NavContainer>
            <div>
                <OnBoardModal />
            </div>
            {coursesList.length > 0 ? (
                <div className="display-grid grid-temp-4 xmd-grid-temp-3 xs-grid-temp-2 xxs-grid-temp-1 unitFR dashboard-cards">
                    {coursesList?.map((course, index) =>
                        <div key={index}>{courseItem(course, index)}</div>)}
                </div>)
                : (<div className="no-course-text flex-grow-1">No courses available!</div>)}
        </NavContainer>
    );
};

export default Home;
