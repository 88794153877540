import {
    AssignmentPayload,
    CourseAnalyticsPayload, CoursePayload, EnrollmentPayload, EnrollmentStatusUpdatePayload, GetTasksPaylod, OrderPayload, PaymentFailurePayload, ShipmentOrderPayload, SubmitQuizPayload, Task
} from "../actions/courseActions";
import { URL, getURL } from '../shared/url';

export async function getCourses(accessToken: string): Promise<any> {
    const res = await fetch(URL.getCourses, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function getCourse(req: CoursePayload, accessToken: string): Promise<any> {
    const res = await fetch(`${URL.getCourses}/${req.courseId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function getCourseBanners(accessToken: string): Promise<any> {
    const res = await fetch(`${URL.getCourseBanners}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function publishAnalytics(req: CourseAnalyticsPayload, accessToken: string): Promise<any> {
    const res = await fetch(URL.publishCourseAnalytics, {
      method: 'POST',
      body: JSON.stringify(req),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
    })

    if (res.status === 204) {
        return Promise.resolve({});
    } else {
        return Promise.reject({ message: 'Publish analytics failed' });
    }
  }

export async function createPaymentOrder(req: OrderPayload, accessToken: string): Promise<any> {
    const res = await fetch(URL.createPaymentOrder, {
        method: 'POST',
        body: JSON.stringify(req),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function createShipmentOrder(req: ShipmentOrderPayload, accessToken: string): Promise<any> {
    const res = await fetch(URL.createShipmentOrder, {
        method: 'POST',
        body: JSON.stringify(req),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function createCourseEnrollment(req: EnrollmentPayload, accessToken: string): Promise<any> {
    const res = await fetch(URL.createCourseEnrollment, {
        method: 'POST',
        body: JSON.stringify(req),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function recordEnrollmentFailure(req: PaymentFailurePayload, accessToken: string): Promise<any> {
    const res = await fetch(URL.recordPaymentFailure, {
        method: 'POST',
        body: JSON.stringify(req),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function getNextCourses( accessToken: string): Promise<any> {
    const response = await fetch(`${URL.getNextCourses}`,
        { method: 'GET', headers: { Authorization: `Bearer ${accessToken}` } });
       
    return response.json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function submitQuiz(req: SubmitQuizPayload, accessToken: string): Promise<any> {
    const res = await fetch(getURL.submitQuiz(req.chapterId, req.experimentId), {
        method: 'PUT',
        body: JSON.stringify({ answers: req.answers }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function getTasks(req: GetTasksPaylod, accessToken: string): Promise<any> {
    const res = await fetch(`${URL.getTasks}/${req.courseId}`,
        { method: 'GET', headers: { Authorization: `Bearer ${accessToken}` } });
    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function putTask(req: Task, accessToken: string): Promise<any> {
    const res = await fetch(URL.putTask, {
        method: 'PUT',
        body: JSON.stringify(req),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function uploadAssignments(req: AssignmentPayload, accessToken: string): Promise<any> {
    const res = await fetch(URL.uploadAssignment, {
        method: 'POST',
        body: JSON.stringify(req),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function updateCourseEnrollmentStatus(req: EnrollmentStatusUpdatePayload, accessToken: string): Promise<any> {
    const res = await fetch(getURL.updateEnrollmentStatus(req.courseId), {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}
