import { call, put, takeEvery, putResolve, select } from 'redux-saga/effects';
import { getProfileData, login, getRefreshedToken, requestOTP, signUp, updateUserAddress, updateUserOnboard, authenticate } from '../services/user.service';
import { createCustomError, createError } from '../errors';
import {
  ReqOTPPayload, sendOTPFailure, sendOTPRequest, sendOTPSuccess, SendOTPSuccessPayload,
  loginRequest, loginSuccess, loginFailure, LoginPayload, LoginSuccessPayload, signUpRequest,
  SignUpRequestSuccessPayload, signUpSuccess, signUpFailure, SignUpRequestPayload, toggleLoading, logOut, updateAddressRequest, updateAddressRequestSuccess, updateAddressRequestFailure, profileDataRequestSuccess, profileDataRequest, refreshTokenRequest, RefreshTokenPayload, refreshTokenSuccess, updateOnboardRequestFailure, updateOnboardRequestSuccess, updateOnboardRequest, authenticateRequest, AuthenticatePayload
} from '../actions/userActions';
import {getCoursesRequest} from "../actions/courseActions";

function* requestOTPHandler(params: {
  type: string;
  payload: ReqOTPPayload;
}): any {
  const { phoneNumber } = params.payload;
  try {
    yield put(toggleLoading(true));
    const OTPResponse: SendOTPSuccessPayload = yield call(requestOTP, phoneNumber);

    if (OTPResponse.session) {
      yield put(sendOTPSuccess({ session: OTPResponse.session, phoneNumber: phoneNumber, message: null }));
    }
    else {
      yield put(sendOTPFailure(createCustomError(OTPResponse.message)));
    }
  } catch (error: any) {
    yield put(sendOTPFailure(createCustomError(error)));
  }
}

function* loginHandler(params: {
  type: string;
  payload: LoginPayload;
}): any {
  try {
    yield put(toggleLoading(true));
    const loginResponse: any = yield call(login, params.payload);

    if(loginResponse.errorCode) {
      yield put(loginFailure(createCustomError(loginResponse.message)));
    }
    else {
      yield putResolve(loginSuccess({ AccessToken: loginResponse.AccessToken, ExpiresIn: loginResponse.ExpiresIn, RefreshToken: loginResponse.RefreshToken,
        User: loginResponse.User , PageMap: loginResponse.PageMap}));
      yield putResolve(getCoursesRequest({ accessToken: loginResponse.AccessToken }));
    }

    yield put(toggleLoading(false));

  } catch (error: any) {
    yield put(loginFailure(createError(error)));
  }
}

function* authenticateHandler(params: {
  type: string;
  payload: AuthenticatePayload;
}): any {
  try {
    yield put(toggleLoading(true));
    const authenticateResponse: any = yield call(authenticate, params.payload);

    if(authenticateResponse.errorCode) {
      yield put(loginFailure(createCustomError(authenticateResponse.message)));
    }
    else {
      yield putResolve(loginSuccess({ AccessToken: authenticateResponse.AccessToken, 
        ExpiresIn: authenticateResponse.ExpiresIn, RefreshToken: authenticateResponse.RefreshToken,
        User: authenticateResponse.User , PageMap: authenticateResponse.PageMap}));
      yield putResolve(getCoursesRequest({ accessToken: authenticateResponse.AccessToken }));
    }

    yield put(toggleLoading(false));

  } catch (error: any) {
    yield put(loginFailure(createError(error)));
  }
}

function* signUpHandler(params: {
  type: string;
  payload: SignUpRequestPayload;
}): any {
  try {
    yield put(toggleLoading(true));

    const signUpResponse: SignUpRequestSuccessPayload = yield call(signUp, params.payload);
    if (signUpResponse.errorCode || signUpResponse.message != "User created successfully") {
      yield put(signUpFailure(createCustomError(signUpResponse.message)));
    }
    else {
      let responsePayload: SignUpRequestSuccessPayload = {
        firstName: params.payload.firstName,
        lastName: params.payload.lastName,
        phoneNumber: params.payload.phoneNumber,
        email: params.payload.email,
        role: params.payload.role,
        message: signUpResponse.message,
        errorCode: ''
      }

      yield put(signUpSuccess(responsePayload));
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

function* logoutHandler(params: {
  type: string;
}): any {
  console.log("Logout hit");
  localStorage.removeItem('persist:root');
}

function* updateAddressHandler(params: {
  type: string;
  payload: any;
}): any {
  try {
    yield put(toggleLoading(true));
    const { accessToken } = yield select((state) => state.user);

    const userResponsePayload: any = yield call(updateUserAddress, params.payload, accessToken);

    if (userResponsePayload.errorCode) {
      yield put(updateAddressRequestFailure(createCustomError(userResponsePayload.message)));
    }
    else {
      yield put(updateAddressRequestSuccess(params.payload));
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

function* updateOnboardHandler(params: {
  type: string;
  payload: any;
}): any {
  try {
    yield put(toggleLoading(true));
    const { accessToken } = yield select((state) => state.user);

    const userResponsePayload: any = yield call(updateUserOnboard, params.payload, accessToken);

    if (userResponsePayload.errorCode) {
      yield put(updateOnboardRequestFailure(createCustomError(userResponsePayload.message)));
    }
    else {
      yield put(updateOnboardRequestSuccess(params.payload));
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

function* profileDataRequestHandler(params: { type: string }): any {
  try {
    const { accessToken } = yield select((state) => state.user);

    const profileData: any = yield call(getProfileData, accessToken);
    if (
      profileData.errorCode === 401 ||
      profileData.errorCode == "InvalidToken"
    ) {
      yield put(refreshTokenRequest());
    }

    yield put(profileDataRequestSuccess(profileData));
  } catch (error: any) {
    throw new Error(error);
  }
}

function* refreshTokenHandler(params: { type: string }): any {
  try {
    const { refreshToken } = yield select((state) => state.user);
    
    var payload: RefreshTokenPayload = {
      refreshToken: refreshToken
    }
    const refreshTokenResponse = yield call(getRefreshedToken, payload);

    if (refreshTokenResponse.errorCode === 401 || refreshTokenResponse.errorCode == "InvalidToken") {
      yield put(logOut());
    }

    yield put(refreshTokenSuccess(refreshTokenResponse));
  } catch (error: any) {
    throw new Error(error);
  }
}

function* userSaga() {
  yield takeEvery(sendOTPRequest.type, requestOTPHandler);
  yield takeEvery(loginRequest.type, loginHandler);
  yield takeEvery(authenticateRequest.type, authenticateHandler);
  yield takeEvery(signUpRequest.type, signUpHandler);
  yield takeEvery(logOut.type, logoutHandler);
  yield takeEvery(updateAddressRequest.type, updateAddressHandler);
  yield takeEvery(profileDataRequest.type, profileDataRequestHandler);
  yield takeEvery(refreshTokenRequest.type, refreshTokenHandler);
  yield takeEvery(updateOnboardRequest.type, updateOnboardHandler);
}

export default [userSaga];
