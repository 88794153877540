import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './VideoModal.scss';

import {toggleModal} from "../actions/courseActions";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../store";

export interface ModalProps {
    videoURL: string,
    isOpen: boolean
}

const VideoModal: React.FC<ModalProps> = ({videoURL, isOpen}) => {
    const dispatch = useDispatch();

    const {
        isModalOpen
    } = useSelector((state: AppState) => state.course);

    const handleClose = () => {
        dispatch(toggleModal(false));
    }

    return (
        <>
            <Modal
                className="video-modal"
                size="lg"
                show={isModalOpen}
                onHide={handleClose}
                aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body className="border-0">
                    <video src={videoURL} width="100%" height="100%" autoPlay controls controlsList='nodownload' onContextMenu={e => e.preventDefault()}/>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default VideoModal;
