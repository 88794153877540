import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import NavContainer from "../components/NavContainer";
import OrderStatusContainer from "../components/OrderStatusContainer";
import { AppState } from "../store";
import { Address, defaultAddress } from "./Courses";

const OrderStatus: React.FC = () => {
  const { isLoggedIn} = useSelector(
    (state: AppState) => state.user
  );
  
  const navigate = useNavigate();
  const params = useParams();
  const courseId = Number(params.courseId);

  const { lastViewed } = useSelector(
    (state: AppState) => state.analytics
  );

  const { address, firstName, lastName } = useSelector(
    (state: AppState) => state.user
  );
  const [userAddress, setUserAddress] = useState<Address>(defaultAddress);

  useEffect(() => {
    if(address != null) {
        var obj = JSON.parse(address);
        setUserAddress(obj);
    }
  }, [address]);

  const arrivingDate = "";
  const destinationAddress = {
    name: `${firstName} ${lastName}`,
    filed1: `${userAddress.address1}`,
    filed2: `${userAddress.address2}`,
    city: `${userAddress.city}`,
    pinCode: `${userAddress.pincode}`
  }

  useEffect(()=>{
    if (!isLoggedIn) {
      navigate("/");
    }
  },[isLoggedIn])
  return (
    <NavContainer useBanner={false} showAppHeader={false}>
      <div>
        {false ?
        <h2>ARRIVING ON {arrivingDate}</h2> : <h2>SHIPMENT STATUS</h2>}
      </div>
      {lastViewed && <>{lastViewed.chapter ? 
      <OrderStatusContainer courseId={courseId} shipmentOrder={lastViewed.chapter.course.shipmentOrder[0]}></OrderStatusContainer> :
      <OrderStatusContainer courseId={courseId} shipmentOrder={lastViewed.courseEnrollment.course.shipmentOrder[0]}></OrderStatusContainer>
      }</>}
      <div style={{margin: "50px", float: "right"}}>
        <b>Delivering to:</b>
        <h6>
          {destinationAddress.name}   <br/>
          {destinationAddress.filed1} <br/>
          {destinationAddress.filed2} <br/>
          {destinationAddress.city + ' - ' + destinationAddress.pinCode}
        </h6>
      </div>
    </NavContainer>
  );
};

export default OrderStatus;
