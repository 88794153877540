import { useSelector } from 'react-redux';
import GenericError from './GenericError';
import Login from './Login';
import React from 'react';

export default () => {

  // const userTokenFromState: UserToken | null = useSelector(
  //   (s) => s.user.userToken
  // );

  // if (userTokenFromState) {
    // return <Login userToken={userTokenFromState} />;
    return <Login />;
  // } else {
  //   return <GenericError />;
  // }
};
