import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getLastViewedRequest, getLastViewedSuccess, getPerformanceDataRequest, getPerformanceDataSuccess, getTimeSpentDataRequest, getTimeSpentDataSuccess, PerformanceChartPayload } from '../actions/analyticsAction';
import { ChartPayload } from "../actions/courseActions";
import { logOut, refreshTokenRequest } from '../actions/userActions';
import { getLastViewed, getPerformanceData, getTimeSpentData } from '../services/analytics.service';

function* getTimeSpentEventHandler(params: {
    type: string;
    payload: ChartPayload
}): any {
    try {
        const { accessToken } = yield select((state) => state.user);
        const timeSpentResponse = yield call(getTimeSpentData, params.payload, accessToken);
        if (timeSpentResponse.errorCode === 401 || timeSpentResponse.errorCode == 'InvalidToken') {
            yield put(refreshTokenRequest());
        }
        yield put(getTimeSpentDataSuccess(timeSpentResponse))
    } catch (error: any) {
        throw new Error(error)
    }
}

function* getPerformanceEventHandler(params: {
    type: string;
    payload: PerformanceChartPayload
}): any {
    try {
        const { accessToken } = yield select((state) => state.user);
        const performanceResponse = yield call(getPerformanceData, params.payload, accessToken);
        if (performanceResponse.errorCode === 401 || performanceResponse.errorCode == 'InvalidToken') {
            yield put(refreshTokenRequest());
        }
        yield put(getPerformanceDataSuccess(performanceResponse))
    } catch (error: any) {
        throw new Error(error)
    }
}

function* getLastViewedEventHandler(params: {
    type: string;
}): any {
    try {
        const { accessToken } = yield select((state) => state.user);
        const lastViewed = yield call(getLastViewed, accessToken);
        if (lastViewed.errorCode === 401 || lastViewed.errorCode == 'InvalidToken') {
            yield put(refreshTokenRequest());
        }
        yield put(getLastViewedSuccess(lastViewed))
    } catch (error: any) {
        throw new Error(error)
    }
}

function* analyticsSaga() {
    yield takeEvery(getTimeSpentDataRequest.type, getTimeSpentEventHandler);
    yield takeEvery(getPerformanceDataRequest.type, getPerformanceEventHandler);
    yield takeEvery(getLastViewedRequest.type, getLastViewedEventHandler);

}

export default [analyticsSaga];