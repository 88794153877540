import { useRef } from 'react';

function useDeepCompareMemoize<T>(value: T) {
  const ref = useRef<T>();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (JSON.stringify(value) !== JSON.stringify(ref.current)) {
    ref.current = value
  }

  return ref.current
}

export default useDeepCompareMemoize;