import {createAction} from "@reduxjs/toolkit";
import { CourseInfoProps } from "../components/Dashboard/CourseInfo";
import { ChartPayload } from "./courseActions";
export const getTimeSpentDataRequest = createAction<ChartPayload>('TIME_SPENT_DATA');
export const getTimeSpentDataSuccess = createAction<Object>('TIME_SPENT_DATA_SUCCESS');


export interface PerformanceChartPayload {
    groupByForPerformance: string
 }
 export interface PerformanceChartResponse {
    response: Object
 }
export const getPerformanceDataRequest = createAction<PerformanceChartPayload>('PERFORMANCE_DATA');
export const getPerformanceDataSuccess = createAction<Object>('PERFORMANCE_DATA_SUCCESS');

export const getLastViewedRequest = createAction('GET_LAST_VIEWED_REQUEST');
export const getLastViewedSuccess = createAction<CourseInfoProps>('GET_LAST_VIEWED_SUCCESS');