import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AppCustomError } from '../errors';

import {
    Course, createCourseEnrollmentFailure,
    createCourseEnrollmentRequest, createCourseEnrollmentSuccess,
    createPaymentOrderFailure,
    createPaymentOrderRequest,
    createPaymentOrderSuccess, EnrollmentPayload,
    getCourseFailure,
    getCourseRequest,
    getCourseSuccess,
    OrderPayload,
    resetPaymentOrder,
    SubmitQuizErrorResponse,
    submitQuizFailure,
    SubmitQuizPayload,
    submitQuizRequest,
    submitQuizSuccess,
    SubmitQuizSuccessResponse,
    resetQuizResponse,
    toggleModal,
    uploadAssignmentRequest,
    AssignmentPayload,
    uploadAssignmentSuccess,
    uploadAssignmentFailure,
    uploadAssignmentReset,
    Question,
    publishCourseAnalyticsSuccess,
    CourseAnalyticsPayload,
    Assignment,
    Task,
    getTasksSuccess,
    putTaskSuccess,
    PutTaskSuccess,
    createShipmentOrderRequest,
    ShipmentOrderPayload,
    createShipmentOrderSuccess,
    createShipmentOrderFailure
} from "../actions/courseActions";
import { logOut } from "../actions/userActions";
import { ChapterId, CourseProgress } from '../types/common';

interface CourseState {
    course: Course,
    errorMessage: string | null,
    hasError: boolean,
    isModalOpen: boolean,
    isOrderCreated: boolean,
    isOrderError: boolean,
    isEnrollmentCreated: boolean,
    isEnrollmentError: boolean,
    orderId: string | null,
    transactionId: string | null,
    courseFailedToLoad: boolean,
    submitQuizResponse: SubmitQuizSuccessResponse | null,
    submitQuizError: SubmitQuizErrorResponse | null,
    isAssignmentUploaded: boolean,
    isAssignmentUploadError: boolean,
    courseProgress: CourseProgress,
    tasks: Task[] | [],
    isShipmentOrderCreated: boolean,
    isShipmentOrderError: boolean,
    shipmentOrderId: number | null,
    shipmentId: number | null,
    shipmentStatus: string | null,
    shipmentStatusCode: number | null,
}

const defaultCourse: Course = {
    id: null,
    name: null,
    description: null,
    trailer: null,
    glimpse: null,
    thumbnail: null,
    coverPhoto: null,
    status: null,
    level: null,
    price: null,
    currency: null,
    createdAt: null,
    updatedAt: null,
    authors: [],
    experiments: [],
    chapters: [],
    faqs: [],
    enrollment: null,
    assignments: [],
    lastWatchedVideo: null,
    isShipping: false,
    shipmentData: null
}

const initialState: CourseState = {
    course: defaultCourse,
    errorMessage: null,
    hasError: false,
    isModalOpen: false,
    isOrderCreated: false,
    isOrderError: false,
    isShipmentOrderCreated: false,
    isShipmentOrderError: false,
    orderId: null,
    isEnrollmentError: false,
    isEnrollmentCreated: false,
    transactionId: null,
    courseFailedToLoad: false,
    submitQuizResponse: null,
    submitQuizError: null,
    isAssignmentUploaded: false,
    isAssignmentUploadError: false,
    courseProgress: {},
    tasks: [],
    shipmentOrderId:  null,
    shipmentId: null,
    shipmentStatus: null,
    shipmentStatusCode: null,
};

const courseProgressCalc = (course: Course): CourseProgress => {
    const courseProgress: CourseProgress = {};

    course.chapters.forEach(chapter => {
        let progress = 0;
        let itemCount = chapter.lessons.length;

        if (chapter.isQuizEnabled) {
            itemCount += 1;

            const answersCount = chapter.questions.filter(question => question.answers.length > 0).length;
            progress += (answersCount >= chapter.noOfQuestions ? 1 : 0);
        }

        chapter.lessons.forEach(lesson => {
            if (lesson.courseAnalytics.length > 0 && lesson.courseAnalytics[0].isCompleted)
                progress += 1;

            if (lesson.lessonType === 'experiment') {
                itemCount += 1;

                if (!!course.assignments?.find(assignment => assignment.lesson.id === lesson.id))
                    progress += 1;


                if (lesson.isQuizEnabled) {
                    itemCount += 1;

                    const answersCount = lesson.questions.filter(question => question.answers.length > 0).length;
                    progress += (answersCount >= lesson.noOfQuestions ? 1 : 0);
                }

            }
        })

        courseProgress[chapter.id as ChapterId] = Math.floor((progress / itemCount) * 100);
    })

    return courseProgress;
}

const courseReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getCourseRequest, (state: CourseState) => ({
            ...state,
            course: defaultCourse,
            courseFailedToLoad: false
        }))

        .addCase(
            getCourseFailure,
            (state: CourseState, action: PayloadAction<AppCustomError>) => ({
                ...state,
                errorMessage: action.payload.message,
                hasError: true,
                courseFailedToLoad: true
            })
        )

        .addCase(getCourseSuccess,
            (state: CourseState, action: PayloadAction<Course>) => ({
                ...state,
                course: {
                    ...action.payload,
                    chapters: action.payload.chapters.map(chapter => ({
                        ...chapter,
                        totalDuration: chapter.lessons
                            .map(lesson => lesson.videoDuration)
                            .reduce((acc: number, val: number) => acc + val, 0)
                    }))
                },
                errorMessage: null,
                hasError: false,
                isModalOpen: false,
                courseProgress: courseProgressCalc(action.payload)
            }))

        .addCase(createPaymentOrderRequest,
            (state: CourseState, action:PayloadAction<OrderPayload>) => ({
                ...state,
                isOrderCreated: false,
                isOrderError: false,
                errorMessage: null
            }))

        .addCase(createPaymentOrderSuccess,
            (state: CourseState, action:PayloadAction<any>) => ({
                ...state,
                transactionId: action.payload.transactionId,
                orderId: action.payload.orderId,
                receipt: action.payload.receipt,
                isOrderCreated: true,
                isOrderError: false,
                errorMessage: null
            }))

        .addCase(createPaymentOrderFailure,
            (state: CourseState, action:PayloadAction<any>) => ({
                ...state,
                errorMessage: action.payload.message,
                isOrderCreated: false,
                isOrderError: true
            }))

        .addCase(createShipmentOrderRequest,
            (state: CourseState, action:PayloadAction<ShipmentOrderPayload>) => ({
                ...state,
                isShipmentOrderCreated: false,
                isShipmentOrderError: false,
                errorMessage: null
            }))

        .addCase(createShipmentOrderSuccess,
            (state: CourseState, action:PayloadAction<any>) => ({
                ...state,
                shipmentOrderId: action.payload.orderId,
                shipmentId: action.payload.shipmentId,
                shipmentStatus: action.payload.status,
                shipmentStatusCode: action.payload.statusCode,
                isShipmentOrderCreated: true,
                isShipmentOrderError: false,
                errorMessage: null
            }))

        .addCase(createShipmentOrderFailure,
            (state: CourseState, action:PayloadAction<any>) => ({
                ...state,
                errorMessage: action.payload.message,
                isShipmentOrderCreated: false,
                isShipmentOrderError: true
            }))
                
        .addCase(createCourseEnrollmentRequest,
            (state: CourseState, action: PayloadAction<EnrollmentPayload>) => ({
                ...state,
                isEnrollmentCreated: false,
                isShipmentOrderCreated: false,
                isEnrollmentError: false,
                errorMessage: null
            }))

        .addCase(createCourseEnrollmentSuccess,
            (state: CourseState, action: PayloadAction<any>) => ({
                ...state,
                orderId: action.payload.orderId,
                paymentId: action.payload.paymentId,
                isEnrollmentCreated: true,
                isEnrollmentError: false,
                errorMessage: null
            }))

        .addCase(createCourseEnrollmentFailure,
            (state: CourseState, action: PayloadAction<any>) => ({
                ...state,
                isEnrollmentCreated: false,
                isEnrollmentError: true,
                errorMessage: action.payload.message
            }))

        .addCase(resetPaymentOrder,
            (state: CourseState) => ({
                ...state,
                errorMessage: null,
                isOrderCreated: false,
                isOrderError: false
            }))

        .addCase(submitQuizRequest, (state: CourseState, action: PayloadAction<SubmitQuizPayload>) => ({
            ...state,
            submitQuizResponse: null,
            submitQuizError: null
        }))

        .addCase(submitQuizSuccess, (state: CourseState, action: PayloadAction<SubmitQuizSuccessResponse>) => {
            const course = {
                ...state.course,
                chapters: state.course.chapters.map(chapter => {
                    if (action.payload.chapterId === chapter.id) {
                        const attemptedQuestions: { [key: string]: Question } = {};
                        action.payload.quizResponses.forEach(q => {
                            attemptedQuestions[`${q.id}`] = q;
                        })

                        if (action.payload.experimentId) {
                            return {
                                ...chapter,
                                lessons: chapter.lessons.map(lesson => {
                                    if (lesson.id === action.payload.experimentId) {
                                        return {
                                            ...lesson,
                                            questions: lesson.questions.map(q => {
                                                if (attemptedQuestions[`${q.id}`])
                                                    return attemptedQuestions[`${q.id}`];

                                                return q;
                                            })
                                        }
                                    }

                                    return lesson;
                                })
                            }
                        }

                        return {
                            ...chapter,
                            questions: chapter.questions.map(q => {
                                if (attemptedQuestions[`${q.id}`])
                                    return attemptedQuestions[`${q.id}`];

                                return q;
                            })
                        }
                    }

                    return chapter;
                })
            }
            state.course = course;
            state.submitQuizResponse = action.payload;
            state.submitQuizError = null;
            state.courseProgress = courseProgressCalc(course)
        })

        .addCase(submitQuizFailure, (state: CourseState, action: PayloadAction<SubmitQuizErrorResponse>) => ({
            ...state,
            submitQuizError: action.payload
        }))

        .addCase(publishCourseAnalyticsSuccess, (state: CourseState, action: PayloadAction<CourseAnalyticsPayload>) => {
            const course = {
                ...state.course,
                chapters: state.course.chapters.map(chapter => {
                    if (action.payload.chapter.id === chapter.id) {

                        return {
                            ...chapter,
                            lessons: chapter.lessons.map(lesson => {
                                if (lesson.id === action.payload.chapter.lesson.id) {
                                    const lessonAnalytics = action.payload.chapter.lesson;
                                    return {
                                        ...lesson,
                                        courseAnalytics: [{
                                            lastWatchedTime: lessonAnalytics.watchedDuration,
                                            isCompleted: lessonAnalytics.isCompleted
                                        }]
                                    }
                                }

                                return lesson;
                            })
                        }
                    }

                    return chapter;
                })
            };
            state.course = course;
            state.courseProgress = courseProgressCalc(course);
        })

        .addCase(resetQuizResponse, (state: CourseState) => ({
            ...state,
            submitQuizResponse: null,
            submitQuizError: null
        }))

        .addCase(uploadAssignmentRequest,
            (state: CourseState, action: PayloadAction<AssignmentPayload>) => ({
                ...state,
                isAssignmentUploaded: false,
                isAssignmentUploadError: false,
                errorMessage: null
            }))

        .addCase(uploadAssignmentSuccess,
            (state: CourseState, action: PayloadAction<Assignment>) => {
                const course = {
                    ...state.course,
                    assignments: [
                        ...state.course.assignments, action.payload
                    ]
                };
                state.course = course;
                state.courseProgress = courseProgressCalc(course);
            })

        .addCase(uploadAssignmentFailure,
            (state: CourseState, action: PayloadAction<any>) => ({
                ...state,
                isAssignmentUploaded: false,
                isAssignmentUploadError: true,
                errorMessage: action.payload.message
            }))

        .addCase(uploadAssignmentReset,
            (state: CourseState) => ({
                ...state,
                isAssignmentUploaded: false,
                isAssignmentUploadError: false,
            }))

        .addCase(getTasksSuccess,
            (state: CourseState, action: PayloadAction<Task[]>) => (
                { ...state, tasks: action.payload }
            ))

        .addCase(putTaskSuccess,
            (state: CourseState, action: PayloadAction<PutTaskSuccess>) => {
                const updatedTasks = state.tasks.map(t => {
                    if (t.task == action.payload.courseTask.task)
                        return { ...t, id: action.payload.id, status: action.payload.status }
                    else return t;
                });
                return { ...state, tasks: updatedTasks }
            })

        .addCase(toggleModal,
            (state: CourseState, action: PayloadAction<boolean>) => ({
                ...state,
                isModalOpen: action.payload
            }))

        .addCase(
            logOut,
            () => (initialState)
        );
});

export default courseReducer;
